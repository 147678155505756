import React, {useEffect, useState} from 'react';

import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import {GET_WEBSITE_LOCALIZE_TEXT} from "../../../utils/dq_lc_service/lc_service";
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const all_keys = [
    "0-70",
    "71-80",
    "81-90",
    "91-100",
    "101-110",
    "111-120",
    "121-130",
    ">130"
]




const DC_ScoreChart = (props) => {

    const { all_scores, locale } = props;

    const options = {
        responsive: true,
        scales: {
            x: {
                display: true,
                grid: {
                    display: false,
                },
            },
            y: {
                display: true,
                grid: {
                    display: true
                }
            }
        },
        plugins: {
            legend: {
                display: false,
                position: 'top'
            },
            title: {
                display: true,
                text: GET_WEBSITE_LOCALIZE_TEXT(locale, 'assessment__distribution_of_dcs'),
            },
        }
    };

    let chart_data = {
        labels: all_keys,
        datasets: [
            {
                label: "",
                backgroundColor: '#32baf1',
                borderColor:'#32baf1',
                hoverBackgroundColor: '#32baf1',
                hoverBorderColor: '#32baf1',
                data: [],
                barPercentage: 1,
                categoryPercentage: 0.8,
            }
        ],
    };

    for (let key of all_keys) {
        if (all_scores[key]) {
            chart_data.datasets[0].data.push(all_scores[key])
        } else {
            chart_data.datasets[0].data.push(0)
        }
    }


    return (
        <div className='dc-chart-content'>
            <div className='dc-chart-vlegend'>{GET_WEBSITE_LOCALIZE_TEXT(locale, 'assessment__percent_of_participants')}</div>
            <Bar options={options} data={chart_data} />
            <div className='dc-chart-name'>{GET_WEBSITE_LOCALIZE_TEXT(locale, 'assessment__digital_citizenship_score')}</div>
        </div>
    );

};

export default DC_ScoreChart;