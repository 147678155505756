import React from 'react';

import {isBadgeEarned} from "../../../utils/utils";

const BadgeListItemDetail = (props) => {

    const { selected, item, areaData, profile } = props;

    let componentClasses = `${'stats-badge-item-detail'} ${'stats-badge-item-close'}`
    if (selected) {
        componentClasses = `${'stats-badge-item-detail'} ${'stats-badge-item-open'}`
    }

    const renderURL = (url) => {
        if (url.substring(0,8) === 'https://') {
            return url
        }
        if (url.substring(0,7) === 'http://') {
            return url
        }
        return `${process.env.REACT_APP_MYDQ_API}${url}`
    }

    return(
        <div className={componentClasses} >
            <div className={'stats-badges-main-section'}>
                {
                    areaData[item.area_code].badges.map( (badge, index) => {
                        const pmb = profile.badges[badge.badge_code];
                        let badgeClasses = 'stats-badge badge-unearned';
                        if (pmb && pmb.finished && isBadgeEarned(pmb.score)) {
                            badgeClasses = 'stats-badge badge-earned';
                        }
                        return (
                            <div
                                key={index}
                                style={{background: areaData[item.area_code].badge_background}}
                                className={badgeClasses}>
                                <img className={'stats-badge-img'} src={renderURL(badge.icon_url)} />
                                <span
                                    className={'stats-badge-span'}
                                    style={{color: areaData[item.area_code].theme_color}}
                                >{badge.app_badge_name ? badge.app_badge_name : badge.badge_code}</span>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default BadgeListItemDetail;