import React, {useEffect, useState} from 'react';
import BorderButton , {btnSize} from "../../components/UI/BorderButton/BorderButton.ui";

import raz_head_img from '../../assets/raz/raz-head.png';
import left_arrow from '../../assets/images/left-arrow.png';
import right_arrow from '../../assets/images/right-arrow.png';

import {useLocation, useParams} from "react-router-dom";

import { withRouter } from "react-router";

import {
    StepType,
} from './questions';
import GuidePoster from "./components/GuidePoster.comp";
import QuestionPoster from "./components/QuestionPoster.comp";
import {
    fetchAssessmentsService,
    getOrCreateAssessmentsService,
    getAssessHistoryService,
    updateAssessHistoryService
} from "../../services/home.service";
import {useGlobalState} from "../../context/global_state";
import {LOCAL_STORAGE_KEYS, QUIZ_TIME_MODES} from "../../utils/constants";
import {getActiveThemeColor, getMapFromObjectListByKey} from "../../utils/utils";
import RazProgressSVG from "../../components/UI/SVG_IMGs/RazProgressSVG";
import {useAuth} from "../../context/auth";



const QuizTime = (props) => {

    const { mode, identifier } = useParams();
    const { history } = props;

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const { authUser } = useAuth();

    const [currentStepCopy, setCurrentStepCopy] = useState(1);
    const [currentStep, setCurrentStep] = useState(1);
    const [currentQuestion, setCurrentQuestion] = useState(undefined);
    const [answers, setAnswers] = useState({});
    const [Quiz, setQuiz] = useState(undefined);
    const [total_steps, setTotalSteps] = useState(0);

    const [badgeCode, setBadgeCode] = useState('');

    const [progress, setProgress] = useState(0);

    const { setToastShow, setToastMessage, profile, setProfile } = useGlobalState();

    useEffect( () => {
        loadQuestions().then( d => console.log('loaded questions'));

        setTimeout( () => {
            console.log('main1 => profile --> ', profile)
        }, 1000)
    }, []);

    useEffect( () => {
        if (badgeCode !== '') {
            // set active theme color as per current area of the Quiz
            const app_start_cached_data = localStorage.getItem(LOCAL_STORAGE_KEYS.HOME_APP_START_API_RESPONSE);
            if (app_start_cached_data) {
                const api_data = JSON.parse(app_start_cached_data);
                const badges_ui_map = getMapFromObjectListByKey(api_data.ui_config.badges_uic, 'badge_code');
                const areas_ui_conf = getMapFromObjectListByKey(api_data.ui_config.areas_uic, 'area_code');

                const current_badge = badges_ui_map[badgeCode];
                if (current_badge) {
                    const current_area = areas_ui_conf[current_badge.area_code];
                    if (current_area) {
                        setProfile({
                            ...profile,
                            active_theme_color: current_area.theme_color
                        })
                    }
                }
            }
        }
    }, [badgeCode] )

    useEffect( () => {

        if (Quiz) {
            const curr_progress = ((currentStep-1) / total_steps) * 100;
            setProgress(curr_progress);

            if (currentStep > total_steps) {
                updateAssessHistory(curr_progress).then(r => {
                    if (authUser.is_guest_user) {
                        history.push('/post_quiz_landing');
                        localStorage.setItem(LOCAL_STORAGE_KEYS.LAST_ACTIVE_QUIZ_ID, Quiz._id);
                    } else {
                        history.push(`/result/quiz/${Quiz._id}`)
                    }
                });
            } else {
                updateAssessHistory(curr_progress).then(r => console.log('assess saved'));
            }

        }

    }, [currentStepCopy])

    
    useEffect( () => {
        if(Quiz && currentStep && answers){
            selectCurrentQuestion();
        }
    }, [currentStep])


    const updateAnswers = (_answers) => {
        setAnswers({
            ..._answers
        });
    }

    const loadQuestions = async () => {
        try {
            let res;
            switch (mode) {
                case QUIZ_TIME_MODES.START_MODE:
                    res = await fetchAssessmentsService(identifier);
                    setBadgeCode(identifier)
                    break;
                case QUIZ_TIME_MODES.RESUME_MODE:
                    res = await getAssessHistoryService(identifier);
                    setBadgeCode(res.data.badge_code)
                    break;
                case QUIZ_TIME_MODES.GETSET_MODE:
                    res = await getOrCreateAssessmentsService(identifier);
                    setBadgeCode(identifier)
                    break;
            }

            if (res.data.total_questions === 0) {
                // show_error and take user back to home page
                setToastMessage('Not Available at the moment, Try back later!');
                setToastShow(true);
                history.push('/home')
            }

            setQuiz(res.data);
            setAnswers(res.data.answers || {});
            setTotalSteps(res.data.total_steps);
            setCurrentStep(res.data.current_step);
            setProgress(res.data.progress_percentage);

            // edge case handled :: if backend responded with error on last score calculation api hit.
            if (res.data.current_step > res.data.total_steps) {
                if (authUser.is_guest_user) {
                    history.push('/post_quiz_landing');
                    localStorage.setItem(LOCAL_STORAGE_KEYS.LAST_ACTIVE_QUIZ_ID, res.data._id);
                } else {
                    history.push(`/result/quiz/${res.data._id}`)
                }
            }
        } catch (e) {
            setToastShow(true);
            setToastMessage(e.message);
            history.push('/home?reload_profile=1')
        }
    }

    const selectCurrentQuestion = () => {
        if(Quiz && Quiz.steps[currentStep]) {
            if (Quiz.steps[currentStep].type === StepType.QUESTION) {
                setCurrentQuestion(Quiz.steps[currentStep].question);
            }
            else if (Quiz.steps[currentStep].type === StepType.PAIRED_QUESTION) {
                if(answers[Quiz.steps[currentStep].question[0].question_number-1].selectedIndex == 1) {
                    setCurrentQuestion(Quiz.steps[currentStep].question[1]);
                } else {
                    setCurrentQuestion(Quiz.steps[currentStep].question[0]);
                }
            }
        }
    }

    const updateAssessHistory = async (curr_progress) => {
        try {
            if (currentQuestion) {
                const res = await updateAssessHistoryService(Quiz._id, answers, curr_progress, currentStep, currentQuestion.question_number);
                if (currentStep === total_steps) {
                    setProfile({
                        ...profile,
                        ...res.data.profile,
                        last_finished_badge: identifier
                    });
                } else {
                    setProfile({
                        ...profile,
                        ...res.data.profile
                    });
                }
            }

        } catch (e) {
            setToastShow(true);
            setToastMessage(e.message);
        }
    }

    const stepOver = () => {
        setCurrentStep(currentStep + 1);
        setCurrentStepCopy(currentStep + 1);
    }

    const stepBack = () => {
        setCurrentStep(currentStep - 1);
        setCurrentStepCopy(currentStep - 1);
    }

    const btnAction = () => {
        stepOver();
    }

    const questionNextAction = () => {
        if (Quiz && Quiz.steps[currentStep]) {
            let goNext = false;

            if (currentQuestion && currentQuestion.question_number in answers) {
                if (answers[currentQuestion.question_number].selectedIndex > -1) goNext = true;
                if (answers[currentQuestion.question_number].selIndexes.length > 0) goNext = true;
            }

            if (goNext) {
                stepOver();
            }
        }
    }

    const questionPrevAction = () => {
        // if (Quiz.steps[currentStep-1].type !== StepType.GUIDE) {
        //     setCurrentQuestion(currentQuestion - 1);
        // }
        stepBack();
    }

    const nextBtnColorGet = () => {
        if (Quiz && Quiz.steps[currentStep]) {
            if (currentQuestion && currentQuestion.question_number in answers) {
                if (answers[currentQuestion.question_number].selectedIndex < 0 && answers[currentQuestion.question_number].selIndexes.length < 1) {
                    return '#c1beb9';
                } else {
                    return getActiveThemeColor(profile);
                }
            }
        }
        return '#c1beb9';
    }

    return (
        <div className={'quiz-time-main-container'}>
            <div className={'quiz-time-progress-container'} >
                <div
                    className={'quiz-time-progress-bar'}
                    style={{
                        backgroundColor: getActiveThemeColor(profile),
                        width: `${progress}%`
                    }}
                />

                <RazProgressSVG
                    color={getActiveThemeColor(profile)}
                    style={{
                        left: `${progress-6}%`
                    }}
                />

                {/*<img*/}
                {/*    src={raz_head_img}*/}
                {/*    className={'quiz-time-progress-img'}*/}
                {/*    style={{*/}
                {/*        left: `${progress-10}%`*/}
                {/*    }}*/}
                {/*/>*/}

                {
                    Quiz && Quiz.steps[currentStep] && currentQuestion && [StepType.QUESTION, StepType.PAIRED_QUESTION].indexOf(Quiz.steps[currentStep].type) > -1 ?
                        <p>
                            <span style={{color: getActiveThemeColor(profile)}}>{currentQuestion.question_number}</span>
                            <span className={'qt-question-num-total'}>/{Quiz.total_questions}</span>
                        </p> : null
                }
            </div>
            
            {
                Quiz && Quiz.steps[currentStep] && Quiz.steps[currentStep].type === StepType.GUIDE ?
                    <GuidePoster locale={pageLc} nextAction={btnAction} {...Quiz.steps[currentStep].guide} /> : null
            }

            {
                Quiz && Quiz.steps[currentStep] && currentQuestion && [StepType.QUESTION, StepType.PAIRED_QUESTION].indexOf(Quiz.steps[currentStep].type) > -1 ?
                    <QuestionPoster
                        answers={answers}
                        updateAnswers={updateAnswers}
                        question={currentQuestion}
                        questionNextAction={questionNextAction}
                    /> : null
            }


            {
                Quiz && Quiz.steps[currentStep] && Quiz.steps[currentStep].type === StepType.GUIDE ?
                    null :
                    <>
                        <div
                            style={{backgroundColor: nextBtnColorGet()}}
                            className={'qt-next-btn'} onClick={questionNextAction}>
                            <img className={'qt-next-btn-img'} src={right_arrow} />
                        </div>
                        <div
                            style={{backgroundColor: getActiveThemeColor(profile)}}
                            className={'qt-prev-btn'} onClick={questionPrevAction}>
                            <img className={'qt-prev-btn-img'} src={left_arrow} />
                        </div>
                    </>
            }

        </div>
    )
}

export default withRouter(QuizTime);
