import React, {useState} from 'react';
import ReactHtmlParser from 'react-html-parser';
import Button, {btnSize} from "../../../components/UI/Button/Button.ui";
import {useAuth} from "../../../context/auth";
import {useGlobalState} from "../../../context/global_state";
import {registerUser} from "../../../services/auth.service";

import { withRouter } from "react-router";
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {loadNewGuestUser} from "../auth_common.lib";
import headerImg from '../../../assets/icons/dq-icons/dq-logo.png';
import {LOCAL_STORAGE_KEYS} from "../../../utils/constants";
import {useLocation} from "react-router-dom";
import {GET_APP_LOCALIZE_TEXT} from "../../../utils/dq_lc_service/lc_service";

const Register = (props) => {

    const { history } = props;

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const [loading, setLoading] = useState(false);

    const [formFirstName, setFormFirstName] = useState('');
    const [formLastName, setFormLastName] = useState('');
    const [formEmail, setFormEmail] = useState('');
    const [formPassword, setFormPassword] = useState('');
    const [formConfirmPassword, setFormConfirmPassword] = useState('');
    const [formAccessCode, setFormAccessCode] = useState('');

    const [showGuestLogin, setShowGuestLogin] = useState(true);
    const [showAccessCode, setShowAccessCode] = useState(false);

    const [termsAccepted, setTermsAccepted] = useState(false);

    const { setAuthToken, setAuthUser } = useAuth();
    const { setToastShow, setToastMessage, setProfile } = useGlobalState();

    const registerAction = async () => {

        // check that no input field is null or blank
        if (showAccessCode) {
            if ([formFirstName, formLastName, formEmail, formPassword, formConfirmPassword, formAccessCode].includes('')) {
                setToastShow(true);
                setToastMessage(GET_APP_LOCALIZE_TEXT(pageLc, 'dq_demographic__plz_fill_req_fields'))
                return
            }
        } else {
            if ([formFirstName, formLastName, formEmail, formPassword, formConfirmPassword].includes('')) {
                setToastShow(true);
                setToastMessage(GET_APP_LOCALIZE_TEXT(pageLc, 'dq_demographic__plz_fill_req_fields'))
                return
            }
        }


        // check if users have checked the agreement box

        // check if both the password matches
        if (formPassword !== formConfirmPassword) {
            setToastShow(true);
            setToastMessage(GET_APP_LOCALIZE_TEXT(pageLc, 'uo__pass_mismatch_plz_retype'))
            return
        }

        if (!termsAccepted) {
            setToastShow(true);
            setToastMessage(GET_APP_LOCALIZE_TEXT(pageLc, 'uo__plz_accept_terms_of_use'));
            return
        }


        // proceed to register the user, invoke the registration API
        try {
            setLoading(true);
            let profile
            const remember_me = true;
            const result = await registerUser(formEmail, formPassword, formFirstName, formLastName, formAccessCode, remember_me, showAccessCode);
            if (result.data && result.data.user && result.data.org_code) {
                await setAuthToken(result.data.auth_token);
                await setAuthUser(result.data.user);
                localStorage.setItem(LOCAL_STORAGE_KEYS.ACTIVE_ORG_CODE, result.data.org_code);

                profile = result.data.profile
                await setProfile(profile);

                if (!profile) {
                    return history.push('/home')
                }

                if (profile.is_demographic_updated) {
                    history.push('/home')
                } else {
                    history.push('/demographic')
                }
            } else {
                setToastShow(true);
                setToastMessage(GET_APP_LOCALIZE_TEXT(pageLc, 'uo__failed_to_proceed'));
                setShowGuestLogin(true);
            }
            setLoading(false)
        } catch (e) {
            setToastShow(true);
            setToastMessage(e.message);
            setLoading(false);
            setShowGuestLogin(true);
        }

    }

    const loginLink = () => {
        history.push('/login', true);
    }

    const guestLoginLink = () => {
        history.push('/info_dq', true);
    }

    const termsOfUseLink = () => {
        history.push('/terms_of_use', true);
    }

    const privacyPolicyLink = () => {
        history.push('/privacy_policy', true);
    }


    // FORM VALUE ON CHANGE EVENTS
    const onChangeFirstName = (e) => {
        setFormFirstName(e.target.value)
    }

    const onChangeLastName = (e) => {
        setFormLastName(e.target.value)
    }

    const onChangeEmail = (e) => {
        setFormEmail(e.target.value);
    }

    const onChangePassword = (e) => {
        setFormPassword(e.target.value);
    }

    const onChangeConfirmPassword = (e) => {
        setFormConfirmPassword(e.target.value);
    }
    const onChangeAccessCode = (e) => {
        setFormAccessCode(e.target.value);
    }
    // FORM VALUE EVENTS END's

    const onChangeTermsAccepted = (selected) => {
        console.log('onChangeTermsAccepted -> calling me -> ', selected)
        setTermsAccepted(selected)
    }

    return (
        <div className={'register-container'}>

            <LoaderWithBackDrop
                loading={loading}
            />

            <section>
                <header className={'header'}>
                    <div className={'toggle'}></div>
                    <img  src={headerImg} />
                </header>
            </section>

            <form onSubmit={e => e.preventDefault()}>
                <div className='dq-register-row'>
                    <label>{GET_APP_LOCALIZE_TEXT(pageLc, 'persist_guest__fname')}</label>
                    <input className={'dq-register-txt'}
                        type='text'
                        name={'first_name'}
                        id={'first_name'}
                        value={formFirstName}
                        onChange={onChangeFirstName}
                    />
                </div>
                <div className='dq-register-row'>
                    <label>{GET_APP_LOCALIZE_TEXT(pageLc, 'persist_guest__lname')}</label>
                    <input className={'dq-register-txt'}
                        type='text'
                        name={'last_name'}
                        id={'last_name'}
                        value={formLastName}
                        onChange={onChangeLastName}
                    />
                </div>

                <div className='dq-register-row'>
                    <label>{GET_APP_LOCALIZE_TEXT(pageLc, 'persist_guest__email_address')}</label>
                    <input className={'dq-register-txt'}
                        type='text'
                        name={'email'}
                        id={'email'}
                        value={formEmail}
                        onChange={onChangeEmail}
                    />
                </div>

                <div className='dq-register-row'>
                    <label>{GET_APP_LOCALIZE_TEXT(pageLc, 'persist_guest__password')}</label>
                    <input className={'dq-register-txt'}
                        type='password'
                        name={'password'}
                        id={'password'}
                        value={formPassword}
                        onChange={onChangePassword}
                    />
                </div>

                <div className='dq-register-row'>
                    <label>{GET_APP_LOCALIZE_TEXT(pageLc, 'persist_guest__confirm_password')}</label>
                    <input className={'dq-register-txt'}
                        type='password'
                        name={'confirm_password'}
                        id={'confirm_password'}
                        value={formConfirmPassword}
                        onChange={onChangeConfirmPassword}
                    />
                </div>

                {
                    !showAccessCode && <div className={'dq-audience-login-blue'}>
                        <a onClick={ () => setShowAccessCode(true) }>
                            {GET_APP_LOCALIZE_TEXT(pageLc, 'persist_guest__have_access_code')}
                        </a>
                    </div>
                }

                {
                    showAccessCode &&  <div>
                        <label onClick={ () => setShowAccessCode(false) }>
                            {ReactHtmlParser(GET_APP_LOCALIZE_TEXT(pageLc, 'persist_guest__access_code_span'))}
                        </label>
                    <input className={'dq-register-txt'}
                           type='text'
                           name={'access_code'}
                           id={'access_code'}
                           value={formAccessCode}
                           onChange={onChangeAccessCode}
                    />
                </div>
                }

            </form>

            <div className={'dq-register-terms'}>
                <div className={'dq-term-col1'}>
                    <input
                        type={'radio'}
                        name={'terms_accepted'}
                        id={'terms_accepted'}
                        value={termsAccepted}
                        onChange={()=>onChangeTermsAccepted(true)}
                    />
                </div>
                <div>
                    {ReactHtmlParser(GET_APP_LOCALIZE_TEXT(pageLc, 'persist_guest__accept_terms_of_use'))}
                </div>
            </div>

            <div className={'dq-options-submit'}>
                <button onClick={registerAction}>{GET_APP_LOCALIZE_TEXT(pageLc, 'persist_guest__register')}</button>
            </div>



            <div className={'dq-audience-login'}>
                <p>{GET_APP_LOCALIZE_TEXT(pageLc, 'persist_guest__already_have_account')}</p>
                <a onClick={loginLink}>{GET_APP_LOCALIZE_TEXT(pageLc, 'persist_guest__login_here')}</a>
            </div>

            {
                showGuestLogin && process.env.REACT_APP_FREE_TEST_ACTIVE === '1' && <div className={'dq-audience-login'}>
                    <p>{GET_APP_LOCALIZE_TEXT(pageLc, 'uo__continue_as_guest')}</p>
                    <a onClick={guestLoginLink}>{GET_APP_LOCALIZE_TEXT(pageLc, 'uo__guest_login')}</a>
                </div>
            }


            {/*<br />*/}
            {/*<br />*/}
            {/*<br />*/}
            {/*<a*/}
            {/*    className={'login-link'}*/}
            {/*    onClick={guestLogin}*/}
            {/*>Continue as Guest</a>*/}
        </div>
    );

}

export default withRouter(Register);
