import {DEFAULT_LOCALE, LOCAL_STORAGE_KEYS, LOCALES_ENABLED} from "../../utils/constants";


export const urlRewriteUtil = (location, history) => {
    const locale = location.pathname.split('/')[1];
    if (locale.length === 1 || locale.length === 2) {
        if (LOCALES_ENABLED.includes(locale)) {
            localStorage.setItem(LOCAL_STORAGE_KEYS.ACTIVE_LOCALE, locale);
        } else {
            // replace locale with english
            const path_parts = location.pathname.split('/');
            path_parts[1] = DEFAULT_LOCALE;
            localStorage.setItem(LOCAL_STORAGE_KEYS.ACTIVE_LOCALE, DEFAULT_LOCALE);
            history.push(path_parts.join('/'));
        }
    }
    else {
        // check active locale from local-storage
        const active_lc = localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVE_LOCALE);
        if (active_lc && active_lc.length === 2 && LOCALES_ENABLED.includes(active_lc)) {
            history.push(`/${active_lc}${location.pathname+location.search}`);
        } else {
            localStorage.setItem(LOCAL_STORAGE_KEYS.ACTIVE_LOCALE, DEFAULT_LOCALE);
            history.push(`/en${location.pathname+location.search}`);
        }
    }
}