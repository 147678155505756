import React, {useState, useEffect} from 'react';
import dqIcon from '../../../assets/icons/dq-icons/dq-logo.png';
import dqRaz from '../../../assets/raz/raz-default.png';

import { Animate, AnimateKeyframes, AnimateGroup } from "react-simple-animate";
import {LOCAL_STORAGE_KEYS} from "../../../utils/constants";

const TopSection = (props) => {

    const { all_close, score, history, is_first_boot } = props;


    const [animateScorePart, setAnimateScorePart] = useState(false);
    const [animateRizPart, setAnimateRizPart] = useState(false);

    const getAnimDuration = () => {
        if (is_first_boot) {
            return 0.6;
        } else {
            return 0;
        }
    }

    useEffect(  () => {
        setTimeout( ()=>{setAnimateScorePart(true)}, getAnimDuration()*1000)
        setTimeout( ()=>{setAnimateRizPart(true)}, getAnimDuration()*2000)
    }, [])

    let componentClasses = `${'home-top-section-container'} ${'home-top-section-full'}`
    // eslint-disable-next-line no-constant-condition
    if (!all_close) {
        //componentClasses = `${'home-top-section-container'} ${'home-top-section-partial'}`
    }
    let scoreClass = 'dq-score';

    const statsClickAction = () => {
        history.push('/stats');
    }

    return(
        <div className={componentClasses}>
            {/*<AnimateKeyframes*/}
            {/*    play={true}*/}
            {/*    duration={2}*/}
            {/*    keyframes={[*/}
            {/*        "transform: scale(2,2) translateY(20vh)",*/}
            {/*        "transform: scale(1,1) translateY(calc(20vh + 35px))",*/}
            {/*        "transform: scale(1,1) translateY(0vh)",*/}
            {/*        // "opacity: 0; transform: translateY(10vh)",*/}
            {/*        // "opacity: 1; transform: translateY(10vh)",*/}
            {/*        // "opacity: 1; transform: translateY(0vh)",*/}
            {/*    ]}*/}
            {/*>*/}
            {/*    <img src={dqIcon}*/}
            {/*         className={'home-top-section-dq-image'}*/}
            {/*         alt='DQ' />*/}
            {/*</AnimateKeyframes>*/}

            <Animate
                play={true}
                duration={getAnimDuration()}
                start={{ opacity: 0 }}
                end={{ opacity: 1 }}
            >
                <img src={dqIcon}
                     className={'home-top-section-dq-image'}
                     alt='DQ'
                />
            </Animate>

            <Animate
                play={animateScorePart}
                duration={getAnimDuration()}
                start={{ opacity: 0 }}
                end={{ opacity: 1 }}
            >
                <div className={scoreClass}>
                    <span>{score.toFixed(2).split('.')[0]}</span><span>.{score.toFixed(2).split('.')[1]}</span>
                </div>
            </Animate>

            <Animate
                play={animateRizPart}
                duration={getAnimDuration()}
                start={{ opacity: 0 }}
                end={{ opacity: 1 }}
            >
                <div className='v-stats'>
                    <div className='v-stats-btn' onClick={statsClickAction}>View Score</div>
                    <div className='raz-chat'>
                        <img src={dqRaz} alt='Raz' />
                    </div>
                </div>
            </Animate>

        </div>
    )
};

export default TopSection;