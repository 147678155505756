import React, {useEffect, useState} from 'react';
import {useGlobalState} from "../../../context/global_state";
import { withRouter } from "react-router";
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";

import dqIcon from '../../../assets/icons/dq-icons/dq-logo.png';
import {LOCAL_STORAGE_KEYS} from "../../../utils/constants";
import {useHistory, useLocation} from "react-router-dom";
import {GET_APP_LOCALIZE_TEXT} from "../../../utils/dq_lc_service/lc_service";
import {loginUser} from "../../../services/auth.service";
import {useAuth} from "../../../context/auth";

const DashboardLogin = (props) => {

    const { onSwitch, closeModal } = props;

    const page_location = useLocation();
    const history = useHistory();
    const pageLc = page_location.pathname.split('/')[1];

    const [loading, setLoading] = useState(false);

    const [formEmail, setFormEmail] = useState('');
    const [formPassword, setFormPassword] = useState('');

    const { setAuthToken, setAuthUser, authToken, authUser } = useAuth();

    const { setToastShow, setToastMessage, setProfile } = useGlobalState();

    useEffect( () => {

    }, [] );


    const loginAction = async () => {

        try {

            if (authToken && authUser) {
                history.push('/home')
            }

            setLoading(true);
            const remember_me = true;
            let profile;
            const result = await loginUser(formEmail, formPassword, remember_me);

            // if (result.data && result.data.redirect_to_dq_portal && result.data.access_token) {
            //     window.location.assign(`${process.env.REACT_APP_DQ_PRO_LOGIN_URL}?token=${result.data.access_token}`);
            // } else {
            //     setToastShow(true);
            //     setToastMessage('You does not have permission to access DQ PRO!');
            // }
            if (result.data && result.data.redirect_to_dq_portal && result.data.access_token) {
                setToastShow(true);
                setToastMessage('You does not have permission to access DQ Assessments!');
            } else if (result.data && result.data.user && result.data.org_code) {
                await setAuthToken(result.data.auth_token);
                await setAuthUser(result.data.user);
                localStorage.setItem(LOCAL_STORAGE_KEYS.ACTIVE_ORG_CODE, result.data.org_code);

                profile = result.data.profile
                await setProfile(profile);

                closeModal();

                if (!profile) {
                    window.location.assign(`${process.env.REACT_APP_DQ_ASSESS_BASE_URL}/home`);
                }

                if (profile.is_demographic_updated) {
                    window.location.assign(`${process.env.REACT_APP_DQ_ASSESS_BASE_URL}/home`);
                    history.push('/home')
                } else {
                    window.location.assign(`${process.env.REACT_APP_DQ_ASSESS_BASE_URL}/demographic`);
                }
            }

            setLoading(false);

        } catch (e) {
            setToastShow(true);
            setToastMessage(e.message);
            setLoading(false);
        }

    }

    const onChangeEmail = (e) => {
        setFormEmail(e.target.value);
    }

    const onChangePassword = (e) => {
        setFormPassword(e.target.value);
    }

    return (
        <div className={'login-container'}>

            <LoaderWithBackDrop
                loading={loading}
            />

            <div className="dq-logo-image"><img src={dqIcon} alt='DQ'/></div>

            <div className={'dq-dashboard-modal-login'}>
                <p>
                    Login to DQ Assessment
                </p>
            </div>
            <div className="dq-login-content">
                <form onSubmit={e => e.preventDefault()}>
                    <div>
                        <input
                            className={'dq-login-txt'}
                            type='text'
                            name={'email'}
                            id={'email'}
                            value={formEmail}
                            placeholder={GET_APP_LOCALIZE_TEXT(pageLc, 'uo__username')}
                            onChange={onChangeEmail}
                        />
                    </div>
                    <div>
                        <input
                            className={'dq-login-txt'}
                            type='password'
                            name={'password'}
                            id={'password'}
                            value={formPassword}
                            placeholder={GET_APP_LOCALIZE_TEXT(pageLc, 'persist_guest__password')}
                            onChange={onChangePassword}
                        />
                    </div>
                </form>
            </div>
            
            <div className={'dq-options-submit'}>
                <button onClick={loginAction}>{GET_APP_LOCALIZE_TEXT(pageLc, 'persist_guest__login')}</button>
            </div>

            <div className={'dq-dashboard-modal-login'}>
                <p>
                    {GET_APP_LOCALIZE_TEXT(pageLc, 'persist_guest__not_have_an_account')}
                    <span>
                        <a className={'dq-footer-link'} onClick={onSwitch}>
                            Contact us
                        </a>
                    </span>
                </p>
                {/*<a onClick={registerLink}>{GET_APP_LOCALIZE_TEXT(pageLc, 'uo__register_as_individual')}</a>*/}
            </div>
            
            <div className={'dq-dashboard-modal-login-footer'}>
                <a className={'dq-footer-link'} href={process.env.REACT_APP_DQ_PRO_LOGIN_URL} target='_blank'>
                    Login to DQ Dashboard
                </a>

            </div>
        </div>
    );

}

export default withRouter(DashboardLogin);