import {DEFAULT_LOCALE} from "./constants";
import {GET_APP_LOCALIZE_TEXT} from "./dq_lc_service/lc_service";

export const getMapFromObjectListByKey = (objectList, mapKey) => {
    let dict = {};
    for (let item of objectList) {
        dict[item[mapKey]] = item;
    }
    return dict;
}

export const getArrayFromMap = (map) => {
    return Object.keys(map).map((k) => map[k])
}

export const getActiveThemeColor = (profile) => {
    if (profile && profile.active_theme_color && profile.active_theme_color.length > 3) {
        return profile.active_theme_color;
    } else {
        return "#32baf1" // - light blue, '#f8b143' - orange
    }
}

export const getPercentileStr = (score, percentile, locale) => {
    if (percentile > 50) {
        return `${GET_APP_LOCALIZE_TEXT(locale, 'dq_home__bottom_percent')} ${parseFloat(101-percentile).toFixed(0)}%`;
    } else {
        return `${GET_APP_LOCALIZE_TEXT(locale, 'dq_home__top_percent')} ${parseFloat(1+percentile).toFixed(0)}%`
    }
}

export const getBadgeResultStr = (score, short = false, locale= DEFAULT_LOCALE) => {
    let results = [
        GET_APP_LOCALIZE_TEXT(locale, 'result_quiz__require_attention'),
        GET_APP_LOCALIZE_TEXT(locale, 'result_quiz__bellow_avg'),
        GET_APP_LOCALIZE_TEXT(locale, 'result_quiz__good_job'),
        GET_APP_LOCALIZE_TEXT(locale, 'result_quiz__excellent'),
    ];
    if (short) results = [
        GET_APP_LOCALIZE_TEXT(locale, 'dq_home__bellow_avg'),
        GET_APP_LOCALIZE_TEXT(locale, 'dq_home__bellow_avg'),
        GET_APP_LOCALIZE_TEXT(locale, 'dq_home__avg'),
        GET_APP_LOCALIZE_TEXT(locale, 'dq_home__excellent'),
    ];

    if (score < 85)  return results[0];
    else if (score >= 85 && score < 100) return results[1];
    else if (score >= 100 && score < 115) return results[2];
    return results[3];
}

export const getBadgeResultColorClass = (score) => {
    if (score < 85) return 'bavg-color';
    else if (score >= 85 && score < 100) return 'bavg-color';
    else if (score >= 100 && score < 115) return 'gr-color';
    return 'lb-color';
}

export const isBadgeEarned = (score) => {
    return score >= 85 ? true : false;
}