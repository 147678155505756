import React, {useEffect, useState} from 'react';
// import '../../../assets/css/ui.comp.css';
import exlChartImg from '../../../assets/images/badge-exl-chart.jpg';
import avgChartImg from '../../../assets/images/badge-below-avg-chart.jpg';
import statshartImg from '../../../assets/images/badge-stats-chart.jpg';

import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import {useLocation} from "react-router-dom";
import {GET_APP_LOCALIZE_TEXT, GET_APP_LOCALIZE_DATE} from "../../../utils/dq_lc_service/lc_service";
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    scales: {
        x: {
            display: true,
            grid: {
                display: false,
            },
        },
        y: {
            display: true,
            min: 40,
            max: 140,

        }
    },
    plugins: {
        legend: {
            position: 'bottom',
        },
        // title: {
        //     display: true,
        //     text: 'Chart.js Bar Chart',
        // },
    },
};


const dataset_indexes = {
    my_score: 0,
    national_avg: 1,
    global_avg: 2
}

const month_interpretation = {
    0: 'Jan',
    1: 'Feb',
    2: 'Mar'
}


const ScoreChart = (props) => {

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const { score, all_scores, scoreChange, showStats } = props;
    const [scoreChangeText, setScoreChangeText] = useState('+0.00');
    const [graphData, setGraphData] = useState(
        {
            labels: [],
            datasets: [
                {
                    label: GET_APP_LOCALIZE_TEXT(pageLc, 'dq_home__myscore'),
                    backgroundColor: '#32baf1',
                    borderColor:'#32baf1',
                    hoverBackgroundColor: '#32baf1',
                    hoverBorderColor: '#32baf1',
                    data: [],
                    barPercentage: 1,
                    categoryPercentage: 0.4,
                },
                {
                    label: GET_APP_LOCALIZE_TEXT(pageLc, 'dq_home__national_avg'),
                    backgroundColor: '#ed6628',
                    borderColor: '#ed6628',
                    hoverBackgroundColor: '#ed6628',
                    hoverBorderColor: '#ed6628',
                    data: [],
                    barPercentage: 1,
                    categoryPercentage: 0.4,
                },
                {
                    label: GET_APP_LOCALIZE_TEXT(pageLc, 'dq_home__global_avg'),
                    backgroundColor: '#717374',
                    borderColor: '#717374',
                    hoverBackgroundColor: '#717374',
                    hoverBorderColor: '#717374',
                    data: [],
                    barPercentage: 1,
                    categoryPercentage: 0.4,
                },
            ],
        }
    )

    useEffect( () => {
        if(scoreChange >= 0) {
            setScoreChangeText(`+${scoreChange}`);
        }else{
            setScoreChangeText(`${scoreChange}`);
        }
    }, [] );

    let chartClasses = `${'chart-title gr-color'}`;
    let scoreChangeClass = `${'chart-score-down avg-color'}`;
    
    if (scoreChange >= 0) {
        scoreChangeClass = `${'chart-score-up lb-color'}`;
    }

    let chart_data = {
        labels: [],
        datasets: [
            {
                label: GET_APP_LOCALIZE_TEXT(pageLc, 'dq_home__myscore'),
                backgroundColor: '#32baf1',
                borderColor:'#32baf1',
                hoverBackgroundColor: '#32baf1',
                hoverBorderColor: '#32baf1',
                data: [],
                barPercentage: 1,
                categoryPercentage: 0.4,
            },
            {
                label: GET_APP_LOCALIZE_TEXT(pageLc, 'dq_home__national_avg'),
                backgroundColor: '#ed6628',
                borderColor: '#ed6628',
                hoverBackgroundColor: '#ed6628',
                hoverBorderColor: '#ed6628',
                data: [],
                barPercentage: 1,
                categoryPercentage: 0.4,
            },
            {
                label: GET_APP_LOCALIZE_TEXT(pageLc, 'dq_home__global_avg'),
                backgroundColor: '#717374',
                borderColor: '#717374',
                hoverBackgroundColor: '#717374',
                hoverBorderColor: '#717374',
                data: [],
                barPercentage: 1,
                categoryPercentage: 0.4,
            },
        ],
    };
    if (all_scores && all_scores.length > 0) {
        let total_records = all_scores.length;
        let start_index = 0
        if (total_records > 3) {
            start_index = total_records - 3;
        }

        for (let i=start_index; i<total_records; i++) {
            const time_of_entry = new Date(all_scores[i].result_time);
            const entry_label = GET_APP_LOCALIZE_DATE(pageLc, time_of_entry);
            // const t_entry_parts = time_of_entry.toString().split(' ');
            // const entry_label = `${t_entry_parts[1]} ${t_entry_parts[2]}, ${t_entry_parts[4].split(':').slice(0,2).join(':')}`

            chart_data.labels.push(entry_label);
            chart_data.datasets[dataset_indexes.my_score].data.push(all_scores[i].score);
            chart_data.datasets[dataset_indexes.national_avg].data.push(93)
            chart_data.datasets[dataset_indexes.global_avg].data.push(100)
        }
    }


    return (
        <div className={'home-item-detail-chart'}>
            <div className={chartClasses}>{GET_APP_LOCALIZE_TEXT(pageLc, 'dq_home__myscore_comparison')}</div>
            {/* <div className={chartClasses}>Your score <span>vs</span> national average <span>vs</span> global average</div> */}
            {/*<div className={'chart-subtitle'}>last 1 month <span className={scoreChangeClass}>({scoreChangeText})</span></div>*/}
            {/*<div className={'chart-legend'}>*/}
            {/*    <span className={'chart-my-score'}>Your Score</span>*/}
            {/*    <span className={'chart-national-score'}>National Avg.</span>*/}
            {/*    <span className={'chart-global-score'}>Global Avg.</span>*/}
            {/*</div>*/}

            <Bar options={options} data={chart_data} />

            {/*{*/}
            {/*    showStats ? */}
            {/*        <img src={statshartImg} alt='' />*/}
            {/*    :*/}
            {/*    <>*/}
            {/*    {*/}
            {/*        score >= 85 ? */}
            {/*        <img src={exlChartImg} alt='' />*/}
            {/*        : <img src={avgChartImg} alt='' />*/}
            {/*    }*/}
            {/*    </>*/}
            {/*}*/}
            {/*<div className={'chart-x-axis-buttons'}>*/}
            {/*    <span><label>1D</label></span>*/}
            {/*    <span><label>7D</label></span>*/}
            {/*    <span><label className={'active'}>1M</label></span>*/}
            {/*    <span><label>6M</label></span>*/}
            {/*    <span><label>1Y</label></span>*/}
            {/*    <span><label>3Y</label></span>*/}
            {/*</div>*/}
        </div>
    );

};

export default ScoreChart;