import React, {useEffect, useState} from 'react';
import ListItemProgress from './ListItemProgress.comp';
import {useGlobalState} from "../../../context/global_state";


const ListItem = (props) => {

    const { badge, areas, selectItem, profile } = props;

    const { setToastShow, setToastMessage } = useGlobalState();

    useEffect( ()=>{

    //     const curr_badge_state = profile.badges[badge.badge_code];
    //     if (curr_badge_state) {
    //         if (curr_badge_state.in_progress) {
    //             setScoreBoxText(`${curr_badge_state.progress.toFixed(1)}%`)
    //         } else if (curr_badge_state.finished) {
    //             setScoreBoxText(`${curr_badge_state.score}`)
    //         }
    //     }
    }, [] );

    const renderURL = (url) => {
        if (url.substring(0,8) === 'https://') {
            return url
        }
        if (url.substring(0,7) === 'http://') {
            return url
        }
        return `${process.env.REACT_APP_MYDQ_API}${url}`
    }

    const microBadgeClickAction = (badge_id, badge_object) => {
        selectItem(badge_id, badge_object);
    }

    const mainStyle = {
        backgroundColor: areas[badge.area_code].badge_background,
        borderColor: `${areas[badge.area_code].theme_color}`,
        borderTop: `1px solid ${areas[badge.area_code].theme_color}`,
        color: areas[badge.area_code].primary_color
    };

    return(
        <div
            className={'home-list-item-main'}
            style={mainStyle}
            onClick={()=>microBadgeClickAction(badge.id, badge)} >
            {/*<span className={style.title}>{item.title}</span>*/}

            <div className={'home-list-item-container'}>
                <div className={'home-list-item-title-section'}>
                    <img className={'home-list-item-img'} src={renderURL(badge.icon_url)} />
                    <div className={'home-list-item-title'}>
                        <span>{badge.duration}</span>
                        <p>{badge.name}</p>
                    </div>
                </div>
                <ListItemProgress
                    badge={badge}
                    profile={profile}
                />
            </div>
        </div>
    )
}

export default ListItem;