
const lc_en = {
    terms_of_use__title: `Terms of Use`,
    terms_of_use__intro: `1. Introduction`,
    terms_of_use__opening_paragraph_1: `The DQ Assessment Sites provide a platform to test your digital intelligence or DQ (<strong>“Sites”</strong>). PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE USING/ALLOWING YOUR CHILD/WARD UNDER EIGHTEEN (18) TO USE ANY SITE. The Sites are owned and operated by DQ LAB Pte. Ltd. (<strong>“DQ LAB”, “we”, “our” or “us”</strong>). The DQ Test Services on the Sites (the <strong>“Services”</strong>) comprise or a test on digital intelligence for children and adults. These Terms of Use are agreed to and accepted by and shall bind you. By using the Services (or, if you are a parent/guardian of a child under eighteen (18) years old using the Services, by providing Parental Consent (as defined in the Privacy Policy)), you accept and agree to be bound and abide by these Terms of Use and our Privacy Policy, incorporated herein by reference. If you do not agree to these Terms of Use, please do not install, use, register to or otherwise access the Services. If you are the parent/guardian of a child under eighteen (18) years old using the Services and you do not agree to these Terms of Use, please do not allow your child/ward to install, use, register for or otherwise access the Services.`,
    terms_of_use__opening_paragraph_2: `You agree that you are fully able and competent to agree to, and comply with, these Terms of Use.`,
    terms_of_use__point_2: `2. Intellectual Property`,
    terms_of_use__point_2_1: `2.1. All intellectual property in any content, information, code, date, or materials (“Content”) on the Sites belong to DQ LAB PTE. LTD. (the “IP Owners”). All trademarks, service marks, trade names, and trade dress on the Sites belong to the IP Owners. We may change or delete Content or features on the Sites at any time, in any way, for any or no reason.`,
    terms_of_use__point_2_2: `2.2. The trademarks, logos, service marks and trade names (collectively, the “Trademarks”) displayed on the Site are registered and unregistered trademarks of DQ LAB and others and may not be used in connection with products and/or services that are not related to, associated with, or endorsed by their rights holders, or in any manner that is likely to cause customer confusion, or which would disparage or discredit their proprietors. All Trademarks not owned by DQ LAB that appear on the Sites, if any, are the property of their respective owners. Nothing contained on the Sites should be construed as granting, by implication, estoppel, or otherwise, any license or right to use any Trademark displayed on the Site without the prior written permission of the IP Owners or the third party that may own the applicable Trademark. Your misuse of the Trademarks displayed on the Sites or any of the mobile product's services is strictly prohibited. The IP Owners reserve all rights to aggressively enforce their Trademark rights to the fullest extent of the law, including the seeking of criminal prosecution.`,
    terms_of_use__point_3: `3. Prohibited Use`,
    terms_of_use__point_3_1: `3.1. You may use the Sites only for lawful purposes and in accordance with these Terms of Use.`,
    terms_of_use__point_3_2: `3.2. Any commercial or promotional distribution, publishing or exploitation of the Services or any Content, is strictly prohibited unless you have received the express prior written permission of the IP Owners or the applicable rights holder. Other than as expressly allowed herein, you may not download, post, display, publish, copy, reproduce, distribute, transmit, modify, perform, broadcast, transfer, create derivative works from, sell or otherwise exploit any Content. You further agree that you may not alter, edit, delete, remove, otherwise change the meaning or appearance of, or repurpose, any of the Content, including, without limitation, the alteration or removal of any trademarks, trade names, logos, service marks, or any other proprietary content or proprietary rights notices.`,
    terms_of_use__point_4: `4. Limited Licence`,
    terms_of_use__paragraph_3: `You may access and view the Content on your computer, other Internet-compatible device, or mobile device and make single copies or prints of the content on the Services for your personal, non-commercial and internal use only.`,
    terms_of_use__point_5: `5. Indemnification`,
    terms_of_use__point_5_1: `5.1. You agree to fully indemnify and hold DQ LAB and its directors, officers, employees and agents harmless from any and all claims (whether in contract, tort or otherwise), liabilities, costs and expenses, including reasonable attorneys' fees, arising in any way from your use of the Sites, your placement or transmission of any message, content, information, software or other materials through the Sites, or your breach or violation of the law or of these Terms of Use.`,
    terms_of_use__point_5_2: `5.2. DQ LAB reserves the right, at its own expense, to assume the exclusive defence and control of any matter which is otherwise subject to indemnification by you, and in such case, you agree to cooperate with DQ LAB's defence of such claim.`,
    terms_of_use__point_6: `6. Disclaimers &amp; Limitations`,
    terms_of_use__point_6_1: `6.1. The Content is for general information purposes and is provided on the understanding that no medical relief for cyber-victims such as video game addiction and/or cyberbullying victims is being offered.`,
    terms_of_use__point_6_2: `6.2. Please seek professional advice from professional counsellors or medical professionals if you/your ward show suspicious symptoms of the aforementioned conditions. The user assumes all responsibility and risk for the use of the Content. Under no circumstances shall DQ LAB or its contributors/employees or its affiliated organizations or their employees or anyone else involved in creating or maintaining the Sites be liable for any DIRECT, INDIRECT, INCIDENTAL, SPECIAL or CONSEQUENTIAL DAMAGES, INJURY and/or LOSS that results directly or indirectly from the use or misuse of the Content, and such liability is hereby excluded to the fullest extent permitted by law.`,
    terms_of_use__point_6_3: `6.3. To the fullest extent allowed by law, DQ LAB excludes all representations or warranties about the accuracy, reliability, completeness, currentness, or timeliness of the Content, software, text, graphics, links, or communications provided on or through the use of the Sites.`,
    terms_of_use__point_7: `7. Updates to the Services`,
    terms_of_use__paragraph_4: `DQ LAB may find it necessary to update, or reset certain parameters to balance activity/game play and usage of the Services. DQ LAB reserves the right to make these updates and is not liable to you for these changes.`,
    terms_of_use__point_8: `8. Third Party Services and Content`,
    terms_of_use__point_8_1: `8.1. You should be aware that when you are on the Sites, there are links to other sites that take you outside of our service to sites that are beyond our control, such as links from partners, sponsors and content partners that may use our logo(s) as part of a co-branding relationship. ou acknowledge that when you click on any of the aforementioned links, the sites you are taken to are not controlled by us, different terms of use and privacy policies may apply, and we are not responsible for such sites. We do not endorse and cannot ensure that you will be satisfied with any products or services of a third-party site that links to or from any Sites or third-party Content contained on our Sites. We strongly encourage you to make whatever investigation you feel necessary or appropriate before proceeding with any online or offline transaction with any third parties.`,
    terms_of_use__point_8_2: `8.2. You acknowledge that we cannot be held responsible for the accuracy, relevance, legality or decency of material contained on sites retrieved in searches and/or listed in search results or identified on search results pages.`,
    terms_of_use__point_9: `9. Limitation on Liability`,
    terms_of_use__point_9_1: `9.1. You understand that we cannot and do not guarantee or warrant that files available for downloading from the Internet or the Services will be free of viruses or other destructive code. You are responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements for anti-virus protection and accuracy of data input and output, and for maintaining a means external to our Sites for any reconstruction of any lost data.`,
    terms_of_use__point_9_2: `9.2. We will not be liable for any loss or damage caused by a distributed Denial-of-Service attack, viruses or other technologically harmful material that may infect your computer equipment, computer programs, data or other proprietary material due to your use of the services or any services or items obtained through the services or due to your downloading of any material posted on them, or on any services linked to it.`,
    terms_of_use__point_9_3: `9.3.Your use of the services, their content and any services or items obtained through the services are at your own risk. The services, their content and any services or items obtained through the services are provided on an “as is” and “as available” basis, and to the maximum extent permitted by the law, without any warranties of any kind, either express or implied. Neither the company nor any person associated with DQ LAB makes any warranty or representation with respect to the completeness, security, reliability, quality, accuracy or availability of the services. Without limiting the foregoing, neither DQ LAB nor anyone associated with DQ LAB represents or warrants that the services, their content or any services or items obtained through the services will be accurate, reliable, error-free or uninterrupted, that defects will be corrected, that the sites or the server that makes them available are free of viruses or other harmful components or that the services or any services or items obtained through the services will otherwise meet your needs or expectations.`,
    terms_of_use__point_9_4: `9.4. To the fullest extent allowed by law, DQ LAB hereby disclaims all warranties of any kind, whether express or implied, statutory or otherwise, including but not limited to any warranties of merchantability, non-infringement and fitness for particular purpose."`,
    terms_of_use__point_9_5: `9.5.  In no event will DQ LAB, its affiliates or their licensors, service providers, employees, agents, officers or directors be liable for damages of any kind, under any legal theory, arising out of or in connection with your use, or inability to use, services, any websites linked to them, any content on the services or such other websites or any services or items obtained through the services or such other websites, including any direct, indirect, special, incidental, consequential or punitive damages, including but not limited to, pain and suffering, emotional distress, loss of revenue, loss of profits, loss of business or anticipated savings, loss of use, loss of goodwill, loss of data, and whether caused by tort (including negligence), breach of contract or otherwise, even if foreseeable.`,
    terms_of_use__point_10: `10. Limitation on Time to File Claims`,
    terms_of_use__paragraph_5: `The extent permitted by law, any cause of action or claim you may have arising out of or relating to these Terms of Use or the services must be commenced within one (1) year after the cause of action accrues, otherwise, such cause of action or claim is permanently barred.`,
    terms_of_use__point_11: `11. Non-Waiver`,
    terms_of_use__paragraph_6: `No waiver by DQ LAB of any term or condition set forth in these Terms of Use shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of DQ LAB to assert a right or provision under these Terms of Use shall not constitute a waiver of such right or provision.`,
    terms_of_use__point_12: `12. Severability`,
    terms_of_use__para_7: `If any provision of these Terms of Use is held by a court or other tribunal of competent jurisdiction to be invalid, illegal or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms of Use will continue in full force and effect.`,
    terms_of_use__point_13: `13. Entire Agreement`,
    terms_of_use__para_8: `The Terms of Use, the Privacy Policy and the Parental Consent (if any) constitute the sole and entire agreement between you and DQ LAB with respect to the Services and supersede all prior and contemporaneous understandings, agreements, representations and warranties, both written and oral, with respect to the Services.`,
    terms_of_use__point_14: `14. Mobile Networks`,
    terms_of_use__para_9: `When you access the Services through a mobile network, your network or roaming provider's messaging, data and other rates and fees will apply. Downloading, installing or using certain Services may be prohibited or restricted by your network provider and not all Services may work with your network provider or device. We bear no responsibility for such fees, prohibitions or restrictions.`,
    terms_of_use__point_15: `15. Linking to the Sites and Social Media Features`,
    terms_of_use__point_15_1: `15.1. You may link to our Sites, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it, but you must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part without our express written consent.`,
    terms_of_use__point_15_2: `15.2. The Sites may provide certain social media features that enable you to: (a) Link from your own or certain third-party websites to certain content on the Sites. (b) Send e-mails or other communications with certain content, or links to certain content, on the Sites. (c) Cause limited portions of content on the Sites to be displayed or appear to be displayed on your own or certain third-party websites. (d) Connect with other users of the Sites. (e) Publish User Generated Content (“UGC”) that will allow all users of the Sites to access and use that information, and to associate it with you. (f) Comment on UGC. (g) Tag (or link) UGC to other users of the Sites.`,
    terms_of_use__point_15_3: `15.3. You may use these features solely as they are provided by us and solely with respect to the content they are displayed with and otherwise in accordance with any additional terms and conditions we provide with respect to such features. Subject to the foregoing, you must not: (a) Establish a link from any website that is not owned by you. (b) Cause the Sites or portions of them to be displayed, or appear to be displayed by, for example, framing, deep linking or in-line linking, on any other site. (c) Link to any part of the Sites other than the homepage. (d) Tag users or send email invitations to non-users without their consent. (e) Post anyone's identification documents or sensitive financial information on the Sites. (f) Otherwise take any action with respect to the materials on the Sites that is inconsistent with any other provision of these Terms of Use.`,
    terms_of_use__point_15_4: `15.4. The website from which you are linking, or on which you make certain content accessible, must comply in all respects with the Terms of Use.`,
    terms_of_use__point_15_5: `15.5. You agree to cooperate with us in causing any unauthorized framing or linking immediately to cease. We reserve the right to withdraw linking permission without notice.`,
    terms_of_use__point_15_6: `15.6. We may disable all or any social media features and any links at any time without notice in our discretion.`,
    terms_of_use__point_16: `16. Governing Law and Jurisdiction`,
    terms_of_use__point_16_1: `16.1. All matters relating to the Services and these Terms of Use and any dispute or claim arising therefrom or related thereto (in each case, including non-contractual disputes or claims) shall be governed by the laws of Singapore, excluding its conflicts of law provisions. You and DQ LAB agree to submit to the personal and exclusive jurisdiction of the courts located within Singapore, to resolve any dispute or claim arising from these Terms of Use or the Privacy Policy.`,
    terms_of_use__point_16_2: `16.2. Specifically excluded from application to these Terms of Use is the United Nations Convention on the International Sale of Goods.`,
    terms_of_use__point_17: `17. Accounts`,
    terms_of_use__point_17_1: `17.1. Some services on the Services permit or require you to create an account to participate or to secure additional benefits. You agree to provide, maintain and update true, accurate, current and complete information about yourself or your child as prompted by our registration processes. You are responsible for taking reasonable steps to maintain the confidentiality of your or your child’s username and password, and you are responsible for all activities under your or your child’s account that you can reasonably control. You shall not impersonate any person or misrepresent your or your child’s identity, including using another person's username, password or other account information, or another person's name. You agree to promptly notify us of any unauthorized use of your username, password or other account information, or of any other breach of security that you become aware of involving the Services.`,
    terms_of_use__point_17_2: `17.2 There are three (3) types of accounts you may register for: (a) Class Teacher Account (for class teachers); (b) Individual Account for 8-12 year olds (for individual users aged 8-12 who signed up for an account by themselves or via their parents); and (c) Individual Accounts for 13-18 year olds (for individual users aged 13-18 who signed up for an account by themselves or via their parents).`,
    terms_of_use__point_18: `18. User Generated Content and Account`,
    terms_of_use__point_18_1: `18.1. We do not pre-screen all UGC and do not endorse or approve any UGC that you and other users may contribute to the Services. You are solely responsible for your UGC and may be held liable for UGC that you post. Accordingly, we assume no liability for any action or inaction regarding transmissions, communications or content provided by any user or third party. We have no liability or responsibility to anyone for performance or non-performance of the activities described in this section.`,
    terms_of_use__point_18_2: `18.2. We respect the intellectual property rights of others. In order to upload your UGC to the Sites, you must first ensure that you have the legal right to do so.`,
    terms_of_use__point_18_3: `18.3. You may not upload or post any UGC on the Sites that infringes the copyright, trademark or other intellectual property rights of a third party nor may you upload UGC that violates the law, this Terms of Use and/or any third party's right of privacy or right of publicity. You may upload only UGC that you are permitted to upload by the owner or by law. DQ LAB may, without prior notice to you and in its sole judgment, remove UGC that may infringe the intellectual property or other rights of a third party. If you are a repeat infringer of the IP Owners' or a third party's intellectual property or other rights, DQ LAB may terminate your account without notice to you.`,
    terms_of_use__point_18_4: `18.4. Any UGC you post to the Sites will be considered non-confidential and non-proprietary. By providing any UGC on the Sites, you grant us and our affiliates and service providers, and each of their and our respective licensees, successors and assigns a worldwide, irrevocable, perpetual, non-exclusive, transferable, royalty-free license to use, reproduce, modify, perform, display, distribute and otherwise disclose to third parties any such material for any purpose.`,
    terms_of_use__point_18_5: `18.5. You represent and warrant that:`,
    terms_of_use__point_18_5_1: `18.5.1. You own or control all rights in and to the UGC and/or you have all rights, licenses, consents and releases that are necessary to grant the license referred to above to us and our affiliates and service providers, and each of their and our respective licensees, successors and assigns.`,
    terms_of_use__point_18_5_2: `18.5.2. All of your UGC do and will comply with these Terms of Use and all applicable laws and regulations.`,
    terms_of_use__point_18_5_3: `18.5.3. You understand and acknowledge that you are responsible for any UGC you submit or contribute, and you, not DQ LAB, have full responsibility for such content, including its legality, reliability, accuracy and appropriateness.`,
    terms_of_use__point_18_6: `18.6. We are not responsible, or liable to any third party, for the content or accuracy of any UGC posted by you or any other user of the Sites.`,
    terms_of_use__point_18_7: `18.7. We reserve the right (but have no obligation except as required by law) to:`,
    terms_of_use__point_18_7_1: `18.7.1. Remove, block, edit, move, disable or take any action with respect to any UGC for any reason, including when we determine that UGC violates these Terms of Use, infringes any intellectual property right or other right of any person or entity, threatens the personal safety of users of the Sites or the public, is in breach of any applicable laws or could create liability for DQ LAB. The decision to remove UGC or other Content at any time is in DQ LAB's sole and final discretion. To the maximum extent permitted by applicable law, DQ LAB does not assume any responsibility or liability for UGC or for removal of, UGC or any failure to or delay in removing, UGC or other Content.`,
    terms_of_use__point_18_7_2: `18.7.2. Disclose your identity or other information about you to any third party who claims that material posted by you violates their rights, including their intellectual property rights or their right to privacy.`,
    terms_of_use__point_18_7_3: `18.7.3. Take appropriate legal action, including without limitation, referral to law enforcement, for any illegal or unauthorized use of the Sites.`,
    terms_of_use__point_18_7_4: `18.7.4. Terminate or suspend your access to all or part of the Sites for any or no reason, including without limitation, any violation of these Terms of Use.`,
    terms_of_use__point_19: `19. Content Standards`,
    terms_of_use__point_19_1: `19.1. These Content Standards apply to any and all UGC and use the Services. UGC must in their entirety comply with all applicable laws and regulations. Without limiting the foregoing, UGC must not:`,
    terms_of_use__point_19_1_1: `19.1.1. Contain any material which is defamatory, obscene, indecent, abusive, offensive, harassing, violent, hateful, inflammatory or otherwise objectionable.`,
    terms_of_use__point_19_1_2: `19.1.2. Promote sexually explicit or pornographic material, violence, or discrimination based on race, sex, religion, nationality, disability, sexual orientation or age.`,
    terms_of_use__point_19_1_3: `19.1.3. Violate the legal rights (including the moral rights, rights of publicity and privacy) of others or contain any material that could give rise to any civil or criminal liability under applicable laws or regulations or that otherwise may be in conflict with these Terms of Use and our Privacy Policy.`,
    terms_of_use__point_19_1_4: `19.1.4. Be likely to deceive any person.`,
    terms_of_use__point_19_1_5: `19.1.5. Promote any illegal activity, or advocate, promote or assist any unlawful act.`,
    terms_of_use__point_19_1_6: `19.1.6. Cause annoyance, inconvenience or needless anxiety or be likely to upset, embarrass, alarm or annoy any other person.`,
    terms_of_use__point_19_1_7: `19.1.7. Impersonate any person, or misrepresent your identity or affiliation with any person or organization.`,
    terms_of_use__point_19_1_8: `19.1.8. Involve commercial activities or sales, such as contests, sweepstakes and other sales promotions, barter or advertising.`,
    terms_of_use__point_19_1_9: `19.1.9. Consist of or contain software viruses, solicitation or any form of “spam.”`,
    terms_of_use__point_19_1_10: `19.1.10. Give the impression that they emanate from or are endorsed by us or any other person or entity, if this is not the case.`,
    terms_of_use__point_20: `20. Copyright Infringement`,
    terms_of_use__end_statement: `If you believe that any UGC violates your copyright, please email us at <a href="mailto:contact@dqforall.com">contact@dqforall.com</a>. It is DQ LAB's policy to terminate the user accounts of repeat infringers.`,
}


const lc_ko = {
    terms_of_use__title: `이용약관`,
    terms_of_use__intro: `1. 개요`,
    terms_of_use__opening_paragraph_1: `DQ 평가 사이트는 디지털 지능 또는 DQ를 테스트할 수 있는 플랫폼을 제공합니다(<strong>"사이트"</strong>). 만 18세 미만의 아동/청소년이 사이트를 이용하도록 허용하기 전에 본 이용약관을 주의 깊게 읽으십시오. 사이트는 DQ LAB Pte. Ltd. 가 소유하고 운영합니다(<strong>"DQ LAB", "당사", "당사의"</strong>). 사이트의 DQ 테스트 서비스(<strong>"서비스"</strong>)는 어린이와 성인을 위한 디지털 지능에 대한 테스트로 구성됩니다. 본 이용약관은 귀하가 동의하고 받아들여야 효력을 발휘합니다. 서비스를 이용함으로써(또는 만 18세 미만 아동의 부모/보호자인 경우에 (개인정보 보호정책에 정의된 바와 같이) 부모의 동의를 제공함으로써) 본 이용약관과 당사의 개인정보보호정책에 준수하기로 동의합니다. 본 이용약관에 동의하지 않는 경우, 서비스를 설치, 사용, 등록하거나 서비스에 접근하지 마십시오. 귀하가 만 18세 미만 아동의 부모/보호자이며 본 이용약관에 동의하지 않는 경우, 자녀가 서비스를 설치, 사용, 등록하거나 서비스에 접근하도록 허용하지 마십시오.`,
    terms_of_use__opening_paragraph_2: `귀하는 본 이용약관에 전적으로 동의하고 준수할 수 있는 능력이 있다는 데 동의합니다.`,
    terms_of_use__point_2: `2. 지적 재산권 조항`,
    terms_of_use__point_2_1: `2.1. 사이트의 모든 콘텐츠, 정보, 코드, 날짜 또는 자료("콘텐츠")의 모든 지적 재산권은 DQ LAB PTE. LTD.에 속합니다. ("지적재산권 소유자"). 사이트의 모든 상표, 서비스 마크, 상호, 트레이드 드레스 등은 지적재산권 소유자에게 속합니다. 당사는 특별한 이유없이, 언제든지, 방식에 구애받지 않고 사이트의 콘텐츠 또는 기능을 변경하거나 삭제할 수 있습니다.`,
    terms_of_use__point_2_2: `2.2. 사이트에 표시된 상표, 로고, 서비스 마크 및 상표(총칭하여 "상표")는 DQ LAB 및 기타 소유자의 등록 및 미등록 상표이며, 권리 보유자와 관련이 없거나, 권리 보유자와 제휴관계가 아니거나, 권리 보유자가 승인하지 않은 제품 및 서비스에 대해 사용할 수 없습니다. 또한, 고객 혼란을 야기할 가능성이 있거나, 권리 보유자를 폄하하거나 권리보유자의 명예를 훼손할 수 있는 어떠한 방식으로든 사용할 수 없습니다. 사이트에 표시되는 DQ LAB이 소유하지 않은 모든 상표는 해당 소유자의 재산입니다. 사이트에 포함된 어떠한 내용도 암시, 금반언, 혹은 기타 방법 등을 통해 지적재산권 소유자 또는 해당 상표를 소유하고 있을 지도 모르는 제3자의 사전 서면 허가 없이 사이트에 표시된 상표를 사용할 수 있는 라이선스 또는 권리를 부여한다는 것으로 해석되어서는 안 됩니다. 사이트 또는 모바일 제품의 서비스에 표시되는 상표의 오용은 엄격히 금지됩니다. 지적재산권 소유자는 형사 기소를 포함하여 법의 모든 범위에서 상표권을 적극적으로 집행할 수 있는 모든 권리를 보유합니다.`,
    terms_of_use__point_3: `3. 금지 사항`,
    terms_of_use__point_3_1: `3.1. 귀하는 합법적인 목적을 위해서만 사이트를 사용할 수 있으며 본 이용약관을 준수해야합니다.`,
    terms_of_use__point_3_2: `3.2. 지적재산권 소유자 또는 해당 권리자의 명시적인 사전 서면 허가를 받지 않은 경우, 서비스 또는 콘텐츠의 상업적 또는 판촉적 배포, 게시 또는 이용은 엄격히 금지됩니다. 본 약관에 명시적된 경우들 뿐만 아니라, 귀하는 콘텐츠를 다운로드, 게시, 전시, 출판, 복사, 복제, 배포, 전송, 수정, 공연, 방송, 전송, 제작, 판매 또는 기타 방법으로 이용할 수 없습니다. 또한 귀하는 콘텐츠를 변경, 수정, 삭제, 제거할 수 없으며, 콘테츠의 의미,모양, 목적 등을 변경할 수 없고, 제약 없이 상표, 상호, 로고, 서비스 마크 또는 기타 소유권 콘텐츠 또는 소유권 권리를 변경 및 제거할 수 없다는 것에 동의합니다.`,
    terms_of_use__point_4: `4. 라이선스 제한`,
    terms_of_use__paragraph_3: `귀하는 컴퓨터, 인터넷 호환 기기 또는 모바일 기기에서 콘텐츠에 접근 및 열람할 수 있으며, 개인적, 비상업적, 대내적 사유에 한하여 서비스 내 콘텐츠의 단일 복사본 혹은 출력물을 허용합니다.`,
    terms_of_use__point_5: `5. 보증`,
    terms_of_use__point_5_1: `5.1. 귀하는 DQ LAB과 당사의 이사, 임원, 직원 및 대리인이 귀하의 사이트 사용이나 메시지, 콘텐츠, 정보, 스프트웨어, 사이트 내 다른 내용들의 게시 및 전송, 또는 법 혹은 본 이용약관에 대한 위반 및 위배로 인해 발생하는 모든 청구(계약, 불법행위, 여타 사유로 인해 발생하는 모든 청구), 책임, 손해, 합리적인 변호사 비용을 포함한 비용에 대하여 어떠한 책임이 없으며 이에 대한 책임을 묻지 않을 것임에 동의합니다.`,
    terms_of_use__point_5_2: `5.2. DQ LAB는 귀사에 의해 배상 대상이 되는 모든 사안의 배타적 방어 및 통제를 맡을 권리를 보유하며, 이 경우 귀하는 그러한 주장에 대한 DQ LAB의 방어에 협력하기로 동의합니다.`,
    terms_of_use__point_6: `6. 면책 및 제한 사항`,
    terms_of_use__point_6_1: `6.1. 본 콘텐츠는 일반적인 정보 제공을 목적으로 하며, 비디오 게임 중독자나 사이버폭력 피해자 등에게 의료적 구제를 제공하지 않습니다.`,
    terms_of_use__point_6_2: `6.2. 귀하나 귀하의 아동이 상기 문제들의 의심 증상을 보일 경우 전문 상담사나 의료진의 전문적인 조언을 구하십시오. 콘텐츠 사용에 대한 모든 책임과 위험은 사용자가 부담합니다. 어떠한 경우에도 DQ LAB, 기여자 및 직원, 제휴기관 및 제휴기관의 직원, 혹은 사이트를 만들고 유지하는데 관여한 어떠한 사람도 콘텐츠의 사용 및 오용으로 인해 직간접적으로 발생하는 직접적, 간접적, 부수적, 특수적, 혹은 결과적 손해, 상해 또는 손실에 대해 어떠한 책임도 지지 않으며, 이러한 책임은 본 조항을 통해 법률에서 허용하는 범위 내에서 완전히 배제됩니다.`,
    terms_of_use__point_6_3: `6.3. DQ LAB는 법률에 의해 허용되는 범위 내에서 본 사이트의 사용을 통해 제공되는 콘텐츠, 소프트웨어, 텍스트, 그래픽, 링크 또는 통신의 정확성, 신뢰성, 완전성, 현재성 또는 적시성에 대한 모든 진술 또는 보증을 제외합니다.`,
    terms_of_use__point_7: `7. 서비스 업데이트`,
    terms_of_use__paragraph_4: `DQ LAB은 활동 및 게임 플레이와 서비스의 사용 간의 균형을 맞추기 위해 특정 매개변수를 업데이트하거나 재설정해야할 수 있습니다. DQ LAB은 이러한 업데이트를 실행할 권리가 있지만 이러한 변경에 대한 책임이 있지는 않습니다.`,
    terms_of_use__point_8: `8. 제3자 서비스 및 콘텐츠`,
    terms_of_use__point_8_1: `8.1. 사이트를 이용할 때에, 공동 브랜딩 관계의 일부로써 당사의 로고를 사용하는 파트너, 스폰서, 그리고 콘텐츠 파트너들이 제공하는 링크와 같이, 당사의 서비스 범위를 벗어나는 다른 사이트로 연결되는 링크들이 있다는 것을 인지해야 합니다. 이와 같은 링크를 클릭할 때 귀하가 방문하는 사이트는 당사의 통제를 받지 않으며, 다른 이용약관 및 개인정보 보호 정책이 적용될 수 있으며, 당사는 이러한 사이트에 대해 책임을 지지 않습니다. 당사는 당사의 사이트에 포함된 모든 사이트 또는 타사 콘텐츠로 연결하거나 연결되는 타사 사이트의 제품 또는 서비스에 대해 귀하가 만족할 것이라고 보증하지 않습니다. 제3자와 온라인 또는 오프라인 거래를 진행하기 전에 필요하거나 적절하다고 생각되는 모든 조사를 실시할 것을 강력히 권장합니다.`,
    terms_of_use__point_8_2: `8.2. 귀하는 검색을 통해 발견되거나 검색 결과 리스트에 포함되거나 검색 결과 페이지에 식별된 사이트에 포함된 자료의 정확성, 관련성, 적법성, 또는 적절성에 대해 당사가 책임을 지지 않는다는 것을 인정합니다.`,
    terms_of_use__point_9: `9. 책임 제한`,
    terms_of_use__point_9_1: `9.1. 귀하는 당사가, 인터넷에서 다운로드 가능한 파일이나 서비스가 바이러스나 여타 악성 코드가 없음을 보장 및 보증하지 않는다는 것을 이해합니다. 귀하는 안티바이러스 보호 및 데이터 입력 및 출력의 정확성에 대한 귀하의 특정 요구사항을 충족하고 손실된 데이터의 재구성을 위해 당사 사이트 외부의 수단을 유지할 충분한 절차와 체크포인트를 시행할 책임이 있습니다.`,
    terms_of_use__point_9_2: `9.2. 당사는 귀하가 서비스 혹은 서비스를 통해 취득한 서비스나 항목을 사용하였거나 그것들 혹은 그것들에 연결된 서비스에 게시된 자료를 다운받는 것으로 인해 발생한, 컴퓨터 장비, 컴퓨터 프로그램, 데이터 또는 기타 소유권이 있는 자료을 감염시킬 수 있는 분산 서비스 거부 공격, 바이러스 또는 기타 기술적 위해 요소로 인해 생기는 어떠한 손실과 피해에 대해 책임을 지지 않습니다.`,
    terms_of_use__point_9_3: `9.3. 서비스, 콘텐츠, 서비스를 통해 얻은 어떠한 서비스나 항목들에 대한 귀하의 사용에 대한 책임은 본인에게 있습니다.  서비스, 콘텐츠, 서비스를 통해 얻은 어떠한 서비스나 항목들은 "현 상태 그대로 (as is)", 또 " 이용 가능한 상태로 (as available)" 법이 허용하는 한도 내에서 명시적이거나 암묵적인 보증 없이 제공됩니다. 당사와 DQ LAB에 연관된 어떠한 사람도 서비스의 완성도, 보안, 안정성, 품질, 정확성 또는 서비스의 이용가능성을 보장하거나 대표하지 않습니다. 앞서 언급한 내용을 제한하지 않고, 당사와 DQ LAB에 연관된 어떠한 사람도 서비스, 콘텐츠, 서비스를 통해 얻은 어떠한 서비스나 항목들이 정확하고, 믿을만하고, 오류가 없고, 중단되지 않으며, 결함이 수정되고, 사이트나 서버가 바이러스 혹은 여타 위해 요소로부터 자유롭고, 서비스나 서비스를 통해 얻은 어떠한 서비스나 항목들로 인해 귀하의 필요와 기대가 충족된다고 보장하거나 대표하지 않습니다.`,
    terms_of_use__point_9_4: `9.4. 법이 허용하는 최대 범위 내에서, DQ LAB은 명시적이든 암묵적이든 법적이든 관계없이 상품성, 비침해성, 특정 목적에 대한 적합성에 대한 보증을 포함하되 이에 국한되지 않는 모든 종류의 보증을 거부합니다.`,
    terms_of_use__point_9_5: `9.5. 어떠한 경우에도 DQ LAB, 계열사 또는 라이선스 소유자, 서비스 제공자, 직원, 대리인, 임원 또는 이사들은 서비스, 서비스로 연결하는 웹사이트, 서비스 내의 콘텐츠, 서비스나 기타 웹사이트를 통해 얻은 기타 웹사이트, 서비스 또는 항목으로 인해 발생한 직접적, 간접적, 특수적, 부수적, 결과적, 처벌적 손해, 고통과 괴로움, 정신적 고통, 수익 손실, 영업권 손실, 데이터 손실, (태만, 과실을 포함한) 불법행위로 인해 발생한 계약 위반을 포함한 (단, 이에 국한되지 않음) 각종 손해에 대해서 그 손해가 예측 가능하다고 하더라도 어떠한 법 이론 하에서든 책임을 지지 않습니다.`,
    terms_of_use__point_10: `10. 문제 제기에 대한 시간 제한`,
    terms_of_use__paragraph_5: `법이 허용하는 범위 내에서, 본 이용약관이나 서비스로 인해, 혹은 본 이용약관이나 서비스과 관련되어 발생할 지도 모르는 소송 혹은 청구의 원인은 그 원인이 발생한 시점으로부터 1년 이내에 시작되어야합니다. 그렇지 않을 경우, 해당 소송 혹은 청구의 원인은 영구적으로 사용할 수 없습니다.`,
    terms_of_use__point_11: `11. 권리불포기 조항`,
    terms_of_use__paragraph_6: `본 이용약관에 명시된 어떠한 조항이나 조건에 대한 DQ LAB의 어떠한 권리 포기도 해당 조항이나 조건에 대한 추가적이고 지속적인 권리 포기나 기타 조항이나 조건에 대한 권리 포기로 간주되어서는 안되며, 당사가 본 이용약관의 권리나 조항을 주장을 하지 못하더라도 해당 권리나 조항에 대한 권리 포기로 여겨져서는 안 된다.`,
    terms_of_use__point_12: `12. 가분성 조항`,
    terms_of_use__para_7: `본 이용약관의 조항이 법원 또는 관할 관할 재판소에서 어떠한 이유로든 무효, 불법 또는 집행 불가능한 것으로 판단될 경우, 나머지 조항이 완전한 효력을 유지할 수 있는 범위에서 해당 조항은 삭제되거나 최소한으로 제한될 수 있습니다.`,
    terms_of_use__point_13: `13. 완전 합의`,
    terms_of_use__para_8: `이용약관, 개인정보 보호정책 및 보호자 동의서(있는 경우)는 본 서비스와 관련하여 귀하와 DQ LAB 간의 단독적이고 완전한 합의를 구성하며, 본 서비스와 관련하여 서면 및 구두로 이루어진 과거와 현재의 이해, 동의서, 진술 및 보증을 모두 대체합니다.`,
    terms_of_use__point_14: `14. 모바일 네트워크`,
    terms_of_use__para_9: `모바일 네트워크를 통해 본 서비스에 액세스하는 경우, 귀하의 네트워크 또는 로밍 공급자의 메시징, 데이터 및 기타 요율과 요금이 적용됩니다. 특정 서비스의 다운로드, 설치 또는 사용은 네트워크 공급자에 의해 금지되거나 제한될 수 있으며 일부 서비스는 귀하의 네트워크 공급사나 장치에서 작동하지 않을 수 있습니다. 당사는 이러한 수수료, 금지 또는 제한에 대해 책임지지 않습니다.`,
    terms_of_use__point_15: `15. 사이트 및 소셜미디어 기능으로의 연결`,
    terms_of_use__point_15_1: `귀하는 정당하고 적법하며 당사의 명예를 훼손하거나 이윤을 추구하지 않는 방식으로 당사 사이트를 링크할 수 있지만 당사의 명시적 서면 동의 없이 당사 측의 제휴, 승인 또는 보증을 제안하기 위해 링크를 사용해서는 안 됩니다.`,
    terms_of_use__point_15_2: `사이트는 다음을 가능하게 하는 특정 소셜미디어 기능을 제공할 수 있습니다. (a) 귀하 소유의 또는 특정 타사 웹 사이트에서 당사 사이트의 특정 콘텐츠로 링크할 수 있습니다. (b) 사이트의 특정 콘텐츠나 특정 콘텐츠로의 링크를 포함하여 이메일이나 기타 통신을 전송할 수 있습니다. (c) 사이트 콘텐츠의 제한된 부분이 귀하 자신 또는 특정 제3자 웹사이트에 표시되거나 표시되는 것처럼 보이게 합니다. (d)사이트의 다른 사용자와 연결합니다. (e) 사이트의 모든 사용자가 해당 정보를 접근하고 사용하며 귀하와 연결할 수 있도록 사용자 생성 콘텐츠("UGC")를 발행할 수 있습니다. (f) UGC에 댓글을 달 수 있습니다. (g) 사이트의 다른 사용자에게 UGC를 태그(혹은 링크)할 수 있습니다.`,
    terms_of_use__point_15_3: `15.3. 귀하는 이러한 기능을 당사가 제공하는 대로, 표시되는 콘텐츠에 대해서만 사용할 수 있으며, 그렇지 않으면 그러한 기능과 관련하여 당사가 제공하는 추가 이용약관에 따라 사용할 수 있습니다. 앞서 말한 내용에 따라 다음 행위를 해서는 안 됩니다 (a) 귀하가 소유하지 않은 웹사이트에서 링크를 설정하는 행위. (b) 사이트 또는 사이트의 일부가 다른 사이트에서 예를 들어 프레이밍, 딥 링크 또는 인라인 링크를 통해 표시되거나 표시되는 것처럼 보이게 하는 행위. (c) 홈페이지 이외의 사이트로 링크하는 행위. (d) 동의 없이 사용자를 태그하거나 비사용자에게 이메일 초대장을 보내는 행위. (e) 사이트에 다른 사람의 신원 확인 문서 또는 민감한 금융 정보를 게시하는 행위. (f) 사이트의 자료와 관련하여 본 이용 약관의 다른 조항과 일치하지 않는 행위.`,
    terms_of_use__point_15_4: `15.4. 귀하가 연결하는 웹사이트 또는 특정 콘텐츠에 액세스할 수 있도록 하는 웹사이트는 모든 면에서 본 이용약관을 준수해야 합니다.`,
    terms_of_use__point_15_5: `15.5. 귀하는 무단 프레이밍 또는 링크를 즉시 중단하는 데 당사와 협력하는 데 동의합니다. 당사는 사전 통지 없이 링크 허가를 철회할 수 있는 권리를 보유합니다.`,
    terms_of_use__point_15_6: `15.6. 당사는 재량에 따라 사전 통지 없이 모든 소셜 미디어 기능 및 링크를 언제든지 비활성화할 수 있습니다.`,
    terms_of_use__point_16: `16. 준거법 및 관할권`,
    terms_of_use__point_16_1: `16.1. 서비스 및 본 이용 약관과 관련된 모든 문제와 그로부터 발생하거나 이와 관련된 모든 분쟁 또는 청구(각각의 경우 비계약 분쟁 또는 청구 포함)는 법률 조항의 충돌하는 경우을 제외하고 싱가포르 법률의 적용을 받습니다. 귀하와 DQ LAB은 본 이용 약관 또는 개인 정보 보호 정책으로 인해 발생하는 모든 분쟁 또는 청구를 해결하기 위해 싱가포르에 위치한 법원의 개인적이고 독점적인 관할권에 따르는 데 동의합니다.`,
    terms_of_use__point_16_2: `16.2. 본 이용 약관의 적용에서 특별히 제외되는 것은 국제 물품 매매에 관한 유엔 협약입니다.`,
    terms_of_use__point_17: `17. 계정`,
    terms_of_use__point_17_1: `17.1. 서비스 중 일부 서비스는 참여하거나 추가 혜택을 제공하기 위해 계정 생성을 허용하거나 요구합니다. 귀하는 당사의 등록 절차에 따라 귀하 또는 귀하의 자녀에 대한 사실이고 정확하며 최신의 완전한 정보를 제공, 유지 및 업데이트하는 데 동의합니다. 귀하는 귀하 또는 귀하 자녀의 사용자 이름과 비밀번호의 기밀성을 유지하기 위해 합당한 조치를 취할 책임이 있으며 귀하 또는 귀하가 합리적으로 제어할 수 있는 귀하 또는 귀하 자녀의 계정에 따른 모든 활동에 대한 책임이 있습니다. 귀하는 다른 사람의 사용자 이름, 비밀번호 또는 기타 계정 정보 또는 다른 사람의 이름을 사용하는 것을 포함하여 다른 사람을 사칭하거나 귀하 또는 귀하 자녀의 신원을 허위로 표시해서는 안 됩니다. 귀하는 귀하의 사용자 이름, 비밀번호 또는 기타 계정 정보의 무단 사용 또는 서비스와 관련하여 알게 된 기타 보안 위반 사항을 즉시 당사에 알리는 데 동의합니다.`,
    terms_of_use__point_17_2: `17.2 등록할 수 있는 계정에는 세 가지 유형이 있습니다. (a) 담임교사 계정(담임교사용) (b) 8-12세 개인 계정(직접 또는 부모를 통해 계정에 등록한 8-12세 개인 사용자용) (c) 13-18세 개인 계정(직접 또는 부모를 통해 계정에 등록한 13-18세 개인 사용자용)`,
    terms_of_use__point_18: `18. 사용자 생성 콘텐츠 (User Generated Content - UGC) 및 계정`,
    terms_of_use__point_18_1: `18.1. 당사는 모든 UGC를 사전 심사하지 않으며 귀하와 다른 사용자가 서비스에 기여할 수 있는 UGC를 보증하거나 승인하지 않습니다. 귀하는 귀하의 UGC에 대해 전적인 책임을 지며 귀하가 게시하는 UGC에 대해 책임을 질 수 있습니다. 따라서 당사는 사용자 또는 제3자가 제공한 전송, 통신 또는 콘텐츠와 관련된 활동 또는 비활동에 대해 책임을 지지 않습니다. 당사는 본 절에서 설명한 활동의 이행 또는 불이행에 대해 누구에게도 책임을 지지 않습니다.`,
    terms_of_use__point_18_2: `18.2. 당사는 타인의 지적 재산권을 존중합니다. UGC를 사이트에 업로드하려면 먼저 그렇게 할 수 있는 법적 권리가 있는지 확인해야 합니다.`,
    terms_of_use__point_18_3: `3. 귀하는 제3자의 저작권, 상표 또는 기타 지적 재산권을 침해하는 UGC를 사이트에 업로드하거나 게시할 수 없으며, 법률, 본 이용 약관 및/또는 제3자의 사생활 보호의 권리나 퍼블리시티권을 위반하는 UGC를 업로드할 수 없습니다. 귀하는 소유자 또는 법률에 의해 업로드가 허용된 UGC만 업로드할 수 있습니다. DQ LAB은 귀하에 대한 사전 통지 없이 단독 판단에 따라 제3자의 지적 재산권 또는 기타 권리를 침해할 수 있는 UGC를 제거할 수 있습니다. 귀하가 IP 소유자 또는 제3자의 지적 재산권 또는 기타 권리를 반복적으로 침해하는 경우 DQ LAB은 통지 없이 귀하의 계정을 중지할 수 있습니다.`,
    terms_of_use__point_18_4: `18.4. 사이트에서 UGC를 제공함으로써 귀하는 당사와 당사의 계열사 및 서비스 제공자, 그리고 이들 각각과 당사의 라이선스 소유자에게 승계인의 자격을 부여하고 어떠한 목적으로든 해당 자료를 사용, 복제, 수정, 수행, 표시, 배포 및 제3자에게 공개할 수 있는 전 세계적이고 취소 불가능하며 영구적이고 비독점적이고 양도 가능하며 로열티 없는 라이센스를 할당합니다.`,
    terms_of_use__point_18_5: `18.5. 귀하는 다음을 진술하고 보증합니다.`,
    terms_of_use__point_18_5_1: `18.5.1. 귀하는 UGC 또는 UGC에 대한 모든 권리를 소유 또는 통제하며 당사와 당사의 계열사 및 서비스 제공업체, 그리고 이들과 당사 각각의 라이선스 보유자, 승계자, 그리고 양수인에게 위에 언급된 라이선스를 부여하는데 필요한 모든 권리, 라이선스, 동의 및 해제를 보유합니다.`,
    terms_of_use__point_18_5_2: `18.5.2. 귀하의 모든 UGC는 본 이용약관과 모든 관련 법률 및 규정을 준수할 것입니다.`,
    terms_of_use__point_18_5_3: `18.5.3. 귀하는 귀하가 제출하거나 기여한 모든 UGC에 대한 책임이 귀하에게 있으며 DQ LAB이 아닌 귀하가 합법성, 신뢰성, 정확성 및 적절성을 포함하여 해당 콘텐츠에 대한 전적인 책임이 있음을 이해하고 인정합니다.`,
    terms_of_use__point_18_6: `18.6. 당사는 귀하 또는 사이트의 다른 사용자가 게시한 UGC의 내용이나 정확성에 대해 책임을 지지 않으며 제3자에 대해 책임을 지지 않습니다.`,
    terms_of_use__point_18_7: `18.7. 당사는 다음과 같은 권리를 보유합니다(그러나 법에서 요구하는 경우를 제외하고는 의무가 없습니다):`,
    terms_of_use__point_18_7_1: `18.7.1. UGC가 본 이용 약관을 위반하거나, 지적 재산권이나 개인이나 단체의 기타 권리를 침해하거나, 사이트 사용자 또는 대중의 개인 안전을 위협하거나, 관련 법률을 위반하거나, DQ LAB에 책임을 만드는 것으로 당사가 결정하는 경우들을 포함하여, 어떤 UGC든 이유불문 삭제, 차단, 수정, 이동, 이용불가, 혹은 기타 조치 처리될 수 있습니다. 언제든지 UGC 또는 기타 콘텐츠를 제거하는 결정은 DQ LAB의 단독 최종 재량입니다. 해당 법률이 허용하는 최대 범위 내에서 DQ LAB은 UGC 또는 UGC 또는 UGC 또는 기타 콘텐츠의 제거의 실패 또는 지연에 대해 어떠한 책임도 지지 않습니다.`,
    terms_of_use__point_18_7_2: `18.7.2. 귀하가 게시한 자료가 지적 재산권 또는 개인 정보 보호 권리를 포함하여 자신의 권리를 침해한다고 주장하는 제3자에게 귀하의 신원 또는 기타 정보를 공개하십시오.`,
    terms_of_use__point_18_7_3: `18.7.3. 사이트의 불법적이거나 허가 받지 않은 사용에 대해 법 집행 기관에 회부하는 것을 포함하되 이에 국한되지 않는 적절한 법적 조치를 취하십시오.`,
    terms_of_use__point_18_7_4: `18.7.4. 본 이용 약관의 위반을 포함하되 이에 국한되지 않는 어떠한 이유로든 사이트의 전체 또는 일부에 대한 액세스를 종료하거나 중지합니다.`,
    terms_of_use__point_19: `19. 콘텐츠 표준`,
    terms_of_use__point_19_1: `19.1. 본 콘텐츠 표준은 모든 UGC에 적용되며 본 서비스를 사용합니다. UGC는 모든 관련 법률 및 규정을 전체적으로 준수해야 합니다. 앞서 언급한 내용을 제한하지 않고 UGC는 다음의 항목들에 해당되지 않아야합니다.`,
    terms_of_use__point_19_1_1: `19.1.1. 명예 훼손, 외설, 외설, 학대, 공격, 괴롭힘, 폭력, 증오, 선동 또는 기타 불쾌감을 주는 자료를 포함하는 행위.`,
    terms_of_use__point_19_1_2: `19.1.2. 인종, 성별, 종교, 국적, 장애, 성적 취향 또는 연령을 근거로 노골적인 성적 자료 또는 포르노 자료, 폭력 또는 차별을 조장하는 행위.`,
    terms_of_use__point_19_1_3: `19.1.3. 타인의 법적 권리(도덕적 권리, 퍼블리시티권 및 사생활 보호의 권리를 포함함)를 침해하거나 관련 법률 또는 규정에 따라 민형사상 책임을 유발할 수 있는 자료를 포함하고  본 이용 약관 및 당사의 개인정보 보호정책과 충돌할 수 있는 행위.`,
    terms_of_use__point_19_1_4: `19.1.4. 누군가를 속일 가능성이 있는 행위.`,
    terms_of_use__point_19_1_5: `19.1.5. 불법 활동을 조장하거나 불법 행위를 옹호, 조장 또는 지원하는 행위.`,
    terms_of_use__point_19_1_6: `19.1.6. 성가심, 불편 또는 불필요한 불안을 야기하거나 다른 사람을 화나게 하거나 당황하게 하거나 놀라게 하거나 성가시게 할 수 있는 행위.`,
    terms_of_use__point_19_1_7: `19.1.7. 다른 사람을 사칭하거나 귀하의 신원 또는 개인 또는 조직과의 제휴 관계를 허위로 표시하는 행위.`,
    terms_of_use__point_19_1_8: `19.1.8. 콘테스트, 경품 및 기타 판매 판촉, 물물교환 또는 광고와 같은 상업적 활동 또는 판매에 연루되는 행위.`,
    terms_of_use__point_19_1_9: `19.1.9. 소프트웨어 바이러스, 권유 또는 모든 형태의 "스팸"으로 구성되거나 이를 포함하는 행위.`,
    terms_of_use__point_19_1_10: `19.1.10. 실제로 그렇지 않음에도 당사 또는 기타 개인이나 단체에서 나왔거나 승인을 받았다는 인상을 전달하는 행위.`,
    terms_of_use__point_20: `20. 저작권 침해`,
    terms_of_use__end_statement: `UGC가 귀하의 저작권을 침해한다고 판단되는 경우 <a href="mailto:contact@dqforall.com">contact@dqforall.com 이메일을 보내주십시오</a>. 상습 침해자의 사용자 계정을 해지하는 것이 DQ LAB의 정책입니다.`,
}


export const LC_VALUES_TERMS_OF_USE = {
    'en': lc_en,
    'ko': lc_ko
}

const validate_keys_syncing = () => {
    const en_keys_count = Object.keys(lc_en).length;
    const ko_keys_count = Object.keys(lc_ko).length;
    if (en_keys_count === ko_keys_count) {
        console.log('lc_values::terms_of_use:: keys are in sync');
    } else {
        console.log('lc_values::terms_of_use:: FATAL ISSUE : keys not in sync');
    }
}

validate_keys_syncing();