import React, { useState } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { AuthContext } from "./context/auth";
import { GlobalStateContext } from "./context/global_state";
import {LOCAL_STORAGE_KEYS} from "./utils/constants";
import Toast from "./components/UI/Toast/Toast.ui";

import Routes from './Routes';

const browserHistory = createBrowserHistory();

const App = () => {

	/*
	* ***********
	* Auth Context Data
	* ***********
	*/
	let existingToken;
	let existingUser;
	if (typeof window !== "undefined") {
		existingToken = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN));
		existingUser = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_USER));
	}

	const [authToken, setAuthToken] = useState(existingToken);
	const [authUser, setAuthUser] = useState(existingUser);

	const setToken = (data) => {
		localStorage.setItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN, JSON.stringify(data));
		setAuthToken(data);
	}

	const setUser = (data) => {
		localStorage.setItem(LOCAL_STORAGE_KEYS.AUTH_USER, JSON.stringify(data));
		setAuthUser(data);
	}

	/*
	* ***********
	* Global Context Data
	* ***********
	*/
	const [toastShow, _setToastShow] = useState(false);
	const [toastMessage, _setToastMessage] = useState('');
	const [profile, _setProfile] = useState({'badges': {}, 'org_code': ''});

	const setToastShow = (flag) => {
		_setToastShow(flag);
	}
	const setToastMessage = (message) => {
		_setToastMessage(message);
	}
	const setProfile = (profile) => {
		_setProfile(profile);
	}

	/*
	* ***********
	* Main App Body
	* ***********
	*/
	return (
		<div id="app">
			<AuthContext.Provider value={{
				authToken,
				setAuthToken: setToken,
				authUser,
				setAuthUser: setUser
			}}>
				<GlobalStateContext.Provider value={{
					toastShow,
					setToastShow,
					toastMessage,
					setToastMessage,
					profile,
					setProfile
				}}>
					<Router history={browserHistory}>
						<Routes />
					</Router>
					<Toast />
				</GlobalStateContext.Provider>

			</AuthContext.Provider>

		</div>
	);
}

export default App;
