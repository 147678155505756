import React, {useEffect} from 'react';
import { withRouter } from "react-router";
import {LOCAL_STORAGE_KEYS} from "../../../utils/constants";
import {getOrgByAppKey} from "../../../services/app_start.service";
import {LoaderDiv} from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";


const Landing = (props) => {

    const {history, location} = props;

    useEffect( ()=>{
        preLoading().then('pre-loading completed');
    }, [] )

    const clearLocalStorage = () => {
        const access_key = localStorage.getItem(LOCAL_STORAGE_KEYS.UNDER_MAINTENANCE_ACCESS_KEY)
        localStorage.clear()
        localStorage.setItem(LOCAL_STORAGE_KEYS.UNDER_MAINTENANCE_ACCESS_KEY, access_key);
    }

    const preLoading = async () => {
        // get org_code from the profile
        const profile_org = localStorage.getItem(LOCAL_STORAGE_KEYS.ORG_CODE_OF_DB_PROFILE);
        if (profile_org && profile_org !== '') {

        }

        const params = new URLSearchParams(location.search);
        const app_key = params.get('app_key');

        let target_org_code;
        let save_app_key = false
        let goto_dq_info_page = false;
        // let failed_block_a = false;

        // // decide here that for which company have to run the application. **** this block not needed, as org will be decided on loging now, instead of app level
        // if (app_key && app_key !== '') {
        //     // case-1
        //     try {
        //         const res = await getOrgByAppKey(app_key)
        //         if (res.data && res.data.org_code) {
        //             target_org_code = res.data.org_code;
        //
        //             // save the working app-key in the localStorage as well
        //             save_app_key = true;
        //         } else {
        //             failed_block_a = true;
        //         }
        //     } catch (e) {
        //         failed_block_a = true;
        //     }
        // } else {
        //     failed_block_a = true
        // }
        //
        // if (failed_block_a) {
        //     console.log('went to the fallback section :: case-2')
        //     // case-2
        //     target_org_code = process.env.REACT_APP_ORG_CODE
        //     goto_dq_info_page = true
        // }

        if (profile_org !== '') {
            console.log('profile_org --> ', profile_org);
            // end the function here, just resuming application
            console.log('**LANDING PAGE*** ---> RESUMING APPLICATION');
            history.push('/home')
            return;
        } else {
            history.push('/login');
            return;
        }

        // console.log('**LANDING PAGE*** ---> GOING TO START FRESH');
        // // force-logout & clear local-storage.
        // clearLocalStorage()

        // // set the new org_code in localstorage
        // localStorage.setItem(LOCAL_STORAGE_KEYS.ACTIVE_ORG_CODE, target_org_code);
        // if (save_app_key) {
        //     localStorage.setItem(LOCAL_STORAGE_KEYS.ACTIVE_APP_KEY, app_key);
        // }


        //  Following code commented out, moved the guest logic to register & login screens

        // // fetch the guest-user
        // // set it to global-storage and app-context
        // try {
        //     const result = await getGuestUser(target_org_code);
        //     console.log('result.data -> ', result.data);
        //     if (result.data && result.data.user) {
        //         await setAuthToken(result.data.auth_token);
        //         await setAuthUser(result.data.user);
        //     }
        // } catch (e) {
        //     setToastShow(true);
        //     setToastMessage(e.message);
        // }
        //
        // // add the local_storage variable with name FIRST_TIME_BOOT
        // localStorage.setItem(LOCAL_STORAGE_KEYS.FIRST_TIME_BOOT, true);
        //

        if (goto_dq_info_page) {
            history.push('/info_dq')
        } else {
            history.push('/home')
        }

        // history.push('/home')
    }

    return (
        <LoaderDiv />
    );
}


export default withRouter(Landing);