import React, {useState, useEffect} from 'react';
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import { withRouter } from "react-router";
import Helmet from "react-helmet";
import {useLocation} from "react-router-dom";
import {useAuth} from "../../../context/auth";
import {useGlobalState} from "../../../context/global_state";
import {passReset, loginUserViaToken, logoutUser} from "../../../services/auth.service";
import {LOCAL_STORAGE_KEYS, LOCALES_ENABLED} from "../../../utils/constants";
import ReactHtmlParser from "react-html-parser";
import {GET_APP_LOCALIZE_TEXT} from "../../../utils/dq_lc_service/lc_service";

const PasswordReset = (props) => {

    const { history, location } = props;

    const page_location = useLocation();
    const pageLc = page_location.pathname.split('/')[1];

    const [loading, setLoading] = useState(false);
    const [formPassword, setFormPassword] = useState('');
    const [formConfirmPassword, setFormConfirmPassword] = useState('');
    const [message, setMessage] = useState(undefined);
    const [resetToken, setResetToken] = useState(undefined);
    const [success, setSuccess] = useState(false);

    const { setAuthToken, setAuthUser, authToken, authUser } = useAuth();
    const { setToastShow, setToastMessage, setProfile } = useGlobalState();

    useEffect( () => {
        const params = new URLSearchParams(location.search);
        const reset_token = params.get('reset_token');
        if (authToken && authUser) {
            LogoutActionWithoutRedirect().then(r=>console.log('logged out of current account automatically'));
        }
        if(reset_token) {
            setResetToken(reset_token);
        }
        else {
            setMessage(`
                Invalid Reset Password Link
                <br/>
                <br/>
                <div>
                    <a href='/recover'>Recover Password Here</a>
                </div>
            `)
        }
    }, [] );

    const resetPassAction = async () => {

        // check that no input field is null or blank
        if ([formPassword, formConfirmPassword].includes('')) {
            setToastShow(true);
            setToastMessage(GET_APP_LOCALIZE_TEXT(pageLc, 'persist_guest__plz_fill_req_fields'))
            return
        }

        // check if users have checked the agreement box

        // check if both the password matches
        if (formPassword !== formConfirmPassword) {
            setToastShow(true);
            setToastMessage(GET_APP_LOCALIZE_TEXT(pageLc, 'uo__pass_mismatch_plz_retype'))
            return
        }

        try {
            setLoading(true);
            const result = await passReset(resetToken, formPassword);
            if(result.data.updated_password){
                setSuccess(true);
            }
            else {
                setToastShow(true);
                setToastMessage('Failed to send reset password');
            }
            setLoading(false);

        } catch (e) {
            setToastShow(true);
            setToastMessage(e.message);
            setLoading(false);
        }
    }

    const LogoutActionWithoutRedirect = async () => {

        try {
            await logoutUser();
            setAuthToken(null);
            setAuthUser(null);
            setProfile(null);
            localStorage.clear();

            const locale = location.pathname.split('/')[1];
            if (LOCALES_ENABLED.includes(locale)) {
                localStorage.setItem(LOCAL_STORAGE_KEYS.ACTIVE_LOCALE, locale);
            }
        } catch (e) {
            setToastShow(true);
            setToastMessage(e.message);
        }
    }
    const onChangePass = (e) => {
        setFormPassword(e.target.value);
    }
    const onChangeConfirmPass = (e) => {
        setFormConfirmPassword(e.target.value);
    }

    const goToLoginAction = () => {
        history.push('/login')
    }

    return (
        <div className={'pass-reset-container'}>
            <LoaderWithBackDrop loading={loading} />
            {
                message ?
                    <div className={'pass-reset-error'}>{ReactHtmlParser(message)}</div>
                    :
                    success ?
                        <>
                            <div className='pass-reset-title'>
                                <p>
                                    {ReactHtmlParser(GET_APP_LOCALIZE_TEXT(pageLc, 'persist_guest__reset_pass_success'))}
                                </p>
                            </div>
                            <div className={'pass-reset-content'}>
                                <div className={'pass-reset-submit'}>
                                    <button onClick={goToLoginAction}>{GET_APP_LOCALIZE_TEXT(pageLc, 'persist_guess__go_to_login')}</button>
                                </div>
                            </div>
                        </>
                    :
                        <>
                            <div className={'pass-reset-content'}>
                                <form onSubmit={e => e.preventDefault()}>
                                    <div className={'pass-reset-input'}>
                                        <label>{GET_APP_LOCALIZE_TEXT(pageLc, 'persist_guest__password')}</label>
                                        <input
                                            type='password'
                                            name={'password'}
                                            id={'password'}
                                            value={formPassword}
                                            onChange={onChangePass}
                                        />
                                    </div>
                                    <div className={'pass-reset-input'}>
                                        <label>{GET_APP_LOCALIZE_TEXT(pageLc, 'persist_guest__confirm_password')}</label>
                                        <input
                                            type='password'
                                            name={'confirm_password'}
                                            id={'confirm_password'}
                                            value={formConfirmPassword}
                                            onChange={onChangeConfirmPass}
                                        />
                                    </div>
                                    <div className={'pass-reset-submit'}>
                                        <button onClick={resetPassAction}>{GET_APP_LOCALIZE_TEXT(pageLc, 'persist_guest__reset_password')}</button>
                                    </div>
                                </form>
                            </div>
                        </>

            }
        </div>
    );
}

export default withRouter(PasswordReset);