import { invokeApi } from '../utils/invokeApi';
import {LOCAL_STORAGE_KEYS} from "../utils/constants";

export const loginUser = (email, password, remember_me) => {
    const locale = localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVE_LOCALE);
    let requestObj = {
        path   : '/my_dq/auth/login',
        method : 'POST',
        headers : {
            'xc-dq-locale': locale
        }
    };
    const postData = {
        email,
        password,
        remember_me
    };
    requestObj['postData'] = postData;

    return invokeApi(requestObj);
}

export const loginUserViaToken = (access_token) => {
    const locale = localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVE_LOCALE);
    let requestObj = {
        path   : '/my_dq/auth/login_via_token',
        method : 'POST',
        headers : {
            'xc-dq-locale': locale
        }
    };
    requestObj['postData'] = {
        access_token
    };

    return invokeApi(requestObj);
}

export const setPasswordViaToken = (access_token, password) => {
    const locale = localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVE_LOCALE);
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN));
    let requestObj = {
        path   : '/my_dq/auth/set_password/via_token',
        method : 'POST',
        headers : {
            Authorization : `Bearer ${token}`,
            'xc-dq-locale': locale
        }
    };
    requestObj['postData'] = {
        access_token,
        password
    };

    return invokeApi(requestObj);
}

export const initPassReset = (email) => {
    const locale = localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVE_LOCALE);
    let requestObj = {
        path   : '/my_dq/auth/init_reset_password',
        method : 'POST',
        headers : {
            'xc-dq-locale': locale
        }
    };
    const postData = {
        email,
    };
    requestObj['postData'] = postData;

    return invokeApi(requestObj);
}

export const passReset = (reset_token, password) => {
    const locale = localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVE_LOCALE);
    let requestObj = {
        path   : '/my_dq/auth/reset_password',
        method : 'POST',
        headers : {
            'xc-dq-locale': locale
        }
    };
    const postData = {
        reset_token,
        password
    };
    requestObj['postData'] = postData;

    return invokeApi(requestObj);
}

export const registerUser = (email, password, first_name, last_name, access_code, remember_me, showAccessCode) => {
    const locale = localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVE_LOCALE);
    let requestObj = {
        path   : '/my_dq/auth/register',
        method : 'POST',
        headers : {
            'xc-dq-locale': locale
        }
    };
    let access_code_required = false;
    if (showAccessCode) {
        access_code_required = true;
    }
    const postData = {
        email,
        password,
        first_name,
        last_name,
        access_code,
        remember_me,
        access_code_required
    };
    requestObj['postData'] = postData;

    return invokeApi(requestObj);
}

export const persistGuestRegisterUser = (email, password, first_name, last_name, access_code, remember_me, showAccessCode) => {
    const locale = localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVE_LOCALE);
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN));
    let requestObj = {
        path   : '/my_dq/auth/persist_guest/register',
        method : 'POST',
        headers : {
            Authorization : `Bearer ${token}`,
            'xc-dq-locale': locale
        }
    };
    let access_code_required = false;
    if (showAccessCode) {
        access_code_required = true;
    }
    const postData = {
        email,
        password,
        first_name,
        last_name,
        access_code,
        remember_me,
        access_code_required
    };
    requestObj['postData'] = postData;

    return invokeApi(requestObj);
}

export const persistGuestLoginUser = (email, password, remember_me) => {
    const locale = localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVE_LOCALE);
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN));
    let requestObj = {
        path   : '/my_dq/auth/persist_guest/login',
        method : 'POST',
        headers : {
            Authorization : `Bearer ${token}`,
            'xc-dq-locale': locale
        }
    };
    const postData = {
        email,
        password,
        remember_me
    };
    requestObj['postData'] = postData;

    return invokeApi(requestObj);
}


export const logoutUser = () => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN));
    let requestObj = {
        path   : '/my_dq/auth/logout',
        method : 'POST',
        headers : {
            Authorization : `Bearer ${token}`
        }
    };
    requestObj['postData'] = {
        all_devices: true
    };

    return invokeApi(requestObj);
};