import React from 'react';
import {Switch, Redirect, Router, Route} from 'react-router-dom';

import Splash from './pages/Splash/Splash.page';
import Landing from './pages/Auth/Landing/Landing.page';
import Home from './pages/Home/Home.page';
import Stats from './pages/Stats/Stats.page';
import DqOnlyInfo from './pages/Auth/UserInfo/DqOnlyInfo.page';
import Demographic from './pages/Auth/Demographic/Demographic.page';
import Login from "./pages/Auth/Login/Login.page";
import Logout from "./pages/Auth/Logout/Logout.page";
import InitPasswordReset from "./pages/Auth/InitPasswordReset/InitPasswordReset.page";
import PasswordReset from "./pages/Auth/PasswordReset/PasswordReset.page";
import Register from "./pages/Auth/Register/Register.page";
import CompleteRegisteration from "./pages/Auth/CompleteRegisteration/CompleteRegisteration.page";
import PersistGuest from "./pages/Auth/PersistGuest/PersistGuest.page";
import QuizTime from "./pages/QuizTime/QuizTime.page";
import QuizResult from "./pages/QuizResult/QuizResult.page";
import QuizInfo from "./pages/QuizInfoPage/QuizInfo.page";

import Settings from './pages/Settings/Settings.page';
import TermsOfUse from './pages/User/TermsOfUse/TermsOfUse.page';
import PrivacyPolicy from './pages/User/PrivacyPolicy/PrivacyPolicy.page';
import CookiePolicy from './pages/User/CookiePolicy/CookiePolicy.page';
import PostQuizGuestLanding from './pages/User/PostQuizGuestLanding/PostQuizGuestLanding.page';

import DQIndexHome from './pages/DQIndex/Home.page';
import DQIndexAssessment from './pages/DQIndex/Assessment.page';

import NotFound from "./pages/404/NotFound.page";

import LockScreen from './pages/LockScreen/LockScreen.page';

import RouteWithLayout from "./components/Wrapper/RouteWithLayout.wrapper";
import PrivateRouteWithLayout from "./components/Wrapper/PrivateRouteWithLayout.wrapper";
import PreAuthRouteWithLayout from "./components/Wrapper/PreAuthRouteWithLayout.wrapper";

import Minimal from "./layouts/Minimal/Minimal.layout";
import Main from "./layouts/Main/Main.layout";

import AppInfo from './pages/User/AppInfo/AppInfo.page';
import CollabPage from './pages/User/Collab/Collab.page';
import App from "./App";



const Routes = () => {
    return (
        <Switch>
            <RouteWithLayout
                component={DQIndexHome}
                exact
                layout={Minimal}
                path="/:lc/"
            />
            <Route exact path={'/lock'}>
                <LockScreen />
            </Route>
            <Route exact path={'/:lc/complete_registration'}>
                <CompleteRegisteration />
            </Route>
            <PreAuthRouteWithLayout
                component={InitPasswordReset}
                exact
                layout={Minimal}
                path="/:lc/recover"
            />
            <PreAuthRouteWithLayout
                component={PasswordReset}
                exact
                layout={Minimal}
                path="/:lc/reset_pass"
            />
            <PreAuthRouteWithLayout
                component={Login}
                exact
                layout={Minimal}
                no_redirect={true}
                path="/:lc/login"
            />
            <PreAuthRouteWithLayout
                component={Register}
                exact
                layout={Minimal}
                path="/:lc/register"
            />

            <PrivateRouteWithLayout
                component={Logout}
                exact
                layout={Minimal}
                path="/:lc/logout"
            />
            <PrivateRouteWithLayout
                component={Demographic}
                exact
                layout={Minimal}
                path="/:lc/demographic"
            />
            <PrivateRouteWithLayout
                component={Home}
                exact
                layout={Main}
                path="/:lc/home"
            />
            <PrivateRouteWithLayout
                component={PersistGuest}
                exact
                layout={Main}
                path="/:lc/persist_guest"
            />

            <PrivateRouteWithLayout
                component={Stats}
                exact
                layout={Main}
                path="/:lc/stats"
            />
            <PreAuthRouteWithLayout
                component={DqOnlyInfo}
                exact
                layout={Minimal}
                path="/:lc/info_dq"
            />
            <PreAuthRouteWithLayout
                component={DqOnlyInfo}
                exact
                layout={Minimal}
                path="/:lc/assessment/DCT"
            />
            <PrivateRouteWithLayout
                component={PostQuizGuestLanding}
                exact
                layout={Minimal}
                path="/:lc/post_quiz_landing"
            />


            <PrivateRouteWithLayout
                component={QuizTime}
                exact
                layout={Main}
                path="/:lc/take/quiz/:mode/:identifier"
            />
            <PrivateRouteWithLayout
                component={QuizResult}
                exact
                layout={Main}
                path="/:lc/result/quiz/:identifier"
            />

            <PrivateRouteWithLayout
                component={Settings}
                exact
                layout={Main}
                path="/:lc/settings"
            />


            <RouteWithLayout
                component={TermsOfUse}
                exact
                layout={Minimal}
                path="/:lc/terms_of_use"
            />
            <RouteWithLayout
                component={PrivacyPolicy}
                exact
                layout={Minimal}
                path="/:lc/privacy_policy"
            />
            <RouteWithLayout
                component={CookiePolicy}
                exact
                layout={Minimal}
                path="/:lc/cookies"
            />
            <RouteWithLayout
                component={AppInfo}
                exact
                layout={Minimal}
                path="/:lc/app_info"
            />
                <RouteWithLayout
                    component={CollabPage}
                    exact
                    layout={Minimal}
                    path="/:lc/collab"
                />

            <RouteWithLayout
                component={DQIndexHome}
                exact
                layout={Minimal}
                path="/:lc/dqindex"
            />
            <RouteWithLayout
                component={DQIndexAssessment}
                exact
                layout={Minimal}
                path="/:lc/assessment"
            />


            <RouteWithLayout
                component={NotFound}
                exact={false}
                layout={Minimal}
                path="/"
            />
        </Switch>
    );
};

export default Routes;
