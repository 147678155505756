import React, {useEffect, useState} from 'react';
import Button, {btnSize} from "../../../components/UI/Button/Button.ui";
import {loginUser, loginUserViaToken, logoutUser} from "../../../services/auth.service";
import {useAuth} from "../../../context/auth";
import {useGlobalState} from "../../../context/global_state";
import { withRouter } from "react-router";
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {loadNewGuestUser} from "../auth_common.lib";

import dqIcon from '../../../assets/icons/dq-icons/dq-logo.png';
import {LOCAL_STORAGE_KEYS, LOCALES_ENABLED} from "../../../utils/constants";
import {useLocation} from "react-router-dom";
import {GET_APP_LOCALIZE_TEXT} from "../../../utils/dq_lc_service/lc_service";
import Helmet from "react-helmet";

const Login = (props) => {

    const { history, location } = props;

    const page_location = useLocation();
    const pageLc = page_location.pathname.split('/')[1];

    const [loading, setLoading] = useState(false);

    const [formEmail, setFormEmail] = useState('');
    const [formPassword, setFormPassword] = useState('');

    const { setAuthToken, setAuthUser, authToken, authUser } = useAuth();
    const { setToastShow, setToastMessage, setProfile } = useGlobalState();

    useEffect( () => {
        const params = new URLSearchParams(location.search);
        const url_token = params.get('access_token');
        if (url_token) {
            // try to login via provided token in url
            loginViaToken(url_token).then(r=>console.log('successfully logged in to application using token'))
        } else if (authToken && authUser) {
            history.push('/home')
        }
    }, [] );

    const loginViaToken = async (token) => {
        try {
            setLoading(true);
            let profile;
            const result = await loginUserViaToken(token);

            if (result.data && result.data.user && result.data.org_code) {
                // logout old user first
                if (authToken && authUser) {
                    await LogoutActionWithoutRedirect();
                }

                // set the data of login for new user
                await setAuthToken(result.data.auth_token);
                await setAuthUser(result.data.user);
                localStorage.setItem(LOCAL_STORAGE_KEYS.ACTIVE_ORG_CODE, result.data.org_code);
                localStorage.setItem(LOCAL_STORAGE_KEYS.FORCE_TO_SET_PASSWORD, true);
                localStorage.setItem(LOCAL_STORAGE_KEYS.TOKEN_FOR_SET_PASSWORD, token);

                profile = result.data.profile
                await setProfile(profile);

                history.push('/complete_registration')
            }
            setLoading(false);
        } catch (e) {
            setToastShow(true);
            setToastMessage(e.message);
            setLoading(false);
        }
    }

    const LogoutActionWithoutRedirect = async () => {

        try {
            await logoutUser();
            setAuthToken(null);
            setAuthUser(null);
            setProfile(null);
            localStorage.clear();

            const locale = location.pathname.split('/')[1];
            if (LOCALES_ENABLED.includes(locale)) {
                localStorage.setItem(LOCAL_STORAGE_KEYS.ACTIVE_LOCALE, locale);
            }
        } catch (e) {
            setToastShow(true);
            setToastMessage(e.message);
        }
    }

    const loginAction = async () => {

        try {
            setLoading(true);
            let profile;
            const remember_me = true;
            const result = await loginUser(formEmail, formPassword, remember_me);

            // if (result.data && result.data.redirect_to_dq_portal && result.data.access_token) {
            //     window.location.assign(`${process.env.REACT_APP_DQ_PRO_LOGIN_URL}?token=${result.data.access_token}`);
            // } else {
            //     setToastShow(true);
            //     setToastMessage('You does not have permission to access DQ PRO!');
            // }
            if (result.data && result.data.redirect_to_dq_portal && result.data.access_token) {
                setToastShow(true);
                setToastMessage('You does not have permission to access DQ Assessments!');
            } else if (result.data && result.data.user && result.data.org_code) {
                await setAuthToken(result.data.auth_token);
                await setAuthUser(result.data.user);
                localStorage.setItem(LOCAL_STORAGE_KEYS.ACTIVE_ORG_CODE, result.data.org_code);

                profile = result.data.profile
                await setProfile(profile);

                if (!profile) {
                    return history.push('/home')
                }

                if (profile.is_demographic_updated) {
                    history.push('/home')
                } else {
                    history.push('/demographic')
                }
            }

            setLoading(false);
        } catch (e) {
            setToastShow(true);
            setToastMessage(e.message);
            setLoading(false);
        }

    }

    const registerLink = () => {
        console.log('register Link Clicked');
        history.push('/register', true);
    }

    const recoverLink = () => {
        console.log('recover Link Clicked');
        history.push('/recover', true);
    }

    const registerAsCompanyLink = () => {
        console.log('register-company Link Clicked');
        window.location.assign(process.env.REACT_APP_REGISTER_AS_COMPANY_LINK)
    }

    const registerAsSchoolLink = () => {
        console.log('register-company Link Clicked');
        window.location.assign(process.env.REACT_APP_REGISTER_AS_SCHOOL_LINK)
    }

    const termsOfUseLink = () => {
        history.push('/terms_of_use', true);
    }

    const privacyPolicyLink = () => {
        history.push('/privacy_policy', true);
    }

    const onChangeEmail = (e) => {
        setFormEmail(e.target.value);
    }

    const onChangePassword = (e) => {
        setFormPassword(e.target.value);
    }

    return (
        <div className={'login-container'}>
            <Helmet title="DQ Assessment" />
            <LoaderWithBackDrop
                loading={loading}
            />

            <div className="dq-logo-image"><img src={dqIcon} alt='DQ'/></div>
            <div className="dq-login-content">
                <form onSubmit={e => e.preventDefault()}>
                    <div>
                        <input
                            className={'dq-login-txt'}
                            type='text'
                            name={'email'}
                            id={'email'}
                            value={formEmail}
                            placeholder={GET_APP_LOCALIZE_TEXT(pageLc, 'uo__username')}
                            onChange={onChangeEmail}
                        />
                    </div>
                    <div>
                        <input
                            className={'dq-login-txt'}
                            type='password'
                            name={'password'}
                            id={'password'}
                            value={formPassword}
                            placeholder={GET_APP_LOCALIZE_TEXT(pageLc, 'persist_guest__password')}
                            onChange={onChangePassword}
                        />
                    </div>
                </form>
            </div>
            
            <div className={'dq-options-submit'}>
                <button onClick={loginAction}>{GET_APP_LOCALIZE_TEXT(pageLc, 'persist_guest__login')}</button>
            </div>

            <div className={'forgot-password'}>
                <a onClick={recoverLink}>{GET_APP_LOCALIZE_TEXT(pageLc, 'persist_guest__forgot_password')}</a>
            </div>

            <div className={'dq-audience-login'}>
                <p>{GET_APP_LOCALIZE_TEXT(pageLc, 'persist_guest__not_have_an_account')}</p>
                <a onClick={registerLink}>{GET_APP_LOCALIZE_TEXT(pageLc, 'uo__register_as_individual')}</a>
                {/* <div style={{marginTop: '8px'}}>
                    <a onClick={registerAsCompanyLink}>{GET_APP_LOCALIZE_TEXT(pageLc, 'uo__register_as_company')}</a>
                </div>
                <div style={{marginTop: '8px'}}>
                    <a onClick={registerAsSchoolLink}>{GET_APP_LOCALIZE_TEXT(pageLc, 'uo__register_as_school')}</a>
                </div> */}
            </div>
            
            <div className={'dq-guest-footer'}>
                <a className={'dq-footer-link'} href={'/privacy_policy'} target={'_blank'}>
                    {GET_APP_LOCALIZE_TEXT(pageLc, 'persist_guest__privacy_policy')}
                </a>
                <a className={'dq-footer-link'} href={'/terms_of_use'} target={'_blank'}>
                    {GET_APP_LOCALIZE_TEXT(pageLc, 'persist_guest__terms_of_use')}
                </a>
                <div className={'dq-copyright'}>&copy; 2022 DQ LAB. All Rights Reserved</div>
            </div>

            {/*<br />*/}
            {/*<br />*/}
            {/*<br />*/}
            {/*<a*/}
            {/*    className={'login-link'}*/}
            {/*    onClick={guestLogin}*/}
            {/*>Continue as Guest</a>*/}
        </div>
    );

}

export default withRouter(Login);