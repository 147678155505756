
const lc_en = {
    cookies__title: `DQ LAB PTE. LTD. Cookie Policy`,
    cookies__effective_date: `Effective Date: February 14, 2022`,
    cookies__policy_desc: `This cookie policy describes how DQ LAB PTE. LTD. <strong>(“DQ LAB”, “we”, “our” or “us”)</strong> uses cookies to collect and process information about you through <a href="/" target="_blank">DQ Index</a> and its related sites (the <strong>"Online Services"</strong>) and all other websites and applications. You can read more about the types of cookies we use, why we use them and how you can exercise your cookies choices below. This policy explains how Google Analytics and authorized third parties use cookies on our sites and off our website. It also provides details on how you can exercise your right to a greater degree of control over our cookies. Please recognize that your experience with our websites may be degraded and may stop working all together if you prevent the use of all cookies.`,
    cookies__what_is_it: `What Are Cookies?`,
    cookies__para_1: `We use the term 'cookies' to refer to all technologies which store and access information on the device that you use to access the Online Services, such as your computer, tablet or mobile phone. For example, we use http cookies, which are small data files (typically made up of numbers and letters) that are downloaded when you access our Online Services and allow us to recognize your device. We also use technology like pixel tags and software kits embedded in our Online Services.`,
    cookies__para_2: `DQ LAB PTE. LTD. and third parties will also use cookies that allow us to analyze trends, administer the Online Services, understand how visitors are using our Online Services, collect demographic information about our site visitors as a group, and optimize our marketing efforts to provide ads that are relevant to your interests. We may report on this information at the aggregate or individual level.`,
    cookies__different_example: `There are different types of Cookies, for example:`,
    cookies__desc_example_1: `Cookies served directly by DQ LAB PTE. LTD. ('first party cookies') and cookies served on our behalf, for example by advertisers and data analytics companies ('third party cookies')`,
    cookies__desc_example_2: `Cookies which endure for different periods of time, including those that only last as long as your browser is open (referred to as 'session cookies'). These are deleted automatically once you close your browser. Other cookies are 'permanent cookies', meaning they survive after your browser is closed. For example, they recognize your device when you open your browser and browse the internet again.`,
    cookies__which_to_use: `Which Cookies Do We Use and Why Do We Use Them?`,
    cookies__which_to_use_explain: `The following table sets out the different categories of cookies that the Online Services use and why we use them.`,
    cookies__type_of_cookie: `Type of Cookie`,
    cookies__source: `Source`,
    cookies__what_these_do: `What these cookies do`,
    cookies__how_to_exercise_choice: `How to exercise our Cookie choice`,
    cookies__essential_web_cookies: `Essential website Cookies`,
    cookies__dq_index_dc_assessment: `DQ Index Digital Citizenship Assessment`,
    cookies__para_3: `These Cookies are necessary to provide you some of the features of the Online Services, such as logging into user accounts and implementing security features to protect our Online Services. These types of cookies allow you to log in with your registered account and use the website as a registered user.`,
    cookies__para_4: `You can control and delete these cookies through your browser settings however if you choose to do so, then you will not be able to use the full functionality of the Online Services.`,
    cookies__analytics_cookies: `Analytics Cookies`,
    cookies__google_analytics: `Google Analytics`,
    cookies__para_5_title: `Type and purpose of the processing`,
    cookies__para_5: `This website uses Google Analytics, a web analytics service of Google LLC, 1600 Amphitheater Parkway, Mountain View, CA 94043 USA (hereinafter: ‘Google’). Google Analytics uses ‘cookies’, i.e. text files that are stored on your computer and allow an analysis of your use of the website. The information generated by the cookie about your use of this website is typically transmitted to Google’s servers in the U.S. and stored there. However, due to the activation of IP anonymization on our website, your IP address will be truncated by Google before a transfer to the U.S. takes place. Only in exceptional cases will the full IP address be sent to a Google server in the U.S. and truncated there. On our behalf, Google will use this information to evaluate your usage of the website, summarise reports on website activities and provide other services related to website and Internet usage to us. The IP address transferred from your browser as part of Google Analytics will not be combined with other data from Google.`,
    cookies__para_5_part: `The data is processed for the purpose of website-use analysis and providing summary reports on the activities on our website.`,
    cookies__legal_basis: `Legal basis`,
    cookies__data_processed_on_consent_art_6_1a: `The data is processed on the basis of user consent (Art. 6(1)(a) GDPR).`,
    cookies__recipients: `Recipients`,
    cookies__para_6: `The data is shared with Google as the processor. To enable this, we have entered into a data processing agreement with Google.`,
    cookies__retention_period: `Retention period`,
    cookies__para_7: `The data is deleted as soon as it is no longer required for recording-keeping purposes or as soon as you have withdrawn your consent.`,
    cookies__third_country_transfer: `Third country transfers`,
    cookies__para_8: `Google processes your data in the United States of America. We have entered into Standard Contractual Clauses with Google. Please let us know should you want to obtain a copy thereof.`,
    cookies__required_provision: `Mandatory or required provision`,
    cookies__para_9: `The provision of your personal data is voluntary, based solely on your consent. If you do not provide your information, it could lead to limited functionality of the website.`,
    cookies__profiling: `Profiling`,
    cookies__para_10: `Google Analytics helps to evaluate the browsing behaviour of website visitors and to analyse their interests. We create a pseudonymous user profile for the purpose of this analysis.`,
    cookies__para_11: `We use analytics cookies to compile usage data regarding our Online Services. We use this information to make high-level decisions about changes to our Online Services. The data collected in these cookies allows us to:`,
    cookies__understand_how_many_active_users: `Understand how many active users we have on DQIndex.org platform within certain time period.`,
    cookies__para_12: `Understand the average sessions (A session is a group of user interactions with our website that take place within a given time frame.) and sessions duration (average length of a session) of all users with certain time period.`,
    cookies__para_13: `Have an overall picture of the total number of page viewed and the average number of a page viewed during a session.`,
    cookies__para_14: `Figure out the overall bounce rate (The percentage of single-page sessions in which there was no interaction with the page) within certain time period.`,
    cookies__help_use_uncover: `Help us uncover our top pages and identify the worst performing pages.`,
    cookies__para_15: `Determine where our users &amp; website visitors are located, and what languages they use.`,
    cookies__understand_how_many_active_users_realtime: `Understand how many users are on our site right now (Real-time users functions).`,
    cookies__find_out_devices_text: `Find out what devices our users &amp; website visitors use.`,
    cookies__track_how_users_navigate: `Track how users &amp; website visitors navigate our website and program.`,
    cookies__track_users_retention: `Track users’ retention rate within certain time period.`,
    cookies__para_16: `You can prevent the storage of cookies using the settings in your browser software. However, if you do this, you may not be able to use all of the functions of this website in their entirety. You can also prevent the transmission of the data collected via a cookie and the data related to your use of the website (including your IP address) to Google, and the processing of this data by Google, by downloading and installing the browser plug-in available at the following link: <br><a href="https://tools.google.com/dlpage/gaoptout?hl=en-GB" target="_blank">browser add-on to deactivate Google Analytics</a>.`,
    cookies__para_16_2: `In addition to, or as an alternative to the browser add-on, you can prevent tracking by Google Analytics on our web pages by clicking <a href="https://tools.google.com/dlpage/gaoptout" target="_blank">this link</a>. This will install an opt-out cookie on your device and prevent future data collection by Google Analytics for this website and browser for as long as the cookie remains installed in your browser.`,
    cookies__info_collect_via_cookie: `Information We Collect Via Cookies`,
    cookies__para_17: `Generally speaking, most of the data we collect about you as an individual is provided through form submission. There are some cases where we obtain certain information from cookies. This data is not personally identifying. It includes things like:`,
    cookies__unique_id_tokens: `Unique identification tokens to enable live chat sessions across pages and other features`,
    cookies__info_about_current_session: `Information about your current browsing session`,
    cookies__how_restrict_cookies: `How Do You Restrict Cookies?`,
    cookies__para_18: `You have the right to choose whether or not to accept cookies. However, they are an important part of how our Online Services work, so you should be aware that if you choose to refuse or remove cookies, this could affect the availability and functionality of the Online Services. If you do not want the Online Services to use cookies, please follow the instruction in the table above and below.`,
    cookies__web_opt_out: `Website (Browser) Opt-out`,
    cookies__para_19: `Most browsers accept cookies by default. You may be able to change the settings to have your browser refuse certain cookies or notify you before accepting cookies. To do so, please follow the instructions provided by your browser which are usually located within the "Help", "Tools", or "Edit" facility. Some third parties also provide the ability to refuse their cookies directly by clicking on an opt- out link, and we have tried to indicate where this is possible in the table above. If you wish to opt-out of third party marketing cookies on our site and on the rest of the web, you can opt-out by <a href="http://preferences-mgr.truste.com/" target="_blank">clicking here</a>, or, if you are based in the European Union, <a href="http://www.youronlinechoices.eu/" target="_blank">click here</a>.`,
    cookies__mobile_opt_out: `Mobile App Opt-Out`,
    cookies__para_20: `To limit interest-based advertising on your mobile device, you can review and adjust the settings provided by your device manufacturer, such as "<a href="https://support.apple.com/en-lamr/HT202074" target="_blank">Limit Ad Tracking</a>" for iOS or "<a href="https://policies.google.com/technologies/ads" target="_blank">Opt-out of interest-based ads</a>" for Android. For further information about cookies, including how to see what cookies have been set on your device and how to manage and delete them, please visit <a href="http://www.allaboutcookies.org/" target="_blank">www.allaboutcookies.org</a> and <a href="http://www.youronlinechoices.eu/" target="_blank">www.youronlinechoices.eu</a>`,
    cookies__contact_us: `Contact Us`,
    cookies__closing_statement: `If you have any questions about our use of cookies, please contact us at: <a href="mailto:contact@dqforall.com">contact@dqforall.com</a>`,
}


const lc_ko = {
    cookies__title: `DQ LAB PTE. 쿠키 정책`,
    cookies__effective_date: `유효일: 2022년 2월 14일`,
    cookies__policy_desc: `이 쿠키 정책은 DQ LAB PTE. LTD. <strong>("DQ LAB", “당사”)</strong>이 <a href="https://www.dqindex.org/" target="_blank">DQ 점수</a>와 관련 사이트 (<strong>"온라인 서비스"</strong>) 및 기타 모든 웹사이트 및 응용 프로그램을 통해 사용자에 대한 정보를 수집하고 처리하기 위해 쿠키를 사용하는 방법에 대해 설명합니다. 아래에서는 사용하는 쿠키의 종류, 사용하는 이유 및 쿠키 선택 사항을 실천하는 방법에 대해 자세히 알아볼 수 있습니다. 이 정책은 Google Analytics 및 승인을 받은 제3자가 당사 사이트 및 사이트 외의 웹사이트에서 쿠키를 사용하는 방법을 설명합니다. 또한 당사의 쿠키를 보다 효과적으로 제어할 수 있는 방법에 대해서도 자세히 설명합니다. 모든 쿠키를 사용할 수 없도록 할 경우 당사 웹사이트의 사용 환경이 저하되거나 전체적으로 작동하지 않을 수 있음을 인지해주세요.`,
    cookies__what_is_it: `쿠키란 무엇인가?`,
    cookies__para_1: `쿠키'라는 용어는 컴퓨터, 태블릿 또는 휴대전화와 같이 온라인 서비스에 액세스하는 데 사용하는 장치에 정보를 저장하고 액세스하는 모든 기술을 가리킵니다. 예를 들어 온라인 서비스에 액세스하여 장치를 인식할 수 있도록 하는 작은 데이터 파일(일반적으로 숫자와 문자로 구성됨)인 http 쿠키를 사용합니다. 또, 온라인 서비스에 내장된 픽셀 태그나 소프트웨어 키트 등의 기술을 사용하고 있습니다.`,
    cookies__para_2: `또한 DQ LAB PTE. LTD 및 제3자는 NAT이 트렌드 분석, 온라인 서비스 관리, 방문자의 온라인 서비스 이용 상황 파악, 단체로 사이트 방문자에 대한 인구 통계 정보 수집, 고객의 관심사에 맞는 광고 제공을 위한 마케팅 노력을 최적화할 수 있도록 쿠키를 사용합니다. 이 정보는 집계 또는 개인 차원에서 보고할 수 있습니다.`,
    cookies__different_example: `쿠키에는 다음과 같이 여러 가지 유형이 있습니다.`,
    cookies__desc_example_1: `DQ LAB PTE. LTD에서 직접 제공하는 쿠키('first party cookies') 및 광고 회사나 데이터 분석 회사를 대신하여 제공하는 쿠키(예: 광고주 및 데이터 분석 회사('third party cookies'))`,
    cookies__desc_example_2: `브라우저가 열려 있는 동안에만 지속되는 쿠키를 포함하여 다양한 기간 동안 지속되는 쿠키('세션 쿠키'라고 함)는 브라우저를 닫으면 자동으로 삭제됩니다. 다른 쿠키는 '영구 쿠키'로 브라우저가 닫힌 후에도 유지됩니다. 예를 들어, 이러한 쿠키는 브라우저를 열고 인터넷을 다시 탐색할 때 귀하의 기기를 인식합니다.`,
    cookies__which_to_use: `당사는 어떤 쿠키를 사용하고 왜 그 쿠키를 사용하는가?`,
    cookies__which_to_use_explain: `다음 표에서는 온라인 서비스에서 사용하는 쿠키의 다양한 카테고리와 사용 이유를 보여 줍니다.`,
    cookies__type_of_cookie: `쿠키 유형`,
    cookies__source: `소스`,
    cookies__what_these_do: `쿠키의 기능`,
    cookies__how_to_exercise_choice: `쿠키를 선택하는 법`,
    cookies__essential_web_cookies: `웹사이트 필수 쿠키`,
    cookies__dq_index_dc_assessment: `DQ 점수 디지털 시민의식 평가`,
    cookies__para_3: `이러한 쿠키는 사용자 계정 로그인 및 온라인 서비스 보호를 위한 보안 기능 구현과 같은 온라인 서비스의 일부 기능을 제공하는 데 필요합니다. 이러한 유형의 쿠키를 사용하면 등록된 계정으로 로그인하고 웹사이트를 등록된 사용자로서 사용할 수 있습니다.`,
    cookies__para_4: `브라우저 설정을 통해 이러한 쿠키를 제어하고 삭제할 수 있지만 그렇게 선택한 경우, 온라인 서비스의 전체 기능을 사용할 수는 없습니다.`,
    cookies__analytics_cookies: `분석 쿠키`,
    cookies__google_analytics: `Google Analytics`,
    cookies__para_5_title: `처리의 종류와 목적`,
    cookies__para_5: `이 웹사이트는 Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043 USA(이하 'Google')의 웹 분석 서비스인 Google Analytics를 사용합니다. Google Analytics는 '쿠키', 즉 귀하의 컴퓨터에 저장되고 귀하의 웹사이트 사용에 대한 분석을 허용하는 텍스트 파일을 사용합니다. 귀하의 이 웹사이트 사용에 대해 쿠키에 의해 생성된 정보는 일반적으로 미국에 있는 Google 서버로 전송되어 그곳에 저장됩니다. 그러나 당사 웹사이트의 IP 익명화 활성화로 인해 Google은 귀하의 IP 주소를 미국으로 이전하기 전에 일부를 잘라냅니다. 예외적인 경우에만 전체 IP 주소가 미국의 Google 서버로 전송되어 진 후 잘려집니다. Google은 우리를 대신하여 이 정보를 사용하여 웹사이트 사용을 평가하고 웹사이트 활동에 대한 보고서를 요약하며 웹사이트 및 인터넷 사용과 관련된 기타 서비스를 제공합니다. Google Analytics의 일부로 귀하의 브라우저에서 전송된 IP 주소는 Google의 다른 데이터와 결합되지 않습니다.`,
    cookies__para_5_part: `웹사이트 이용 분석 및 웹사이트 활동 요약 보고서 제공을 목적으로 데이터를 처리합니다.`,
    cookies__legal_basis: `법적 근거`,
    cookies__data_processed_on_consent_art_6_1a: `데이터는 사용자 동의에 기초하여 처리됩니다(Art. 6(a) GDPR).`,
    cookies__recipients: `수신자`,
    cookies__para_6: `데이터는 프로세서를 통해 구글과 공유됩니다. 이를 위해 Google과 데이터 처리 계약을 체결했습니다.`,
    cookies__retention_period: `쿠키 보존 기간`,
    cookies__para_7: `데이터는 기록 보관 목적으로 더 이상 필요하지 않거나 동의를 철회하는 즉시 삭제됩니다.`,
    cookies__third_country_transfer: `제3국 양도`,
    cookies__para_8: `Google은 미국에서 데이터를 처리합니다. Google과 표준 계약 조항을 체결했습니다. 그 사본을 얻으려면 당사에 알려주십시오.`,
    cookies__required_provision: `필수 또는 필요 조항`,
    cookies__para_9: `귀하의 개인 데이터 제공은 전적으로 귀하의 동의에 따라 자발적입니다. 정보를 제공하지 않으면 웹사이트의 기능이 제한될 수 있습니다.`,
    cookies__profiling: `프로파일링`,
    cookies__para_10: `Google Analytics는 웹사이트 방문자의 탐색 행동을 평가하고 그들의 관심사를 분석하는 데 도움이 됩니다. 이 분석을 위해 가명 사용자 프로필을 생성합니다.`,
    cookies__para_11: `당사는 분석 쿠키를 사용하여 온라인 서비스와 관련된 사용 데이터를 수집합니다. 당사는 이 정보를 사용하여 온라인 서비스 변경에 대한 높은 수준의 결정을 내립니다. 이 쿠키에서 수집된 데이터를 통해 당사는 다음을 수행할 수 있습니다.`,
    cookies__understand_how_many_active_users: `특정 기간 동안 DQIndex.org 플랫폼에 얼마나 많은 활성 사용자가 있는지 파악합니다.`,
    cookies__para_12: `특정 기간 동안의 모든 사용자의 평균 세션(세션은 주어진 시간 프레임 내에서 발생하는 당사 웹사이트와의 사용자 상호 작용 그룹입니다.) 및 세션 지속 시간(세션의 평균 길이)을 이해합니다.`,
    cookies__para_13: `조회된 총 페이지 수와 세션 중 조회된 평균 페이지 수에 대한 전체적으로 파악합니다.`,
    cookies__para_14: `특정 기간 내 전체 이탈률(페이지와 상호 작용이 없는 단일 페이지 세션의 비율)을 파악합니다.`,
    cookies__help_use_uncover: `당사가 실적이 좋은 페이지를 찾아내고 실적이 가장 낮은 페이지를 식별하도록 돕습니다.`,
    cookies__para_15: `사용자 및 웹사이트 방문자의 위치와 그들이 사용하는 언어를 결정합니다.`,
    cookies__understand_how_many_active_users_realtime: `현재 사이트에 얼마나 많은 사용자가 있는지 파악합니다(실시간 사용자 기능).`,
    cookies__find_out_devices_text: `사용자 및 웹 사이트 방문자가 사용하는 장치를 찾습니다.`,
    cookies__track_how_users_navigate: `사용자 및 웹사이트 방문자가 당사 웹사이트 및 프로그램을 탐색하는 방법을 추적합니다.`,
    cookies__track_users_retention: `특정 기간 내 사용자 유지율을 추적합니다.`,
    cookies__para_16: `브라우저 소프트웨어의 설정을 사용하여 쿠키의 저장을 방지할 수 있습니다. 그러나 이렇게 하면 이 웹사이트의 모든 기능을 완전히 사용하지는 못할 수 있습니다. 또한 브라우저 플러그인을 다운로드 및 설치하여 쿠키를 통해 수집된 데이터 및 웹사이트 사용과 관련된 데이터(귀하의 IP 주소 포함)를 Google로 전송하고 Google에서 이 데이터를 처리하는 것을 방지할 수 있습니다. 다음 링크에서 사용 가능합니다: <br><a href=" https://tools.google.com/dlpage/gaoptout?hl=en-GB " target="_blank"> Google Analytics를 비활성화하는 브라우저 추가 기능 </a>.`,
    cookies__para_16_2: `<a href="https://tools.google.com/dlpage/gaoptout" target="_blank">이 링크</a>를 클릭하여 브라우저 추가 기능에 대한 대안으로 당사 웹 페이지에서 Google Analytics의 추적을 방지할 수 있습니다. 이는 귀하의 장치에 옵트아웃 쿠키를 설치하고 귀하의 브라우저에 쿠키가 설치된 상태를 유지하는 이 웹사이트 및 브라우저에 대한 Google Analytics의 향후 데이터 수집을 방지합니다.`,
    cookies__info_collect_via_cookie: `쿠키를 통해 수집하는 정보`,
    cookies__para_17: `일반적으로 개인으로서 귀하에 대해 수집하는 대부분의 데이터는 양식 제출을 통해 제공됩니다. 쿠키에서 특정 정보를 얻는 몇몇 경우가 있습니다. 이 데이터는 개인을 식별하지 않습니다. 여기에는 다음과 같은 것들이 포함됩니다.`,
    cookies__unique_id_tokens: `페이지 및 기타 기능 전반에 걸쳐 실시간 채팅 세션을 가능하게 하는 고유 식별 토큰`,
    cookies__info_about_current_session: `현재 브라우징 세션에 대한 정보`,
    cookies__how_restrict_cookies: `쿠키를 어떻게 제한하는가?`,
    cookies__para_18: `귀하는 쿠키 허용 여부를 선택할 권리가 있습니다. 그러나 이는 당사 온라인 서비스 작동 방식의 중요한 부분이므로 쿠키를 거부하거나 제거하기로 선택하면 온라인 서비스의 가용성 및 기능에 영향을 미칠 수 있음을 인지해야 합니다. 온라인 서비스에서 쿠키를 사용하는 것을 원하지 않는 경우 위 및 아래 표의 지침을 따르십시오.`,
    cookies__web_opt_out: `웹사이트(브라우저) 옵트아웃`,
    cookies__para_19: `대부분의 브라우저는 기본적으로 쿠키를 허용합니다. 브라우저가 특정 쿠키를 거부하거나 쿠키를 수락하기 전에 알리도록 설정을 변경할 수 있습니다. 그렇게 하려면 일반적으로 "도움말", "도구" 또는 "편집" 기능에 있는 브라우저에서 제공하는 지침을 따르십시오. 일부 제3자는 옵트아웃 링크를 클릭하여 쿠키를 직접 거부할 수 있는 기능을 제공하며, 당사는 위 표에서 이것이 가능한 위치를 나타내기 위해 노력했습니다. 당사 사이트와 웹의 나머지 부분에서 제3자 마케팅 쿠키를 선택 해제하려면 <a href="http://preferences-mgr.truste.com/" target="에서 선택 해제할 수 있습니다. _blank">여기를 클릭</a>하거나 유럽 연합에 거주하는 경우 <a href="http://www.youronlinechoices.eu/" target="_blank">여기를 클릭</a>하세요.`,
    cookies__mobile_opt_out: `모바일 앱 옵트아웃`,
    cookies__para_20: `모바일 장치에서 관심 기반 광고를 제한하려면 iOS를 위한 "<a href="https://support.apple.com/en-lamr/HT202074" target="_blank"> 광고 추적 제한</a>"이나 안드로이드를 위한 "<a href="https://policies.google.com/technologies/ads" target="_blank">관심 기반 광고 선택 해제< /a>"와 같이 장치 제조업체에서 제공한 설정을 검토하고 조정할 수 있습니다. 장치에 설정된 쿠키를 확인하는 방법과 쿠키를 관리 및 삭제하는 방법을 포함하여 쿠키에 대한 자세한 내용을 보려면 <a href="http://www.allaboutcookies.org/" target="_blank"> www.allaboutcookies.org</a> 및 <a href="http://www.youronlinechoices.eu/" target="_blank">www.youronlinechoices.eu</a>에 방문하십시오.`,
    cookies__contact_us: `문의하기`,
    cookies__closing_statement: `쿠키 사용에 대해 질문이 있으시면 다음 주소 <a href="mailto:contact@dqforall.com">contact@dqforall.com</a>로 문의해 주십시오.`,
}


export const LC_VALUES_COOKIES = {
    'en': lc_en,
    'ko': lc_ko
}

const validate_keys_syncing = () => {
    const en_keys_count = Object.keys(lc_en).length;
    const ko_keys_count = Object.keys(lc_ko).length;
    if (en_keys_count === ko_keys_count) {
        console.log('lc_values::cookies:: keys are in sync');
    } else {
        console.log('lc_values::cookies:: FATAL ISSUE : keys not in sync');
    }
}

validate_keys_syncing();