import {DEFAULT_LOCALE, LOCALES_ENABLED} from "../constants";
import {LC_VALUES_APP} from "./lc_vales_app";
import {LC_VALUES_WEBSITE} from "./lc_values_website";
import {LC_VALUES_TERMS_OF_USE} from "./lc_values_terms_of_use";
import {LC_VALUES_COOKIES} from "./lc_values_cookies";
import {LC_VALUES_PRIVACY_POLICY} from "./lc_values_privacy_policy";

const __fetch_value = (locale, lc_key, value_map) => {
    let lc = ''
    if (!LOCALES_ENABLED.includes(locale)) {
        lc = DEFAULT_LOCALE;
    } else {
        lc = locale
    }

    const localize_text = value_map[lc][lc_key];
    if (localize_text && localize_text !== '') {
        return localize_text
    } else {
        return '**Missing Text**'
    }
}

export const GET_APP_LOCALIZE_TEXT = (locale, lc_key) => {
    return __fetch_value(locale, lc_key, LC_VALUES_APP);
}

export const GET_APP_LOCALIZE_DATE = (locale, date_obj) => {
    // TEMP function for localized date conversion
    // TODO: proper implementation
    if (locale === 'en') {
        const t_entry_parts = date_obj.toString().split(' ');
        return `${t_entry_parts[1]} ${t_entry_parts[2]}, ${t_entry_parts[4].split(':').slice(0,2).join(':')}`;
    } else if (locale === 'ko') {
        return `${date_obj.getFullYear()}년 ${date_obj.getMonth()+1}월, ${date_obj.getDate()}일`;
    }
}

export const GET_WEBSITE_LOCALIZE_TEXT = (locale, lc_key) => {
    return __fetch_value(locale, lc_key, LC_VALUES_WEBSITE);
}

export const GET_TERMS_OF_USE_LOCALIZE_TEXT = (locale, lc_key) => {
    return __fetch_value(locale, lc_key, LC_VALUES_TERMS_OF_USE);
}

export const GET_COOKIES_LOCALIZE_TEXT = (locale, lc_key) => {
    return __fetch_value(locale, lc_key, LC_VALUES_COOKIES);
}

export const GET_PRIVACY_POLICY_LOCALIZE_TEXT = (locale, lc_key) => {
    return __fetch_value(locale, lc_key, LC_VALUES_PRIVACY_POLICY);
}
