import React,{useState} from 'react';
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import Button,{btnSize} from "../../../components/UI/Button/Button.ui";
import {saveDemographics} from "../../../services/app_start.service";
import {useGlobalState} from "../../../context/global_state";
import headerImg from '../../../assets/icons/dq-icons/dq-logo.png';
import Footer from "../../../components/Footer/plugable.footer";
import {LOCAL_STORAGE_KEYS} from "../../../utils/constants";
import {useLocation} from "react-router-dom";
import {GET_APP_LOCALIZE_TEXT} from "../../../utils/dq_lc_service/lc_service";

// convert this to be a demographics screen

const ProfileConflict = (props) => {

    const { history } = props;

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const [loading, setLoading] = useState(false);

    const [formGender, setFormGender] = useState('');
    const [formYOB, setFormYOB] = useState('');
    const [formCountry, setFormCountry] = useState('');
    const [formPostCode, setFormPostCode] = useState('');

    const { setToastShow, setToastMessage, profile, setProfile } = useGlobalState();

    const submitDemographicFrom = async () => {

        // check if the form is filled completely
        if ([formGender, formYOB, formCountry, formPostCode].includes('')) {
            setToastShow(true);
            setToastMessage(GET_APP_LOCALIZE_TEXT(pageLc, 'dq_demographic__plz_fill_req_fields'));
            return;
        }

        let continue_home = false;
        try {
            const result = await saveDemographics(
                formGender,
                formYOB,
                formCountry,
                formPostCode
            );

            // start block_41 :: calculate the current user age form year of birth and store in local-storage
            const currentTime = new Date()
            const current_year = currentTime.getFullYear();
            const year_diff = current_year - formYOB;
            localStorage.setItem(LOCAL_STORAGE_KEYS.CURRENT_USER_AGE, year_diff);
            // end block_41

            if (result.data && result.data.profile) {
                setProfile({
                    ...profile,
                    ...result.data.profile
                });
            }

            continue_home = true;
        } catch (e) {
            setToastShow(true);
            setToastMessage(GET_APP_LOCALIZE_TEXT(pageLc, 'uo__save_failed_try_again'))
        }

        if (continue_home) {
            history.push('/home');
        }

    }

    const onChangeGender = (e) => {
        setFormGender(e.target.value);
    }

    const onChangeYOB = (e) => {
        setFormYOB(e.target.value);
    }

    const onChangeCounty = (e) => {
        setFormCountry(e.target.value);
    }

    const onChangePostCode = (e) => {
        setFormPostCode(e.target.value);
    }

    const genderOptions = [
        { value: '1', label: 'Male' },
        { value: '0', label: 'Female' }
    ];

    const birthYears = [2014, 2013, 2012, 2011, 2010, 2009, 2008, 2007, 2006, 2005, 2004,
        2003, 2002, 2001, 2000, 1999, 1998, 1997, 1996, 1995, 1994, 1993, 1992, 1991, 1990,
        1989, 1988, 1987, 1986, 1985, 1984, 1983, 1982, 1981, 1980, 1979, 1978, 1977, 1976, 1975, 1974, 1973,
        1972, 1971, 1970, 1969, 1968, 1967, 1966, 1965, 1964, 1963, 1962, 1961, 1960, 1959, 1958, 1957, 1956,
        1955, 1954, 1953, 1952, 1951, 1950, 1949, 1948, 1947, 1946, 1945, 1944, 1943, 1942, 1941, 1940, 1939,
        1938, 1937, 1936, 1935, 1934, 1933, 1932, 1931, 1930, 1929, 1928, 1927, 1926, 1925, 1924, 1923, 1922,
        1921, 1920];
    const countries = [
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__afghanistan'), "code": "AF"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__aland_islands'), "code": "AX"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__albania'), "code": "AL"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__algeria'), "code": "DZ"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__american_samoa'), "code": "AS"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__andorra'), "code": "AD"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__angola'), "code": "AO"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__anguilla'), "code": "AI"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__antarctica'), "code": "AQ"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__antigua_and_barbuda'), "code": "AG"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__argentina'), "code": "AR"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__armenia'), "code": "AM"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__aruba'), "code": "AW"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__australia'), "code": "AU"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__austria'), "code": "AT"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__azerbaijan'), "code": "AZ"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__bahamas'), "code": "BS"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__bahrain'), "code": "BH"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__bangladesh'), "code": "BD"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__barbados'), "code": "BB"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__belarus'), "code": "BY"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__belgium'), "code": "BE"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__belize'), "code": "BZ"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__benin'), "code": "BJ"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__bermuda'), "code": "BM"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__bhutan'), "code": "BT"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__bolivia'), "code": "BO"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__bosnia_and_herzegovina'), "code": "BA"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__botswana'), "code": "BW"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__bouvet_island'), "code": "BV"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__brazil'), "code": "BR"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__british_indian_ocean_territory'), "code": "IO"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__brunei_darussalam'), "code": "BN"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__bulgaria'), "code": "BG"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__burkina_faso'), "code": "BF"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__burundi'), "code": "BI"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__cambodia'), "code": "KH"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__cameroon'), "code": "CM"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__canada'), "code": "CA"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__cape_verde'), "code": "CV"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__cayman_islands'), "code": "KY"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__central_african_republic'), "code": "CF"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__chad'), "code": "TD"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__chile'), "code": "CL"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__china'), "code": "CN"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__christmas_island'), "code": "CX"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__cocos_keeling_islands'), "code": "CC"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__colombia'), "code": "CO"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__comoros'), "code": "KM"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__congo'), "code": "CG"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__congo_the_democratic_republic_of_the'), "code": "CD"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__cook_islands'), "code": "CK"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__costa_rica'), "code": "CR"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__cote_divoire'), "code": "CI"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__croatia'), "code": "HR"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__cuba'), "code": "CU"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__cyprus'), "code": "CY"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__czech_republic'), "code": "CZ"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__denmark'), "code": "DK"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__djibouti'), "code": "DJ"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__dominica'), "code": "DM"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__dominican_republic'), "code": "DO"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__ecuador'), "code": "EC"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__egypt'), "code": "EG"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__el_salvador'), "code": "SV"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__equatorial_guinea'), "code": "GQ"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__eritrea'), "code": "ER"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__estonia'), "code": "EE"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__ethiopia'), "code": "ET"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__falkland_islands_malvinas'), "code": "FK"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__faroe_islands'), "code": "FO"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__fiji'), "code": "FJ"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__finland'), "code": "FI"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__france'), "code": "FR"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__french_guiana'), "code": "GF"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__french_polynesia'), "code": "PF"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__french_southern_territories'), "code": "TF"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__gabon'), "code": "GA"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__gambia'), "code": "GM"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__georgia'), "code": "GE"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__germany'), "code": "DE"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__ghana'), "code": "GH"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__gibraltar'), "code": "GI"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__greece'), "code": "GR"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__greenland'), "code": "GL"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__grenada'), "code": "GD"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__guadeloupe'), "code": "GP"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__guam'), "code": "GU"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__guatemala'), "code": "GT"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__guernsey'), "code": "GG"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__guinea'), "code": "GN"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__guinea_bissau'), "code": "GW"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__guyana'), "code": "GY"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__haiti'), "code": "HT"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__heard_island_and_mcdonald_islands'), "code": "HM"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__holy_see_vatican_city_state'), "code": "VA"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__honduras'), "code": "HN"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__hong_kong'), "code": "HK"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__hungary'), "code": "HU"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__iceland'), "code": "IS"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__india'), "code": "IN"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__indonesia'), "code": "ID"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__iran_islamic_republic_of'), "code": "IR"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__iraq'), "code": "IQ"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__ireland'), "code": "IE"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__isle_of_man'), "code": "IM"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__israel'), "code": "IL"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__italy'), "code": "IT"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__jamaica'), "code": "JM"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__japan'), "code": "JP"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__jersey'), "code": "JE"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__jordan'), "code": "JO"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__kazakhstan'), "code": "KZ"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__kenya'), "code": "KE"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__kiribati'), "code": "KI"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__korea_democratic_peoples_republic_of'), "code": "KP"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__korea_republic_of'), "code": "KR"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__kuwait'), "code": "KW"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__kyrgyzstan'), "code": "KG"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__lao_peoples_democratic_republic'), "code": "LA"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__latvia'), "code": "LV"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__lebanon'), "code": "LB"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__lesotho'), "code": "LS"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__liberia'), "code": "LR"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__libyan_arab_jamahiriya'), "code": "LY"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__liechtenstein'), "code": "LI"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__lithuania'), "code": "LT"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__luxembourg'), "code": "LU"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__macao'), "code": "MO"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__macedonia_the_former_yugoslav_republic_of'), "code": "MK"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__madagascar'), "code": "MG"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__malawi'), "code": "MW"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__malaysia'), "code": "MY"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__maldives'), "code": "MV"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__mali'), "code": "ML"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__malta'), "code": "MT"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__marshall_islands'), "code": "MH"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__martinique'), "code": "MQ"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__mauritania'), "code": "MR"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__mauritius'), "code": "MU"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__mayotte'), "code": "YT"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__mexico'), "code": "MX"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__micronesia_federated_states_of'), "code": "FM"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__moldova_republic_of'), "code": "MD"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__monaco'), "code": "MC"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__mongolia'), "code": "MN"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__montserrat'), "code": "MS"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__morocco'), "code": "MA"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__mozambique'), "code": "MZ"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__myanmar'), "code": "MM"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__namibia'), "code": "NA"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__nauru'), "code": "NR"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__nepal'), "code": "NP"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__netherlands'), "code": "NL"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__netherlands_antilles'), "code": "AN"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__new_caledonia'), "code": "NC"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__new_zealand'), "code": "NZ"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__nicaragua'), "code": "NI"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__niger'), "code": "NE"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__nigeria'), "code": "NG"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__niue'), "code": "NU"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__norfolk_island'), "code": "NF"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__northern_mariana_islands'), "code": "MP"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__norway'), "code": "NO"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__oman'), "code": "OM"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__pakistan'), "code": "PK"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__palau'), "code": "PW"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__palestinian_territory_occupied'), "code": "PS"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__panama'), "code": "PA"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__papua_new_guinea'), "code": "PG"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__paraguay'), "code": "PY"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__peru'), "code": "PE"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__philippines'), "code": "PH"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__pitcairn'), "code": "PN"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__poland'), "code": "PL"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__portugal'), "code": "PT"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__puerto_rico'), "code": "PR"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__qatar'), "code": "QA"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__reunion'), "code": "RE"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__romania'), "code": "RO"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__russian_federation'), "code": "RU"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__rwanda'), "code": "RW"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__saint_helena'), "code": "SH"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__saint_kitts_and_nevis'), "code": "KN"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__saint_lucia'), "code": "LC"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__saint_pierre_and_miquelon'), "code": "PM"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__saint_vincent_and_the_grenadines'), "code": "VC"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__samoa'), "code": "WS"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__san_marino'), "code": "SM"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__sao_tome_and_principe'), "code": "ST"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__saudi_arabia'), "code": "SA"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__senegal'), "code": "SN"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__serbia_and_montenegro'), "code": "CS"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__seychelles'), "code": "SC"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__sierra_leone'), "code": "SL"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__singapore'), "code": "SG"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__slovakia'), "code": "SK"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__slovenia'), "code": "SI"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__solomon_islands'), "code": "SB"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__somalia'), "code": "SO"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__south_africa'), "code": "ZA"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__south_georgia_and_the_south_sandwich_islands'), "code": "GS"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__spain'), "code": "ES"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__sri_lanka'), "code": "LK"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__sudan'), "code": "SD"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__suriname'), "code": "SR"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__svalbard_and_jan_mayen'), "code": "SJ"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__swaziland'), "code": "SZ"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__sweden'), "code": "SE"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__switzerland'), "code": "CH"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__syrian_arab_republic'), "code": "SY"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__taiwan_province_of_china'), "code": "TW"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__tajikistan'), "code": "TJ"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__tanzania_united_republic_of'), "code": "TZ"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__thailand'), "code": "TH"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__timor_leste'), "code": "TL"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__togo'), "code": "TG"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__tokelau'), "code": "TK"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__tonga'), "code": "TO"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__trinidad_and_tobago'), "code": "TT"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__tunisia'), "code": "TN"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__turkey'), "code": "TR"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__turkmenistan'), "code": "TM"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__turks_and_caicos_islands'), "code": "TC"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__tuvalu'), "code": "TV"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__uganda'), "code": "UG"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__ukraine'), "code": "UA"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__united_arab_emirates'), "code": "AE"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__united_kingdom'), "code": "GB"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__united_states'), "code": "US"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__united_states_minor_outlying_islands'), "code": "UM"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__uruguay'), "code": "UY"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__uzbekistan'), "code": "UZ"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__vanuatu'), "code": "VU"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__venezuela'), "code": "VE"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__viet_nam'), "code": "VN"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__virgin_islands_british'), "code": "VG"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__virgin_islands_us'), "code": "VI"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__wallis_and_futuna'), "code": "WF"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__western_sahara'), "code": "EH"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__yemen'), "code": "YE"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__zambia'), "code": "ZM"},
        {"name": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__zimbabwe'), "code": "ZW"},
    ];

    return (
        <div className={'register-container dq-demographic'}>
            {/* <section>
                <header className={'header'}>
                    <div className={'toggle'}></div>
                    <img  src={headerImg} />
                </header>
            </section> */}

            <LoaderWithBackDrop
                loading={loading}
            />

            <div className="dq-logo-image"><img src={headerImg} alt='DQ'/></div>

            <form onSubmit={e => e.preventDefault()}>
                <div className='dq-register-row'>
                    <label>{GET_APP_LOCALIZE_TEXT(pageLc, 'dq_demographic__gender')}</label>
                    <div className={'dq-select'}>
                        <select className={'dq-register-txt'} name={'gender'} id={'gender'} value={formGender} onChange={onChangeGender}>
                            <option value=""></option>
                            <option value="male">{GET_APP_LOCALIZE_TEXT(pageLc, 'dq_demographic__male')}</option>
                            <option value="female">{GET_APP_LOCALIZE_TEXT(pageLc, 'dq_demographic__female')}</option>
                            <option value="other">{GET_APP_LOCALIZE_TEXT(pageLc, 'dq_demographic__other')}</option>
                        </select>
                    </div>
                </div>
                <div className='dq-register-row'>
                    <label>{GET_APP_LOCALIZE_TEXT(pageLc, 'dq_demographic__year_of_birth')}</label>
                    <div className={'dq-select'}>
                        <select className={'dq-register-txt'} name={'yob'} id={'yob'} value={formYOB} onChange={onChangeYOB}>
                            <option value=""></option>
                            {
                                birthYears.map( (year, index) => 
                                    <option value={year}>{year}</option>
                                )
                            }
                        </select>
                    </div>
                </div>
                <div className='dq-register-row'>
                    <label>{GET_APP_LOCALIZE_TEXT(pageLc, 'dq_demographic__country')}</label>
                    <div className={'dq-select'}>
                        <select className={'dq-register-txt'} name={'country'} id={'country'} value={formCountry} onChange={onChangeCounty}>
                            <option value=""></option>
                            {
                                countries.map( (ctry, index) => 
                                    <option value={ctry.code}>{ctry.name}</option>
                                )
                            }
                        </select>
                    </div>
                </div>
                <div className='dq-register-row'>
                    <label>{GET_APP_LOCALIZE_TEXT(pageLc, 'dq_demographic__postal_code')}</label>
                    <input className={'dq-register-txt'}
                        type='text'
                        name={'postal_code'}
                        id={'postal_code'}
                        value={formPostCode}
                        onChange={onChangePostCode}
                    />
                </div>
            </form>

            <div className={'dq-options-submit'}>
                <button onClick={submitDemographicFrom}>{GET_APP_LOCALIZE_TEXT(pageLc, 'dq_demographic__next')}</button>
            </div>

            {/*<br />*/}
            {/*<br />*/}
            {/*<br />*/}
            {/*<a*/}
            {/*    className={'login-link'}*/}
            {/*    onClick={guestLogin}*/}
            {/*>Continue as Guest</a>*/}

            <Footer />
        </div>
    );
};

export default ProfileConflict;
