import React, { useState } from 'react';
import headerImg from '../../assets/icons/dq-icons/dq-logo.png';

import { withRouter } from "react-router";

import SideBar from './SideBar/SideBar.comp'
import BackDrop from './BackDrop/BackDrop.comp'
import {useGlobalState} from "../../context/global_state";
import {getActiveThemeColor} from "../../utils/utils";


const Header = (props) => {

	const { history } = props;

	const { profile } = useGlobalState();

	const [drawerOpen, setDrawerOpen] = useState(false);

	const openDrawer = () => {
		setDrawerOpen(true);
		console.log('clicked --> openDrawer')
	}

	const closeDrawer = () => {
		setDrawerOpen(false);
	}

	const getHamburgerIconColor = () => {
		const curr_path = props.location.pathname;
		const path_parts = curr_path.split('/')

		if (path_parts.length > 3 && path_parts[2] === 'quiz') {
			return getActiveThemeColor(profile);
		}
		return '#4a90e2';
	}

	return (
		<section>

			<SideBar show={drawerOpen} closeBar={closeDrawer} />

			{drawerOpen && <BackDrop closeDrawer={closeDrawer} />}

			<header className={'header'}>

				<div className={'toggle'} onClick={openDrawer}>
					<span style={{background: getHamburgerIconColor()}} />
					<span style={{background: getHamburgerIconColor()}} />
					<span style={{background: getHamburgerIconColor()}} />
				</div>

				{
					props.location.pathname === '/home' ? undefined : <img  src={headerImg} onClick={()=>history.push('/home')} />
				}


			</header>
		</section>

	)

}

export default withRouter(Header);