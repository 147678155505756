import raz_assess_start from "../../../assets/raz/RazPoses_0001_lets_start.png";
import raz_assess_section from "../../../assets/raz/RazPoses_0000_sections.png";
import raz_assess_game from "../../../assets/raz/RazPoses_0002_lets_play.png";
import raz_quiz_time from "../../../assets/raz/RazPoses_0006_quiz_time.png";

import React from "react";
import BorderButton , {btnSize} from "../../../components/UI/BorderButton/BorderButton.ui";
import {getActiveThemeColor} from "../../../utils/utils";
import {useGlobalState} from "../../../context/global_state";
import Footer from "../../../components/Footer/plugable.footer";
import {GET_APP_LOCALIZE_TEXT} from "../../../utils/dq_lc_service/lc_service";

const GuidePoster = (props) => {

    const { locale, nextAction, title, description, raz_type } = props;

    const { profile } = useGlobalState();

    const getRazSrcByType = (r_type) => {
        switch (r_type) {
            case 'raz_assess_start':
                return raz_assess_start;
            case 'raz_assess_section':
                return raz_assess_section;
            case 'raz_assess_game':
                return raz_assess_game;
            case 'raz_quiz_time':
                return raz_quiz_time;
            default:
                return raz_assess_start;
        }
    }

    return (
        <>
            <div className={'qt-guide-container'}>
                <div
                    style={{color: getActiveThemeColor(profile)}}
                    className={'qt-guide-heading'} >
                    <span>{title}</span>
                </div>
                <div className={'qt-guide-img-container'}>
                    <img className={'qt-guide-img'} src={getRazSrcByType(raz_type)} />
                </div>
                <div className={'qt-guide-body'}>
                    <span>{description && description.replace('This score', 'This test')}</span>
                </div>
            </div>
            <div
                className={'qt-cont-btn-container'}>
                <BorderButton
                    size={btnSize.md}
                    title={GET_APP_LOCALIZE_TEXT(locale, 'result_quiz__next')}
                    action={nextAction}
                    themeColor={getActiveThemeColor(profile)}
                />
            </div>
            <Footer />
        </>
    )
};

export default GuidePoster;