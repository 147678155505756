import React, {useState, useEffect} from 'react';
import "./modal.css";
import {GET_APP_LOCALIZE_TEXT} from "../../../utils/dq_lc_service/lc_service";
import {useLocation} from "react-router-dom";
import DashboardLogin from "./DashboardLogin.modal";
import ContactUs from "./ContactUs.modal";

import Modal from "react-modal";



const LoginModal = (props) => {

    Modal["setAppElement"] = '#root'

    const location = useLocation();

    const pageLc = location.pathname.split('/')[1];

    const [isOpen, setIsOpen] = useState(false);

    const secondaryTag = <ContactUs/>

    const SwitchTag = () => {
        setDisplayTag(secondaryTag)
    };

    const closeModal = () => {
        setIsOpen(false);
    }

    const primaryTag = <DashboardLogin onSwitch={SwitchTag} closeModal={closeModal}/>

    const [displayTag, setDisplayTag] = useState(primaryTag);

    function toggleModal() {
        if(!isOpen)
        {
            setDisplayTag(primaryTag)
        }
        setIsOpen(!isOpen);
    }

    return (
        <div className="Modal">
            <a className='imenu-link' onClick={toggleModal}>{GET_APP_LOCALIZE_TEXT(pageLc, 'persist_guest__login')}</a>
            <Modal
                isOpen={isOpen}
                onRequestClose={toggleModal}
                contentLabel="My dialog"
                className="mymodal"
                overlayClassName="myoverlay"
                closeTimeoutMS={500}
            >
                <div>
                    {displayTag}
                </div>
                {/*<button onClick={toggleModal}>Close modal</button>*/}
            </Modal>
        </div>
    );
}

export default LoginModal;