import React, {useEffect, useState} from 'react';
import BadgeListItem from "./components/BadgeListItem.comp";
import {LOCAL_STORAGE_KEYS} from "../../utils/constants";
import BadgeListItemDetail from "./components/BadgeListItemDetail.comp";
import {useGlobalState} from "../../context/global_state";
import {getArrayFromMap, getMapFromObjectListByKey} from "../../utils/utils";
import {getProfileService} from "../../services/home.service";
import LineChart from "../../components/UI/Charts/LineChart/LineChart.ui";
import ScoreChart from "../../components/UI/Charts/ScoreChart.ui";

import {isBadgeEarned} from "../../utils/utils";

import statsChart from '../../assets/images/stats_chart.png';
import badgeChart from "../../assets/images/badge-exl-chart.jpg";
import {withRouter} from "react-router-dom";


const data = [
	[
		{ label: "04/2020", x: 0, y: 40 },
		{ label: "05/2020", x: 1, y: 56 },
		{ label: "06/2020", x: 2, y: 30.01 },
		{ label: "07/2020", x: 3, y: 30.99 },
		{ label: "08/2020", x: 4, y: 45 },
		{ label: "09/2020", x: 5, y: 56 },
		{ label: "10/2020", x: 6, y: 77 },
		{ label: "11/2020", x: 7, y: 87 },
		{ label: "12/2020", x: 8, y: 81 }
	],
	[
		{ label: "04/2020", x: 0, y: 56 },
		{ label: "05/2020", x: 1, y: 45 },
		{ label: "06/2020", x: 2, y: 30 },
		{ label: "07/2020", x: 3, y: 39 },
		{ label: "08/2020", x: 4, y: 40 },
		{ label: "09/2020", x: 5, y: 66 },
		{ label: "10/2020", x: 6, y: 37 },
		{ label: "11/2020", x: 7, y: 82 },
		{ label: "12/2020", x: 8, y: 85 }
	]
];


const Stats = (props) => {

	const { history } = props;

	const [items, setItems] = useState([]);
	const [selectedItems, setSelectedItems] = useState({});
	const [areaData, setAreaData] = useState(undefined);
	const [totalScore, setTotalScore] = useState(0);

	const [totalBadges, setTotalBadges] = useState(0);
	const [completedBadges, setCompletedBadges] = useState(0);
	const [strengths, setStrengths] = useState([]);
	const [weaknesses, setWeaknesses] = useState([]);

	const [badgesMetaMap, setBadgesMetaMap] = useState(undefined)

	const { setToastShow, setToastMessage, profile, setProfile } = useGlobalState();

	useEffect( ()=>{
		let finished_tests = 0;
		let net_score = 0;
		let earnedBadges = 0;
		let _strengths = [];
		let _weaknesses = [];
		const _badges = profile.badges
		for (let [key, _badge] of Object.entries(_badges)) {
			if (_badge.finished) {
				const sc = parseFloat(_badge.obtained_score);
				if (sc > 0) {
					finished_tests ++;
					net_score += sc;
					if (isBadgeEarned(sc)) {
						earnedBadges++;
						_strengths.push([key, sc])
					}else{
						_weaknesses.push([key, sc])
					}
				}
			}
		}
		if (finished_tests > 0) {
			const score_avg = net_score / finished_tests
			setTotalScore(score_avg.toFixed(2));

			setCompletedBadges(earnedBadges);

			_strengths = _strengths.sort((f, s) => s[1]-f[1]).slice(0,2)
			_weaknesses = _weaknesses.sort((f, s) => f[1]-s[1]).slice(0,2)
			setStrengths(_strengths);
			setWeaknesses(_weaknesses);
		}
	}, [profile] )

	useEffect( ()=> {

		if (process.env.REACT_APP_DEVSID_BUILD === '1') {
			history.push('/home');
		}

		const app_start_cached_data = localStorage.getItem(LOCAL_STORAGE_KEYS.HOME_APP_START_API_RESPONSE);

		loadProfile().then(d=>console.log('fresh profile loaded'));

		if (app_start_cached_data) {

			const api_data = JSON.parse(app_start_cached_data);
			const badges_meta_ui = api_data.ui_config.badges_uic;
			const badges_meta_map = getMapFromObjectListByKey(api_data.meta_data.badges_meta, 'code');
			const areas_map = getMapFromObjectListByKey(api_data.meta_data.areas_meta, 'code');
			const areas_ui_conf = getMapFromObjectListByKey(api_data.ui_config.areas_uic, 'area_code');

			let areasMap = {};

			let totalBadgeCount = 0;

			for (let badge of badges_meta_ui) {
				const badge_area_ui = areas_ui_conf[badge.area_code];
				const badge_area_meta = areas_map[badge.area_code];
				if (badge_area_ui && badge_area_meta) {
					let badge_area = areasMap[badge.area_code]
					if (!badge_area) {
						badge_area = {
							badges: [],
							...badge_area_meta,
							...badge_area_ui
						}
					}
					const badge_meta_extra = badges_meta_map[badge.badge_code];
					if (badge_meta_extra) {
						badge = {
							...badge,
							...badge_meta_extra
						}
					}
					badge_area.badges.push(badge);
					totalBadgeCount++;
					areasMap = {
						...areasMap,
						[badge.area_code]: badge_area
					}
				}
			}

			setAreaData(areasMap);
			let areas = getArrayFromMap(areasMap);
			setItems(areas);
			setTotalBadges(totalBadgeCount);
			setBadgesMetaMap(badges_meta_map);
		}

	}, [] );

	const loadProfile = async () => {
		try {
			const res = await getProfileService();
			setProfile(res.data.profile);
		} catch (e) {
			setToastShow(true);
			setToastMessage(e.message);
		}
	}

	const selectItem = (id) => {

		let itemMap = {...selectedItems};

		if (itemMap !== undefined) {
			itemMap[id] = !itemMap[id];
			setSelectedItems(itemMap);
		}

	}

	return (
		<div className={'stats-container'}>
			<div className={'stats-content'}>
				<h1 className={'stats-title'}>My DQ Score</h1>
				<div className={'score-spinner'}>
					<span>{(totalScore+'').split('.')[0]}</span><span className={'score-label-pt'}>.{(totalScore+'').split('.')[1]}</span>
				</div>
			</div>
			<div className={'stats-charts-section'}>
				<ScoreChart
					score={totalScore}
					scoreChange={-0.12}
					showStats={true}
				/>
			</div>
			
			{/*<LineChart*/}
			{/*	width={400}*/}
			{/*	height={200}*/}
			{/*	data={data}*/}
			{/*	horizontalGuides={4}*/}
			{/*	yMaxVal={100}*/}
			{/*	precision={0}*/}
			{/*	verticalGuides={0}*/}
			{/*/>*/}
			
			{/*<div className={'stats-charts_btn_section'}>*/}
			{/*	<div className={'stats-charts-btn-container'}>*/}
			{/*		<div className={'stats-tmp-btn'} />*/}
			{/*		<div className={'stats-tmp-btn'} />*/}
			{/*		<div className={'stats-tmp-btn'} />*/}
			{/*		<div className={'stats-tmp-btn'} />*/}
			{/*		<div className={'stats-tmp-btn'} />*/}
			{/*		<div className={'stats-tmp-btn'} />*/}
			{/*		<div className={'stats-tmp-btn'} />*/}
			{/*	</div>*/}
			{/*</div>*/}
			<div className={'stats-remarks-section'}>
				{
					strengths.length > 0 ? 
						<div className={'stats-remarks-item-container'}>
							<h1 className={'stats-title'}>My Strengths</h1>
							<div className={'stats-feedback-section stats-stregnths'}>
								<ol>
									{
										badgesMetaMap && strengths.map( (st, index) => <li><div
											key={'st-' + index}
											className={'row'}>
											<div className={'col-name'}>{`${badgesMetaMap[st[0]].app_badge_name}`}</div>
											<div className={'row-col-sm'}>{st[1]}</div>
											<div className={'row-col-sm'}><span>Share</span></div>
										</div></li> )
									}
								</ol>
							</div>
						</div>
					: null
				}
				{
					weaknesses.length > 0 ?
					<>
						<div className={'stats-remarks-item-container'}>
							<h1 className={'stats-title'}>My Weaknesses</h1>
							<div className={'stats-feedback-section stats-weaknesses'}>
								<ol>
									{
										badgesMetaMap && weaknesses.map( (wk, index) => <li><div
											key={'wk-' + index}
											className={'row'}>
											<div className={'col-name'}>{`${badgesMetaMap[wk[0]].app_badge_name}`}</div>
											<div className={'row-col-sm'}>{wk[1]}</div>
											<div className={'row-col-sm'}><span>Retake</span></div>
										</div></li> )
									}
								</ol>
							</div>
						</div>
						<div className={'stats-remarks-item-container'}>
							<h1 className={'stats-title'}>Suggested Tips</h1>
							<div className={'stats-feedback-section stats-tips'}>
								<ul>
									<li>Be careful of what you post online. Your score seems to be affected mostly by this.</li>
									<li>Remember to ask others before you post anything about them.</li>
									<li>Social media can be a great tool for work but remember to watch what you post about your organization on them.</li>
								</ul>
							</div>
						</div>
					</>	
					: null
				}
			</div>
			<h1 className={'stats-title'}>My DQ Badges</h1>
			<div className={'stats-badges-progress-content'}>
				<div>Completed</div>
				<div className={'stats-badges-progress'}>
					<span className={'stats-badges-progress-bar'} style={{width: `${completedBadges/totalBadges*100}%`}}></span>
				</div>
				<div className={''}>{completedBadges}/{totalBadges}</div>
			</div>
			{
				areaData && items.map((item, index) => (
					<div
						key={`adata-${index}`}
					>
						<BadgeListItem key={'list-item-'+index} item={item} areaData={areaData} selectItem={selectItem} />
						<BadgeListItemDetail
							key={'list-item-detail-'+index}
							selected={selectedItems[item.id]}
							item={item}
							areaData={areaData}
							profile={profile}
						/>
					</div>

				))
			}
		</div>
	);
}

export default withRouter(Stats);

