
const lc_en = {
    privacy_policy__title: `Privacy Policy`,
    privacy_policy__intro: `1. Introduction`,
    privacy_policy__para_1: `The DQ Assessment Sites (“<b>Sites</b>”) provide a platform to test your digital intelligence or DQ. You have trusted us to make safe and quality content that users of all ages can enjoy. Online, this trust is even more important to you, and to DQ LAB Pte. Ltd. (“<b>DQ LAB</b>”, “<b>we</b>”, “<b>our</b>”, or “<b>us</b>”). To make sure that the Sites stay safe and fun, and because your privacy is important to us, we will abide by this Privacy Policy.`,
    privacy_policy__para_2: `This Privacy Policy describes the types of information which we may collect about you on the Sites where this Privacy Policy is posted and on applications we make available on third-party sites or platforms (if disclosed to you in connection with use of the application), how this information may be used and/or disclosed, the purposes for which your information is collected, used and/or disclosed, the retention of such information, and your right to access and correct such information. Personal information is information or a combination of pieces of information that could reasonably allow you to be identified (“<b>Personal Information</b>”). This Privacy Policy relates to the DQ LAB Services on the Sites (the “<b>Services</b>”), which comprise a questionnaire on digital intelligence for children, that are provided by DQ LAB.`,
    privacy_policy__info_we_collect: `2. Information we collect`,
    privacy_policy__para_3: `DQ LAB will collect personal information about you from a variety of sources, including information we collect from you directly (e.g. when you contact us). Note that we may be required by law to collect certain personal information about you, or as a consequence of any contractual relationship we have with you. Failure to provide this information may prevent or delay the fulfilment of these obligations. We will inform you at the time your information is collected whether certain data is compulsory and the consequences of the failure to provide such data. To access the Services, DQ LAB may collect information about you and your child such as:`,
    privacy_policy__p2_personal_details: `Personal details (e.g. name and date of birth)`,
    privacy_policy__p2_contact_details: `Contact details (e.g. email address, phone number, postal address or mobile number)`,
    privacy_policy__p2_other_info: `Other information such as the name of your school, your child's school or organization;`,
    privacy_policy__p2_device_properties: `Device properties, including but not limited to IP address, unique device identifier or other persistent or non-persistent device identifier;`,
    privacy_policy__p2_device_software: `Device software platform and firmware;`,
    privacy_policy__p2_geographical_data: `Geographical data such as zip code, area code and approximate location;`,
    privacy_policy__p2_activity_progress: `Activity progress, time used playing, score and achievements; and`,
    privacy_policy__p2_your_responses: `Your responses to various surveys and quizzes`,
    privacy_policy__p2_ending_note: `Please note, that parents, teachers or legal guardians may be asked to provide their child's name and email address in addition to information about themselves. In that event, such information will also be subject to this Privacy Policy.`,
    privacy_policy__how_we_use_ur_info: `3. How we use your personal information and the legal basis on which we use it`,
    privacy_policy__p3_dq_may_use_ps_info: `DQ LAB may use your Personal Information to:`,
    privacy_policy__p3_send_msg_about_dqlab: `send you messages about DQ LAB;`,
    privacy_policy__p3_send_msg_about_research: `send you messages about research;`,
    privacy_policy__p3_send_msg_about__new_feat: `send you messages about new features or services;`,
    privacy_policy__p3_send_msg_about_prom_material: `send you messages about promotional material from some of our partners;`,
    privacy_policy__p3_send_message_about_marketing: `send you messages about marketing communications relating to our business which we think may be of interest to you, by post or, where you have specifically agreed to this, by email or similar technology (you can inform us at any time if you no longer require marketing communications to contact you when necessary;`,
    privacy_policy__p3_dq_may_use_combine_ps_info: `DQ LAB also may use your Personal Information, both individually or combined together:`,
    privacy_policy__p3_for_publish_research_articles: `for publishing research articles and/or reports;`,
    privacy_policy__p3_for_feedback_purpose: `for editorial and feedback purposes;`,
    privacy_policy__p3_for_marking_purpose: `for marketing and promotional purposes;`,
    privacy_policy__p3_for_statistical_analysis: `for statistical analysis of users' behaviour;`,
    privacy_policy__p3_for_content_improve: `for content improvement;`,
    privacy_policy__p3_for_tech_support: `for technical support;`,
    privacy_policy__p3_obtain_views_on_services: `obtain your views or comments on the Services;`,
    privacy_policy__p3_to_verify_id: `to verify your identity;`,
    privacy_policy__p3_to_provide_service: `to manage and provide the Services; and`,
    privacy_policy__p3_to_customize_content: `to customize content and layout of the Services.`,
    privacy_policy__para_4: `DQ LAB does not sell or rent your Personal Information to third-parties for commercial purposes. DQ LAB may share your Personal Information you provide with our partners only for research purposes as described in the Information Sharing section below.We must have a legal basis to process your Personal Information. In most cases, the legal basis will be one of the following:`,
    privacy_policy__para_5: `To fulfil our contractual obligations to you, for example, to provide the services. Failure to provide this information may prevent or delay the fulfilment of these contractual obligations;`,
    privacy_policy__p3_comply_with_legal_obligations: `To comply with our legal obligations; or`,
    privacy_policy__para_6: `To meet our legitimate interests, for example, to understand how you use our services and to enable us to derive knowledge from that to enable us to develop new services. When we process Personal to meet our legitimate interests, we put in place robust safeguards to ensure that your privacy is protected and to ensure that our legitimate interests are not overridden by your interests or fundamental rights and freedoms. For more information on the balancing test that we carry out to process your personal information to meet our legitimate interests, please contact us at the details below.`,
    privacy_policy__para_7: `We may obtain your consent to collect and use certain types of personal information when we are required to do so by law (for example, in relation to our direct marketing activities, or our use of cookies and tracking technologies). If we ask for your consent to process your personal information in relation to these activities, you may withdraw your consent at any time by contacting us using the details at the end of this privacy notice.We do not directly market to children. Direct marketing communications are only sent to parents and schools to encourage the use of the DQ LAB platform.`,
    privacy_policy__automated_processing: `4. Automated Processing`,
    privacy_policy__para_8: `We use automated processing to produce DQ Reports to parents and teachers to keep them up to date about their child's progress on the programme. These reports are generated by our system based on the child's responses throughout the programme and are used to identify their strengths as well as any potential risks to the child.`,
    privacy_policy__info_sharing: `5. Information Sharing`,
    privacy_policy__para_9: `We may share your Personal Information with third parties under the following circumstances:`,
    privacy_policy__para_10: `S ervice providers and business partners. We may share your Personal Information with our service providers and business partners that perform marketing services and other business operations for us. For example, we may partner with other companies to optimize our services, send newsletters and marketing emails, support email and messaging services and analyze information.`,
    privacy_policy__para_11: `Law enforcement agency, court, regulator, government authority or other third party. We may share your Personal Information with these parties where we believe this is necessary to comply with a legal or regulatory obligation, or otherwise to protect our rights or the rights of any third party.`,
    privacy_policy__para_12: `Asset purchasers. We may share your Personal Information with any third party that purchases, or to which we transfer, all or substantially all of our assets and business. Should such a sale or transfer occur, we will use reasonable efforts to try to ensure that the entity to which we transfer your personal information uses it in a manner that is consistent with this privacy notice.`,
    privacy_policy__para_13: `Because we operate as part of a global business, the recipients referred to above may be located outside the jurisdiction in which you are located (or in which we provide the services). See the section on “International Data Transfer” for more information.`,
    privacy_policy__point_6: `6. Children's Privacy`,
    privacy_policy__para_14: `We recognize the need to provide further privacy protections with respect to Personal Information we may collect from children under the age of eighteen (18) who are users of our Sites and applications (“Children” or “Child”). When we intend to collect Personal Information from Children, we take additional steps to protect Children's privacy, including:`,
    privacy_policy__p6_limiting_collection_of_ps_info: `Limiting our collection of Personal Information from Children to no more than is reasonably necessary to participate in an online activity;`,
    privacy_policy__p6_mandating_strog: `Mandating strong passwords be used when Children sign up for the Service;`,
    privacy_policy__p6_utilize_secure_login: `Utilizing a secure login form for the login pages of the Sites;`,
    privacy_policy__p6_ensuring_dqlab_info_tech: `Ensuring that DQ LAB's information technology vendors and/or staff are reputable, experienced and properly accredited;`,
    privacy_policy__p6_implementing_robust_policy: `Implementing robust policies and procedures regarding confidentiality obligations;`,
    privacy_policy__p6_anonymize_personal_info: `Anonymizing Personal Information of Children before disclosure, where feasible;`,
    privacy_policy__p6_req_for_updated_copy: `Requesting for an updated copy of the Personal Information before making a decision that will significantly affect the Child;`,
    privacy_policy__p6_req_updates_for_ps_info: `Requesting updates of the Personal Information from the Children on a regular basis;`,
    privacy_policy__p6_parents_ability_to_req_access: `Giving parents/guardians the ability to request access to Personal Information we have collected from their Children and the ability to request that the Personal Information be changed or deleted;`,
    privacy_policy__para_15: `Requiring parents/guardians to acknowledge our Terms of Use and Privacy Policy for Children, and give permission for Children to provide us with certain Personal Information required to set up an account on the Sites, to enter a contest and/or to participate in any other activity offered through the Sites (“<b>Parental Consent</b>”). In the event that a Child’s account (“<b>Sub-Account</b>”) is set up on his/her behalf by a teacher or educator in charge of him/her (“<b>School Account Holder</b>”), we mandate that such School Account Holder inform that Child's parent or guardian in writing with instructions for how to opt-out if needed. Children whom DQ LAB has knowledge are under the age of 13 and have registered individual accounts on the Sites are required to provide a parent's/guardian's e-mail address, and must have a parent/guardian provide Parental Consent. This e-mail address will be sent a notification message with an embedded link to our Terms of Use, Privacy Policy and Parental Consent page. The Child's registration or request will be considered incomplete until the Child's parent/guardian uses this link to provide Parental Consent; and`,
    privacy_policy__p6_dqlab_recommends_parents: `DQ LAB recommends that parents/guardians monitor and supervise the online activities of their Children.`,
    privacy_policy__point_7: `7. Public Information Including User Generated Content (“UGC”)`,
    privacy_policy__para_16: `Some of the Services may allow you to enter text and information in fields that are accessible by the general public, such as comment sections, and message boards. If you choose to participate in these public areas of the Services, please be aware that any information submitted through these areas will be in the public domain. Please use caution in disclosing Personal Information in these areas. You are responsible for any content, including Personal Information, that you contribute to be published or displayed (hereinafter, “<b>posted</b>”) on the Services or transmit to other users of the Services. Your UGC is posted on and transmitted to other users of the Services at your own risk. By posting UGC you are allowing all users of the Services to access and use that UGC, and to associate it with you. If we provide a feature that allows tagging of photos and profiles to UGC, then you or other users may tag your profile to UGC. Please be aware that no security measures are perfect or impenetrable.`,
    privacy_policy__para_17: `Although we moderate public forums to keep their content appropriate for the forum's intended audience, we are not responsible for the information you or your Child submits in these open forums and in the public domain, nor for other users’ actions with respect to that information. Additionally, we cannot control the actions of other users of the Services with whom you may choose to share your UGC. Therefore, we cannot and do not guarantee that your UGC will not be viewed by unauthorized persons. We are not responsible for circumvention of any privacy settings or security measures contained on the Services. You understand and acknowledge that, even after removal, copies of your UGC may remain viewable in cached and archived pages, or might have been copied or stored by other users of the Services.`,
    privacy_policy__p7_you_acknowledge_that: `You acknowledge that you are responsible for any UGC you submit or contribute, and all UGC shall comply with the Terms of Use and all applicable laws and regulations.`,
    privacy_policy__point_8: `8. Changes to this Privacy Policy`,
    privacy_policy__para_18: `DQ LAB reserves the right to change or update this Privacy Policy, or any other of our policies or practices, at any time, and will notify users of these Sites by posting such changed or updated Privacy Policy on this page. Any changes or updates will be effective immediately upon posting to these Sites. Where changes to this privacy notice will have a fundamental impact on the nature of the processing or otherwise have a substantial impact on you, we will give you sufficient advance notice so that you have the opportunity to exercise your rights (e.g. object to the processing).`,
    privacy_policy__p8_may_req_a_copy: `You may request a copy of this Privacy Policy from us using the contact details set out below.`,
    privacy_policy__point_9: `9. Data Security and Integrity`,
    privacy_policy__para_19: `The security, integrity and confidentiality of your information are extremely important to us. We have implemented technical, administrative and physical security measures that are designed to reasonably protect Personal Information from unauthorized access, collection, disclosure, use, copying, modification, disposal or similar risks. From time to time, we review our security procedures to consider appropriate new technology and methods. However, please be aware that despite our best efforts, no security measures can be perfect or impenetrable.`,
    privacy_policy__para_20: `The safety and security of your Personal Information also depends on you. Where we have given you (or where you have chosen) a password for access to certain parts of the Sites, you are responsible for keeping this password confidential. We ask you not to share your password with anyone. We urge you to be careful about giving out Personal Information in public areas of the Sites like message boards. The information you share in public areas may be viewed by any user of the Sites.`,
    privacy_policy__para_21: `Unfortunately, the transmission of information via the Internet is not completely secure. Although we do our best to protect your Personal Information, we cannot guarantee the security of your Personal Information transmitted to DQ LAB. Any transmission of Personal Information is at your own risk.`,
    privacy_policy__point_10: `10. Retention of Personal Information`,
    privacy_policy__para_22: `We implement technical and organizational measures to ensure a level of security appropriate to the risk to the Personal Information we process. These measures are aimed at ensuring the on-going integrity and confidentiality of Personal Information. We evaluate these measures on a regular basis to ensure the security of the processing.`,
    privacy_policy__para_23: `We will keep your personal information for as long as we have a relationship with you. Once our relationship with you has come to an end, we will retain your personal information for a period of time that enables us to:`,
    privacy_policy__p10_maintain_records: `Maintain business records for analysis and/or audit purposes`,
    privacy_policy__p10_comply_with_record: `Comply with record retention requirements under the law`,
    privacy_policy__p10_defend_or_bring: `Defend or bring any existing or potential legal claims`,
    privacy_policy__p10_deal_with_complaints: `Deal with any complaints regarding the services`,
    privacy_policy__para_24: `We will delete your personal information when it is no longer required for these purposes. If there is any information that we are unable, for technical reasons, to delete entirely from our systems, we will put in place appropriate measures to prevent any further processing or use of the data.`,
    privacy_policy__point_11: `11. Your rights over your personal information`,
    privacy_policy__para_25: `You have certain rights regarding your personal information, subject to local law. These include the following rights to:`,
    privacy_policy__p11_access_ps_info: `access your personal information`,
    privacy_policy__p11_rectify_info: `rectify the information we hold about you`,
    privacy_policy__p11_erase_ps_info: `erase your personal information`,
    privacy_policy__p11_restict_use_of_ps_info: `restrict our use of your personal information`,
    privacy_policy__p11_objection_on_ps_info: `object to our use of your personal information`,
    privacy_policy__para_26: `receive your personal information in a useable electronic format and transmit it to a third party (right to data portability)`,
    privacy_policy__p11_lodge_complaint: `lodge a complaint with your local data protection authority.`,
    privacy_policy__p11_contact_to_discuss_rights: `If you would like to discuss or exercise such rights, please contact us at the details below.`,
    privacy_policy__para_27: `We encourage you to contact us to update or correct your information if it changes or if the personal information we hold about you is inaccurate.`,
    privacy_policy__p11_will_contact_for_additional_info: `We will contact you if we need additional information from you in order to honor your requests.`,
    privacy_policy__point_12: `12. International data transfer`,
    privacy_policy__para_28: `Your personal information may be transferred to, stored and processed in a country that is not regarded as ensuring an adequate level of protection for personal information by the European Commission.`,
    privacy_policy__para_29: `We have put in place appropriate safeguards (such as contractual commitments) in accordance with applicable legal requirements to ensure that your data is adequately protected. For more information about the appropriate safeguards in place, please contact us at the details below.`,
    privacy_policy__point_13: `13. Contact us`,
    privacy_policy__p13_dqlab_relevant_data: `DQ LAB is the relevant data controller responsible for the Personal Information we collect and process.`,
    privacy_policy__para_30: `Our representative in Europe is below. <br>activeMind.legal <br>Rechtsanwaltsgesellschaft m. b. H <br>Potsdamer Straße 3 <br>80802 München, Germany <br>Phone: +49 (0)89 / 418 560 170 <br>Email: <a href="mailto:eu-dataprotection@dqforall.com">eu-dataprotection@dqforall.com</a>`,
    privacy_policy__para_31: `If you have a comment or question about this Privacy Policy, please contact Guest Services: <a href="mailto:dpo@dqforall.com">dpo@dqforall.com</a>. We will contact you within fourteen (14) days to confirm receipt of your email. Following receipt of your email, we will investigate your concern or complaint and endeavour to provide you with our response within a reasonable timeframe.`,
    privacy_policy__para_32: `We are committed to working with you to obtain a fair resolution of any complaint or concern about privacy. If, however, you believe that we have not been able to assist with your complaint or concern, you have the right to make a complaint to the data protection authority of the country in which you are a resident.`,
}


const lc_ko = {
    privacy_policy__title: `개인정보 정책`,
    privacy_policy__intro: `1. 개요`,
    privacy_policy__para_1: `DQIndex.ORG 및 DQ 평가 사이트("<b>Sites</b>")는 디지털 지능 또는 DQ를 테스트하기 위한 플랫폼을 제공합니다. 귀하는 당사가 모든 연령대의 사용자가 즐길 수 있는 안전하고 질 좋은 콘텐츠를 만들 수 있도록 당사를 신뢰해 왔습니다. DQIndex.ORG의 ("<b>DQ LAB</b>", "<b>we</b>", "<b>our</b>" 또는 "<b>us</b>") 온라인상에서의 신뢰는 DQ LAB Pte. Ltd. 에게 무척 중요합니다. 귀하의 개인정보는 당사에 매우 중요하며, 사이트의 안전과 질을 보장하기 위해, 당사는 이하 개인정보 정책을 준수합니다.`,
    privacy_policy__para_2: `이 개인정보 정책은 이 개인정보 정책이 게시된 사이트 및 제 3의 사이트 또는 플랫폼(어플리케이션 사용과 관련하여 사용자에게 공개되는 경우)에서 당사가 사용자에 대해 수집할 수 있는 정보의 유형, 이 정보의 사용 방법 및/또는 공개 목적에 대해 설명합니다. 개인정보란 사용자를 특정할 수 있는 정보 또는 정보의 조합입니다("<b>「개인정보」</b>). 본 프라이버시 정책은 DQ LAB에서 제공하는 아동용 디지털 시민의식에 관한 설문조사를 위한 DQ LAB 서비스(이하 "서비스")에 관한 것입니다.`,
    privacy_policy__info_we_collect: `2. 당사가 수집하는 정보`,
    privacy_policy__para_3: `DQ LAB에서는 고객으로부터 직접 수집하는 정보 등 다양한 정보원으로부터 고객의 개인정보를 수집합니다(예, 고객 문의 등). 당사는 법률에 따라 귀사에 대한 특정 개인정보를 수집해야 하거나 귀사와의 계약 관계에 따라 수집해야 할 수 있습니다. 이 정보를 제공하지 않으면 이러한 의무의 이행을 방해하거나 지연시킬 수 있습니다. 당사는 귀하의 정보가 수집될 때 특정 데이터가 필수인지 여부와 해당 데이터를 제공하지 못한 경우의 결과를 알려드립니다. 본 서비스에 접근하기 위해 DQ LAB는 귀하와 귀하의 자녀에 대한 다음과 같은 정보를 수집할 수 있습니다.`,
    privacy_policy__p2_personal_details: `개인정보(예: 이름 및 생년월일)`,
    privacy_policy__p2_contact_details: `연락처 정보(예: 이메일 주소, 전화 번호, 우편 주소 또는 휴대폰 번호)`,
    privacy_policy__p2_other_info: `학교 이름, 자녀의 학교 또는 조직과 같은 기타 정보;`,
    privacy_policy__p2_device_properties: `IP 주소, 고유 장치 식별자 또는 기타 영구 또는 비영구 장치 식별자를 포함하되 이에 국한되지 않는 장치 속성;`,
    privacy_policy__p2_device_software: `장치 소프트웨어 플랫폼 및 펌웨어;`,
    privacy_policy__p2_geographical_data: `우편 주소, 지역 번호 및 대략적인 위치와 같은 지리적 데이터;`,
    privacy_policy__p2_activity_progress: `활동 진행, 사용 시간 재생, 점수 및 성과; 그리고`,
    privacy_policy__p2_your_responses: `다양한 설문조사 및 퀴즈에 대한 답변`,
    privacy_policy__p2_ending_note: `부모, 교사 또는 법적 보호자는 자녀의 이름과 이메일 주소와 함께 자녀의 이름과 이메일 주소를 제공하도록 요청받을 수 있습니다. 이 경우 이러한 정보는 본 개인정보 보호정책의 적용을 받습니다.`,
    privacy_policy__how_we_use_ur_info: `부모, 교사 또는 법적 보호자는 자녀의 이름과 이메일 주소와 함께 자녀의 이름과 이메일 주소를 제공하도록 요청받을 수 있습니다. 이 경우 이러한 정보는 본 개인정보 보호정책의 적용을 받습니다`,
    privacy_policy__p3_dq_may_use_ps_info: `DQ LAB은 귀하의 개인정보를 다음과 같은 경우에 사용할 수 있습니다.`,
    privacy_policy__p3_send_msg_about_dqlab: `DQ LAB에 대한 메시지를 보내는 경우;`,
    privacy_policy__p3_send_msg_about_research: `연구에 대한 메시지를 보내는 경우;`,
    privacy_policy__p3_send_msg_about__new_feat: `새로운 기능 및 서비스에 대한 메시지를 보내는 경우;`,
    privacy_policy__p3_send_msg_about_prom_material: `일부 비즈니스 파트너의 홍보물에 대한 메시지를 보내는 경우;`,
    privacy_policy__p3_send_message_about_marketing: `당사의 비즈니스와 관련된 마케팅 커뮤니케이션에 관한 메시지를 우편 또는 이메일 등 방법을 통해 보내는 경우(마케팅 커뮤니케이션이 더는 필요하지 않을 경우 당사로 안내 바랍니다);`,
    privacy_policy__p3_dq_may_use_combine_ps_info: `DQ LAB에서는 다음과 같은 경우에 개인정보를 개별적으로 또는 조합하여 사용할 수 있습니다.`,
    privacy_policy__p3_for_publish_research_articles: `연구 기사 및/또는 보고서를 게시하기 위한 경우;`,
    privacy_policy__p3_for_feedback_purpose: `편집 및 피드백을 위한 경우;`,
    privacy_policy__p3_for_marking_purpose: `마케팅 및 홍보 목적으로 사용하는 경우;`,
    privacy_policy__p3_for_statistical_analysis: `사용자의 행동에 대한 통계 분석을 위한 경우;`,
    privacy_policy__p3_for_content_improve: `콘텐츠 개선을 위한 경우;`,
    privacy_policy__p3_for_tech_support: `기술 지원을 위한 경우;`,
    privacy_policy__p3_obtain_views_on_services: `서비스에 대한 귀하의 견해 또는 의견을 얻을 경우;`,
    privacy_policy__p3_to_verify_id: `귀하의 신원을 확인하는 경우;`,
    privacy_policy__p3_to_provide_service: `서비스를 관리하고 제공하기 위한 경우; 그리고`,
    privacy_policy__p3_to_customize_content: `서비스의 콘텐츠 및 레이아웃을 사용자에 맞게 제공하기 위한 경우.`,
    privacy_policy__para_4: `DQ LAB는 상업적 목적으로 귀하의 개인정보를 제3자에게 판매하거나 대여하지 않습니다. DQ LAB에서는, 이하의 '정보 공유' 섹션에 기재되어 있는 조사 목적으로만, 고객이 제공하는 개인정보를 당사의 파트너와 공유할 수 있습니다. 당사는 귀하의 개인정보를 처리하기 위한 법적 근거를 가지고 있어야 합니다. 대부분의 경우 법적 근거는 다음 중 하나입니다.`,
    privacy_policy__para_5: `서비스를 제공하는 등과 같은 게약상의 이무를 이행하기 위한 경우. 이 경우 개인정보를 제공하지 않으면 계약상의 의무가 이행되지 않거나 지연될 수 있습니다.`,
    privacy_policy__p3_comply_with_legal_obligations: `당사의 법적 의무를 준수하기 위한 경우; 또는`,
    privacy_policy__para_6: `사용자가 당사의 서비스를 어떻게 사용하는지 이해하고, 거기서 지식을 얻어 새로운 서비스를 개발하는 등 당사의 이익을 정당하게 추구하는 경우. 당사는 사용자의 개인정보가 보호되고 이익이나 기본적 권리 및 자유에 의해 당사의 정당한 이익이 무시되지 않도록 하기 위해 강력한 보호 수단을 강구합니다. 당사가 사용자의 개인정보를 처리하기 위해 실시하는 테스트에 대한 정보는 아래 연락처를 통해 문의해주십시오.`,
    privacy_policy__para_7: `당사는 법률에 의해 특정 유형의 개인정보 수집 및 사용이 요구되는 경우(예, 당사의 직접 마케팅 활동, 쿠키 및 추적 기술 사용 등) 귀하의 동의를 얻을 수 있습니다. 당사의 이러한 활동에 관한 개인정보의 처리에 대해 동의를 구했을 경우, 본 개인정보 통지서의 마지막 부분에 기재되어 있는 상세 정보를 사용해 당사에 연락함으로써 언제든지 동의를 철회할 수 있습니다. 당사는 어린이에게 직접 마케팅을 하지 않습니다. DQ LAB 플랫폼의 사용을 장려하기 위해 학부모 및 학교와 직접 커뮤니케이션합니다.`,
    privacy_policy__automated_processing: `4. 자동 처리`,
    privacy_policy__para_8: `우리는 자동화 된 시스템을 사용하여 부모와 교사에게 DQ 보고서를 생성, 자녀의 프로그램 진행 상황에 대해 최신 상태로 유지합니다. 이 보고서는 프로그램 전반에 걸쳐 자녀의 반응을 기반으로 시스템에 의해 생성되며 자녀에게 잠재적인 위험뿐만 아니라 자신의 강점을 식별하는 데 사용됩니다.`,
    privacy_policy__info_sharing: `5. 정보 공유`,
    privacy_policy__para_9: `당사는 다음과 같은 상황에서 귀하의 개인정보를 제3자와 공유할 수 있습니다.`,
    privacy_policy__para_10: `서비스 공급자 및 비즈니스 파트너. 당사는 당사를 위해 마케팅 서비스 및 기타 비즈니스 운영을 수행하는 당사의 서비스 제공 업체 및 비즈니스 파트너에 귀하의 개인정보를 공유할 수 있습니다. 예를 들어, 당사는 서비스를 최적화하고, 뉴스레터 및 마케팅 이메일을 보내고, 이메일 및 메시징 서비스를 지원하고, 정보를 분석하는 비즈니스 파트너가 있을 수 있습니다.`,
    privacy_policy__para_11: `법 집행기관, 법원, 규제 기관, 정부 기관 또는 기타 제3자. 당사는 법적 또는 규제 의무를 준수하거나 당사의 권리 또는 제3자의 권리를 보호하기 위해 필요하다고 판단되는 경우 귀하의 개인정보를 이러한 당사자와 공유할 수 있습니다.`,
    privacy_policy__para_12: `자산 구매자. 당사는 귀하의 개인정보를 구매하거나 당사가 당사의 자산 및 비즈니스를 전부 또는 실질적으로 양도하는 제3자와 공유할 수 있습니다. 이러한 판매 또는 양도가 발생하는 경우, 당사는 당사가 귀하의 개인정보를 전송하는 법인이 개인정보 보호 고지와 일치하는 방식으로 이를 사용하도록 보장하기 위해 합리적인 노력을 기울일 것입니다.`,
    privacy_policy__para_13: `당사는 글로벌 비즈니스의 일환으로 운영되기 때문에 위에서 언급한 수령인은 귀하가 위치한 관할권(또는 당사가 서비스를 제공하는 경우) 외부에 있을 수 있습니다. 자세한 내용은 "국제 데이터 전송"에 대한 섹션을 참조하십시오.`,
    privacy_policy__point_6: `6. 아동 개인정보`,
    privacy_policy__para_14: `당사는 당사 사이트 및 애플리케이션의 사용자가 18세 미만의 아동일 경우, 수집할 수 있는 개인정보와 관련하여 추가적인 개인정보 보호를 제공할 필요성을 인식하고 있습니다. 당사가 아동으로부터 개인 정보를 수집하려는 경우, 당사는 아동의 개인정보를 보호하기 위한 추가 조치를 취합니다: `,
    privacy_policy__p6_limiting_collection_of_ps_info: `온라인 활동에 참여하는 데 합리적으로 필요한 것 이상으로 아동으로부터 개인정보 수집을 제한합니다.`,
    privacy_policy__p6_mandating_strog: `아동가 서비스에 가입할 때 강력한 비밀번호를 의무화;`,
    privacy_policy__p6_utilize_secure_login: `사이트의 로그인 페이지에 대한 보안 로그인 양식을 활용;`,
    privacy_policy__p6_ensuring_dqlab_info_tech: `DQ LAB의 정보 기술 공급업체 및/또는 직원이 평판이 많고 경험이 풍부하며 적절한 인증을 받을 수 있도록 함;`,
    privacy_policy__p6_implementing_robust_policy: `기밀 유지 의무에 관한 강력한 정책 및 절차를 구현;`,
    privacy_policy__p6_anonymize_personal_info: `공개하기 전에 아동의 개인정보를 익명화(가능한 경우);`,
    privacy_policy__p6_req_for_updated_copy: `아동에게 큰 영향을 미칠 결정을 내리기 전에 개인정보의 업데이트 된 사본을 요청;`,
    privacy_policy__p6_req_updates_for_ps_info: `정기적으로 아동에게 정보 업데이트를 요청;`,
    privacy_policy__p6_parents_ability_to_req_access: `부모/보호자에게 당사가 아동으로부터 수집한 개인정보에 대한 접근을 요청할 수 있는 능력과 개인정보의 변경 또는 삭제를 요청할 수 있는 권한을 부여;`,
    privacy_policy__para_15: `부모/보호자가 우리의 아동을 위한 이용약관 및 개인정보 정책에 동의하고 아동이 사이트에서 계정을 설정하기 위해 필요한 특정 개인정보를 제공하거나 토론에 참가하거나 사이트를 통해 제공되는 기타 활동에 참가하기 위해 필요한 권한을 부여하도록 요구합니다("<b>Parental Consent</b>"). 담당 교사 또는 교육자("<b>School Account Holder</b>")가 아동의 계정("<b>Sub-Account</b>")을 아동을 대신해 설정하는 있는 경우, 학교 계정 보유자는, 필요에 따라서, 아동의 부모/보호자에게 서면으로 탈퇴의 순서를 통지하는 것을 의무화하고 있습니다. DQ LAB이 만 13세 미만으로 판단되는 아동에게 속한 계정의 경우, 사이트에 등록시, 반드시 부모/보호자의 이메일 주소를 제공되어야 하며 부모/보호자가 부모의 동의가 제공되어야 계정이 활성화 됩니다. 이 이메일 주소를 통해 사용 약관, 개인정보 정책 및 보호자 동의 페이지에 대한 링크가 포함된 알림 메시지가 발송됩니다. 아동의 부모/보호자가 이 링크를 사용하여 동의를 얻을 때까지 아동의 등록 또는 요청은 완료되지 않은 것으로 간주됩니다.`,
    privacy_policy__p6_dqlab_recommends_parents: `DQ LAB은 부모/보호자가 아동의 온라인 활동을 모니터링하고 감독할 것을 권장합니다.`,
    privacy_policy__point_7: `7. 사용자가 생성한 콘텐츠를 포함한 공개 정보("UGC")`,
    privacy_policy__para_16: `댓글 섹션 및 게시판과 같이 일부 서비스를 통해 일반 대중이 액세스할 수 있는 공간에 텍스트와 정보를 입력할 수 있습니다. 이러한 공공 영역에 참여할 경우 이러한 공간을 통해 제출된 모든 정보는 전체 공개된다는 사실을 알길 바랍니다. 이때 개인정보를 공개하는 데 주의하시기 바랍니다. 귀하는 귀하가 서비스에 게시되거나 표시되거나 콘텐츠에 기여하거나 서비스의 다른 사용자에게 전송하는(이하, "<b>게시물</b>") 모든 콘텐츠에 대한 책임이 있습니다. 귀하의 UGC는 서비스의 다른 사용자에게 게시되고 전송될 위험이 있습니다. 귀하가 UGC를 게시하면 서비스의 모든 사용자가 해당 UGC에 액세스하고 사용할 수 있으며 귀하와 연결할 수 있습니다. 사진 및 프로필에 태그를 지정할 수 있는 기능을 제공하는 경우 사용자 또는 다른 사용자가 귀하의 프로필을 UGC에 태그할 수 있습니다. 어떤 보안 조치도 완벽할 수 없다는 점에 유의하시기 바랍니다.`,
    privacy_policy__para_17: `게시판의 잠재 사용자에 적합한 콘텐츠를 유지하기 위해 공개 게시판을 중재하지만, 당사는 귀하 또는 귀하의 자녀가 이러한 공개 게시판과 공개 도메인에서 제출하는 정보나 해당 정보와 관련하여 다른 사용자의 행동에 대해 책임을 지지 않습니다. 또한 귀하가 UGC를 공유하기로 선택할 수 있는 서비스의 다른 사용자의 행동을 통제할 수 없습니다. 따라서 당사는 귀하의 UGC가 승인되지 않은 사람이 볼 수 없음을 보장할 수 없습니다. 당사는 서비스에 포함된 개인정보 보호 설정 또는 보안 조치를 우회할 책임이 없습니다. 귀하는 제거 후에도 UGC의 복사본이 로컬 데이터로 보관되어 볼 수 있거나 서비스의 다른 사용자가 복사하거나 저장할 수 있음을 이해하고 인정합니다.`,
    privacy_policy__p7_you_acknowledge_that: `귀하는 귀하가 제출하거나 기여한 모든 UGC에 대한 책임이 있음을 인정하며, 모든 UGC는 이용약관 및 모든 관련 법률 및 규정을 준수해야 합니다.`,
    privacy_policy__point_8: `8. 본 개인정보 처리방침의 변경`,
    privacy_policy__para_18: `DQ LAB은 언제든지 본 개인정보 보호 정책 또는 기타 정책 또는 관행을 변경하거나 업데이트할 권리를 보유하며, 이 페이지에 변경되었거나 업데이트된 개인정보 보호정책을 게시하여 해당 사이트를 사용자에게 알립니다. 변경 사항이나 업데이트는 해당 사이트에 게시하는 즉시 효력이 있습니다. 이 개인정보 보호 고지의 변경이 처리의 특성에 근본적인 영향을 미치거나 귀하에게 상당한 영향을 미치는 경우, 당사는 귀하가 귀하의 권리를 행사할 수 있는 기회를 가질 수 있도록 충분한 사전 통지를 제공합니다(예, 처리에 대한 반대).`,
    privacy_policy__p8_may_req_a_copy: `귀하는 아래에 명시된 연락처 정보를 사용하여 본 개인정보 보호정책의 사본을 당사에 요청할 수 있습니다.`,
    privacy_policy__point_9: `9. 데이터 보안 및 진실성`,
    privacy_policy__para_19: `귀하의 정보의 보안, 진실성 및 기밀성은 당사에 매우 중요합니다. 당사는 개인정보를 무단 액세스, 수집, 공개, 사용, 복사, 수정, 폐기 또는 이와 유사한 위험으로부터 합리적으로 보호하기 위해 고안된 기술적, 행정적 및 물리적 보안 조치를 시행했습니다. 때때로 우리는 적절한 새로운 기술과 방법을 고려하기 위해 당사의 보안 절차를 검토합니다. 그러나 최선의 노력에도 불구하고 어떤 보안 조치도 완벽할 수 없다는 점에 유의하십시오.`,
    privacy_policy__para_20: `귀하의 개인정보의 안전성과 보안도는 귀하에 따라 다릅니다. 당사가 사이트의 특정 부분에 대한 액세스를 위해 귀하에게(또는 귀하가 선택한 경우) 암호를 제공한 경우, 귀하는 이 비밀번호를 기밀로 유지할 책임이 있습니다. 당사는 귀하가 누구와도 암호를 공유하지 않도록 요청합니다. 당사는 게시판과 같은 사이트의 공용 공간에서 개인정보를 제공하는 것에 주의할 것을 촉구합니다. 공용 공간에서 공유하는 정보는 사이트의 모든 사용자가 볼 수 있습니다.`,
    privacy_policy__para_21: `안타깝게도 인터넷을 통한 정보 전송은 완전히 안전하지는 않습니다. 당사는 귀하의 개인정보를 보호하기 위해 최선을 다하고 있지만 DQ LAB에 전송되는 귀하의 개인정보의 보안을 보장할 수는 없습니다. 스스로 선택에 따른 개인정보의 전송에 대한 책임은 귀하에 있습니다.`,
    privacy_policy__point_10: `10. 개인정보 보유`,
    privacy_policy__para_22: `당사는 당사가 처리하는 개인정보에 대한 위험에 적합한 수준의 보안을 보장하기 위해 기술적 및 조직적 조치를 구현합니다. 이러한 조치는 개인정보의 진실성과 기밀성을 보장하기 위한 것입니다. 우리는 처리의 보안을 보장하기 위해 정기적으로 이러한 조치를 평가합니다.`,
    privacy_policy__para_23: `당사는 귀하와 관계를 맺는 한 귀하의 개인정보를 보관합니다. 귀하와의 관계가 끝나면 당사는 다음과 같은 기간 동안 귀하의 개인정보를 보관할 것입니다.`,
    privacy_policy__p10_maintain_records: `분석 및/또는 감사 목적의 비즈니스 기록 유지 및 관리`,
    privacy_policy__p10_comply_with_record: `법률에 따른 기록 보존 요건 준수`,
    privacy_policy__p10_defend_or_bring: `기존 또는 잠재적 법적 청구에 대한 방어 및 문제 제기`,
    privacy_policy__p10_deal_with_complaints: `서비스에 관한 불만 처리`,
    privacy_policy__para_24: `당사는 이러한 목적을 위해 더 이상 필요하지 않을 때 귀하의 개인정보를 삭제합니다. 기술적 이유로 시스템에서 완전히 삭제할 수 없는 정보가 있는 경우, 당사는 데이터의 추가 처리 또는 사용을 방지하기 위한 적절한 조치를 취할 것입니다.`,
    privacy_policy__point_11: `11. 개인정보에 대한 권리`,
    privacy_policy__para_25: `귀하는 현지 법률에 따라 귀하의 개인정보에 관한 특정 권리를 가집니다. 권한은 다음과 같습니다.`,
    privacy_policy__p11_access_ps_info: `개인정보에 액세스`,
    privacy_policy__p11_rectify_info: `당사가 보유하고 있는 개인정보에 대한 수정`,
    privacy_policy__p11_erase_ps_info: `개인정보 삭제`,
    privacy_policy__p11_restict_use_of_ps_info: `개인정보 사용 제한`,
    privacy_policy__p11_objection_on_ps_info: `개인정보 사용 반대`,
    privacy_policy__para_26: `사용 가능한 전자 형식으로 귀하의 개인정보를 수신하고 제3자에게 전송(데이터 휴대성에 대한 권리)`,
    privacy_policy__p11_lodge_complaint: `현지의 데이터 보호 당국에 불만 제기`,
    privacy_policy__p11_contact_to_discuss_rights: `이러한 권리를 논의하거나 행사하려면 아래 세부 사항에 문의하시기 바랍니다.`,
    privacy_policy__para_27: `당사는 귀하의 개인정보가 변경되거나 부정확한 경우 업데이트하거나 수정하기 위해 당사에 문의할 것을 요청합니다.`,
    privacy_policy__p11_will_contact_for_additional_info: `귀하의 요청을 준수하기 위해 귀하의 추가 정보가 필요한 경우 당사는 귀하에게 연락할 것입니다.`,
    privacy_policy__point_12: `12. 국제 데이터 전송`,
    privacy_policy__para_28: `귀하의 개인정보는 유럽 위원회가 개인정보에 대한 적절한 수준의 보호를 보장하는 것으로 간주되지 않는 국가로 전송, 저장 및 처리 될 수 있습니다.`,
    privacy_policy__para_29: `당사는 귀하의 데이터가 적절하게 보호되는지 확인하기 위해 해당 법적 요구 사항에 따라 적절한 보호 장치(예, 계약 약정)를 시행했습니다. 적절한 안전 장치에 대한 자세한 내용은 아래 세부 사항에 문의하시기 바랍니다.`,
    privacy_policy__point_13: `13. 문의`,
    privacy_policy__p13_dqlab_relevant_data: `DQ LAB은 당사가 수집하고 처리하는 개인정보에 대한 책임이 있는 관련 데이터 관리자입니다.`,
    privacy_policy__para_30: `당사의 유럽 대표 문의처는 아래와 같습니다. <br>activeMind.legal <br>Rechtsanwaltsgesellschaft m.b. H <br>Potsdamer Straße 3 <br>80802 München, Germany <br>Phone: +49 (0)89 / 418 560 170 <br>Email: <a href="mailto:eu-dataprotection@dqforall.com">eu-dataprotection@dqforall.com</a>`,
    privacy_policy__para_31: `본 개인정보 보호정책에 대한 의견이나 질문이 있는 경우 게스트 서비스<a href="mailto:dpo@dqforall.com">dpo@dqforall.com</a>로 문의하시기 바랍니다. 이메일 수신 확인을 위해 14일 이내에 연락을 드립니다. 귀하의 이메일을 받은 후, 당사는 귀하의 우려 또는 불만 사항을 조사하고 합리적인 기간 내에 당사의 답변을 제공하기 위해 노력할 것입니다.`,
    privacy_policy__para_32: `당사는 귀하와 협력하여 개인정보 보호에 대한 불만이나 우려사항을 공정하게 해결하기 위해 최선을 다하고 있습니다. 그러나 불만이나 우려 사항을 지원할 수 없다고 판단하는 경우, 귀하는 귀하가 거주하는 국가의 데이터 보호 당국에 불만을 제기할 권리가 있습니다.`,
}


export const LC_VALUES_PRIVACY_POLICY = {
    'en': lc_en,
    'ko': lc_ko
}

const validate_keys_syncing = () => {
    const en_keys_count = Object.keys(lc_en).length;
    const ko_keys_count = Object.keys(lc_ko).length;
    if (en_keys_count === ko_keys_count) {
        console.log('lc_values::privacy_policy:: keys are in sync');
    } else {
        console.log('lc_values::privacy_policy:: FATAL ISSUE : keys not in sync');
    }
}

validate_keys_syncing();