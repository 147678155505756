import React, {useEffect, useState, useRef} from 'react';
import { withRouter } from "react-router";
import ListItem from './components/ListItem.comp';
import ListItemDetail from './components/ListItemDetail.comp';
import TopSection from './components/TopSection.comp';
import Footer from "../../components/Footer/plugable.footer";
import {useGlobalState} from "../../context/global_state";
import {appStartService, getProfileService} from "../../services/home.service";
import {DEFAULT_LOCALE, FREE_TESTS, LOCAL_STORAGE_KEYS, QUIZ_TIME_MODES} from "../../utils/constants";
import {getMapFromObjectListByKey} from "../../utils/utils";
import { Animate, AnimateGroup } from "react-simple-animate";
import {useAuth} from "../../context/auth";



const Home = (props) => {

	const {history, location} = props;

	const { authUser } = useAuth();

	const [badgeSelected, setBadgeSelected] = useState(-1);
	const [badgesUIC, setBadgesUIC] = useState(undefined);
	const [areasUIC, setAreasUIC] = useState(undefined);
	const [areasUICMap, setAreasUICMap] = useState(undefined);
	const [totalScore, setTotalScore] = useState(0);

	const [loadItemsAnim, setLoadItemsAnim] = useState(false);

	const { setToastShow, setToastMessage, profile, setProfile } = useGlobalState();

	const pageLc = location.pathname.split('/')[1];
	const is_first_boot = useRef(false);

	const getItemsLoadWaitTime = () => {
		if (is_first_boot.current) {
			return 1500;
		} else {
			return 0;
		}
	}

	const getListItemAnimDuration = () => {
		if (is_first_boot.current) {
			return 0.25;
		} else {
			return 0;
		}
	}

	useEffect( () => {
		if (loadItemsAnim) {
			if (is_first_boot.current) {
				// turn it off after and approx time, totalling to all the animations
				// current implementation, turning off in 7 seconds
				setTimeout( () => {
					localStorage.removeItem(LOCAL_STORAGE_KEYS.FIRST_TIME_BOOT);
					is_first_boot.current = false;
				}, 5000 )
			}
		}
	}, [loadItemsAnim] )

	useEffect(  () => {

		is_first_boot.current = localStorage.getItem(LOCAL_STORAGE_KEYS.FIRST_TIME_BOOT)
		fetchData().then(r => console.log('data loaded'));

		setTimeout( ()=>{
			setLoadItemsAnim(true)
		}, getItemsLoadWaitTime() )

		const params = new URLSearchParams(location.search);
		const reload_profile = params.get('reload_profile');
		if (reload_profile && reload_profile === '1') {
			reloadProfile().then(r=>console.log('profile reloaded at page boot time'))
		}
	}, [])

	useEffect( ()=>{

		console.log('current loaded profile ==> ', profile);

		// update the profile org in localStorage :: for app_start (landing-page) logic
		if (profile && profile.org_code) {
			localStorage.setItem(LOCAL_STORAGE_KEYS.ORG_CODE_OF_DB_PROFILE, profile.org_code)
		}

		let finished_tests = 0;
		let net_score = 0;
		const _badges = (profile && profile.badges) ? profile.badges : {}
		for (let [key, _badge] of Object.entries(_badges)) {
			if (_badge.finished) {
				const sc = parseFloat(_badge.obtained_score);
				if (sc > 0) {
					finished_tests ++;
					net_score += sc;
				}
			}
		}
		// if (finished_tests > 0) {
		// 	const score_avg = net_score / finished_tests
		// 	setTotalScore(score_avg);
		// }
		if (profile && profile.dq_avg_score) {
			setTotalScore(profile.dq_avg_score)
		}
		if (profile && profile.last_finished_badge) {
			// console.log('profile.last_finished_badge --> ', profile.last_finished_badge);
			if (badgesUIC && badgesUIC.length > 0) {
				for (let i=0; i<badgesUIC.length; i++) {
					if (profile.last_finished_badge === badgesUIC[i].badge_code) {
						if (!authUser.is_guest_user) {
							setBadgeSelected(badgesUIC[i].id);
						}
					}
				}
				setProfile({
					...profile,
					last_finished_badge: undefined
				})
			}
		}

		if (profile) {
			console.log('profile.last_finished_badge --> ', profile);
			if (profile.badges) {
				if (Object.values(profile.badges).length > 0) {
					for (let badge of Object.values(profile.badges)) {
						if (badge.progress === 100) {
							history.push(`/take/quiz/${QUIZ_TIME_MODES.RESUME_MODE}/${badge.resume_ref}`);
						}
					}
				} else {
					// land user into free assessment only if it's not an org-user
					if (profile.is_demographic_updated && process.env.REACT_APP_FREE_TEST_ACTIVE === '1') {
						if (!profile.is_org_provide) {
							history.push(`/take/quiz/${QUIZ_TIME_MODES.START_MODE}/${FREE_TESTS.SimpleDQTest.badge(pageLc).badge_code}`);
						}
					}

				}
			}
		}
	}, [profile] )

	const fetchData = async () => {

		const listCacheTimeLimit = 30; // 30 seconds

		const lastCacheTime = localStorage.getItem(LOCAL_STORAGE_KEYS.HOME_APP_START_CACHE_TIME);
		const cachedAppStart = localStorage.getItem(LOCAL_STORAGE_KEYS.HOME_APP_START_API_RESPONSE);

		let fetchList = false;

		if (!cachedAppStart) {
			fetchList = true;
		}

		if (!lastCacheTime) {
			localStorage.setItem(LOCAL_STORAGE_KEYS.HOME_APP_START_CACHE_TIME, Date.now());
		} else {
			const diff_in_sec = (Date.now() - lastCacheTime) / 1000;
			if (diff_in_sec > listCacheTimeLimit) {
				localStorage.setItem(LOCAL_STORAGE_KEYS.HOME_APP_START_CACHE_TIME, Date.now());
				fetchList = true;
			}
		}

		let api_data;
		if (fetchList) {
			try {
				const res = await appStartService();
				api_data = res.data;
				localStorage.setItem(LOCAL_STORAGE_KEYS.HOME_APP_START_API_RESPONSE, JSON.stringify(res.data));

				setProfile({
					...profile,
					...api_data.profile
				});
			} catch (e) {
				setToastShow(true);
				setToastMessage(e.message);
			}
		} else {
			await reloadProfile();

			api_data = JSON.parse(cachedAppStart);
		}

		if (api_data) {
			let area_uic_data;
			if (authUser.is_guest_user) {
				area_uic_data = []
			} else {
				area_uic_data = api_data.ui_config.areas_uic;
			}

			// FREE TEST AREA ADDITION
			if (process.env.REACT_APP_FREE_TEST_ACTIVE === '1' &&
				(authUser.is_guest_user || profile.org_code.toUpperCase().trim() === 'DQ')) {
				let pre_exist = false;
				const area_code_tobe_add = FREE_TESTS.SimpleDQTest.area.area_code;
				for (let item of area_uic_data) {
					if (item.area_code === area_code_tobe_add) {
						pre_exist = true;
					}
				}
				if (!pre_exist) {
					area_uic_data.push(FREE_TESTS.SimpleDQTest.area);
				}
			}

			const _areas_uic_data_map = getMapFromObjectListByKey(area_uic_data, 'area_code');
			setAreasUIC(area_uic_data);
			setAreasUICMap(_areas_uic_data_map);

			const _badges_meta = getMapFromObjectListByKey(api_data.meta_data.badges_meta, 'code');
			let _badges_uic_data = [];

			// FREE TEST BADGE ADDITION
			if (process.env.REACT_APP_FREE_TEST_ACTIVE === '1' &&
				(authUser.is_guest_user || profile.org_code.toUpperCase().trim() === 'DQ')) {
				_badges_uic_data.push(FREE_TESTS.SimpleDQTest.badge(pageLc));
			}

			for (let a_uic of area_uic_data) {
				for (let uic of api_data.ui_config.badges_uic) {
					if (uic.area_code.trim() === a_uic.area_code.trim()) {
						let item = {
							...uic
						}
						item.name = uic.badge_code;
						if (_badges_meta[item.badge_code]) {
							item.name = _badges_meta[item.badge_code].app_badge_name;
							item.desc = _badges_meta[item.badge_code].app_badge_desc;
						}
						_badges_uic_data.push(item);
					}
				}
			}
			setBadgesUIC(_badges_uic_data);

			// auto-open last finish badge
			if (profile && profile.last_finished_badge) {
				for (let i=0; i<_badges_uic_data.length; i++) {
					if (profile.last_finished_badge === _badges_uic_data[i].badge_code) {
						if (!authUser.is_guest_user) {
							setBadgeSelected(_badges_uic_data[i].id);
						}
					}
				}
				setProfile({
					...profile,
					last_finished_badge: undefined
				})
			}
		}

	}

	const reloadProfile = async () => {
		try {
			const res = await getProfileService();
			setProfile({
				...profile,
				...res.data.profile
			});
		} catch (e) {
			setToastShow(true);
			setToastMessage(e.message);
		}
	}

	const itemClickAction = (id, badge) => {
		if (id === badgeSelected) {
			setBadgeSelected(-1)
		} else {
			setBadgeSelected(id);
		}

		const curr_badge_state = profile.badges[badge.badge_code];
        if (curr_badge_state) {
            if (curr_badge_state.in_progress) {
                history.push(`/take/quiz/${QUIZ_TIME_MODES.RESUME_MODE}/${curr_badge_state.resume_ref}`);
            }
        } else {
            history.push(`/take/quiz/${QUIZ_TIME_MODES.START_MODE}/${badge.badge_code}`);
        }
	}

	return (
		<div className={'home-container'}>
			<TopSection
				score={totalScore}
				all_close={badgeSelected === -1}
				history={history}
				is_first_boot={localStorage.getItem(LOCAL_STORAGE_KEYS.FIRST_TIME_BOOT)}
			/>
			<div className={'home-badges-section-full'}>
			{

				badgesUIC && loadItemsAnim && (
					badgesUIC.length > 0 ?
					<AnimateGroup play={loadItemsAnim}>
						{
							badgesUIC.map((badge, index) => {
								return <div
									key={`home-anim0-${index}`}
								>
									<Animate
										duration={getListItemAnimDuration()}
										start={{ opacity: 0 }}
										end={{ opacity: 1 }}
										sequenceIndex={index}
									>
										<ListItem
											badge={badge}
											areas={areasUICMap}
											profile={profile}
											selectItem={itemClickAction}
										/>
									</Animate>

									<ListItemDetail
										history={history}
										areas={areasUICMap}
										badge={badge}
										selected={badgeSelected}
										profile={profile}
									/>
								</div>
							})
						}
					</AnimateGroup> : <h3
							style={{
								textAlign: 'center',
								margin: '15px'
							}}
						>NO Assessments available at the moment, Please check again later</h3>
				)
			}
			</div>
			{/* <Footer /> */}
		</div>
	);
}

export default withRouter(Home);
