import React, {useEffect, useState} from 'react';
import {useGlobalState} from "../../../context/global_state";
import { withRouter } from "react-router";
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";

import dqIcon from '../../../assets/icons/dq-icons/dq-logo.png';
import {LOCAL_STORAGE_KEYS} from "../../../utils/constants";
import {useLocation} from "react-router-dom";
import {GET_APP_LOCALIZE_TEXT} from "../../../utils/dq_lc_service/lc_service";

const ContactUs = (props) => {

    const { onSwitch } = props;

    const page_location = useLocation();
    const pageLc = page_location.pathname.split('/')[1];

    const [loading, setLoading] = useState(false);

    const [formName, setFormName] = useState('');
    const [formEmail, setFormEmail] = useState('');
    const [formMessage, setFormMessage] = useState('');

    const { setToastShow, setToastMessage, setProfile } = useGlobalState();

    useEffect( () => {

    }, [] );

    const sendAction = async () => {


    }


    const onChangeEmail = (e) => {
        setFormEmail(e.target.value);
    }

    const onChangeName = (e) => {
        setFormName(e.target.value);
    }

    const onChangeMessage = (e) => {
        setFormMessage(e.target.value);
    }

    return (
        <div className={'login-container'}>

            <LoaderWithBackDrop
                loading={loading}
            />

            <div className="dq-logo-image"><img src={dqIcon} alt='DQ'/></div>
            <div className="dq-login-content">
                <form onSubmit={e => e.preventDefault()}>
                    <div>
                        <input
                            className={'dq-login-txt'}
                            type='password'
                            name={'name'}
                            id={'name'}
                            value={formName}
                            placeholder={"Name *"}
                            required={true}
                            onChange={onChangeName}
                        />
                    </div>
                    <div>
                        <input
                            className={'dq-login-txt'}
                            type='text'
                            name={'email'}
                            id={'email'}
                            value={formEmail}
                            placeholder={"Email *"}
                            required={true}
                            onChange={onChangeEmail}
                        />
                    </div>
                    <div>
                        <textarea
                            className={'dq-login-txt'}
                            type='text'
                            name={'message'}
                            id={'message'}
                            value={formMessage}
                            placeholder={"Message *"}
                            required={true}
                            onChange={onChangeMessage}
                        />
                    </div>
                </form>
            </div>
            
            <div className={'dq-options-submit'}>
                <button onClick={sendAction}>Send</button>
            </div>
        </div>
    );

}

export default withRouter(ContactUs);