import React from 'react';

const BadgeListItem = (props) => {

    const { item, areaData, selectItem } = props;

    const renderURL = (url) => {
        if (url.substring(0,8) === 'https://') {
            return url
        }
        if (url.substring(0,7) === 'http://') {
            return url
        }
        return `${process.env.REACT_APP_MYDQ_API}${url}`
    }

    return(
        <div
            className={'stats-list-container'}
            style={{
                backgroundColor: areaData[item.area_code].theme_color,
                color: areaData[item.area_code].primary_color,
                // borderLeft: `5px solid ${areaData[item.area_code].badge_background}`,
                borderTop: `1px solid #fff`
            }}
            onClick={()=>selectItem(item.id)}
         >
            <div
                className={'stats-list-title-section'} >
                <img className={'stats-list-item-img'} src={ renderURL(areaData[item.area_code].icon_url) } />
                <p>{areaData[item.area_code].full_name}</p>
            </div>
        </div>
    )
}

export default BadgeListItem;