
const lc_en = {
    home__dq_index: `DQ Index`,
    home__dq_assessment: `DQ Assessment`,
    home__take_the_test: `Take the Test`,
    home__global_project_to_measure: `Global Project to Measure the Digital Skills Level`,
    home__realtime_tracker: `Real-time Tracker for Digital Skills`,
    home__disclaimer_1: `Disclaimer: The above is for illustrative purposes only and is not indicative of a country’s level of digital skills or anything related.`,
    home__read_press_release: `Read the Press Release`,
    home__measure_digi_skills: `Measure the Digital Skills Level of your Country`,
    home__dqindex_intro_title: `The <a href="www.dqinstitute.org">DQ Institute</a> is starting a new global collaborative project to develop the DQ Index, a global tracker for digital skills.`,
    home__disc_about_cookies: `We use cookies to deliver our online services and to make interactions with our online services easy and meaningful. By continuing to use our site, you agree to our cookie policy. Details of the cookies we use and instructions on how to disable them are set out in our`,
    home__cookies_piolicy_link: `<a href="/cookies" target="_blank">Cookies Policy</a>`,
    home__project_measure_digi_skill_level: `Global Project to Measure the Digital Skills Level`,
    home__realtime_tracker_for_digi_skills: `Real-time Tracker for Digital Skills`,
    home__read_press_release_br: `Read the <br>Press Release`,
    home__disclaimer_1_br: `Disclaimer: The above is for illustrative purposes only and is not <br>indicative of a country’s level of digital skills or anything related.`,
    home__measure_digi_skill_lvl_of_country: `Measure the Digital Skills Level of your Country`,
    home__dq_is_starting_new_collab_project: `The <a href="https://www.dqinstitute.org/" target="_blank">DQ Institute</a> is starting a new global collaborative project to develop the DQ Index, a global tracker for digital skills.`,
    home__covid19_pushed_world_message: `COVID-19 has pushed the world into fast digitization, where digital skills are now a must-have for every individual.`,
    home__ieee_digiskill_beyond_tech_competency: `“<strong>Digital skills</strong>” go beyond technical competency such as coding and computer operations. In accordance with the <a href="https://standards.ieee.org/ieee/3527.1/7589/" target="_blank">IEEE digital intelligence (DQ) Global Standards (IEEE 3527.1™)</a>, “digital skills” are defined as a comprehensive set of competencies needed to thrive in the digital age.`,
    home__based_on_un_sustainable_dev_goals: `Based on its <a href="https://www.dqinstitute.org/collaborative-rd/" target="_blank">collaborative R&amp;D platform</a> that aggregates different frameworks, researches, and programs, the DQ Index develops a set of standardized metrics that tracks how countries are progressing in terms of the following six digital skills that are aligned with the UN Sustainable Development Goals.`,
    home__global_tracker_for_digi_skills: `Global Tracker for Digital Skills`,
    home__digi_skills_child_safety: `Digital Skills for <span>Child Online Safety</span>`,
    home__protect_all_children: `Protect all children from cyber-risks and harms and ensure digital citizenship skills for all children`,
    home__digi_skills_learning: `Digital Skills for <span>Learning</span>`,
    home__ensure_quality_statement_1: `Ensure quality and comprehensive digital skill education and promote life-long opportunities for all`,
    home__digiskills_innovation: `Digital Skills for <span>Work &amp; Innovation</span>`,
    home__ensure_digital_skilling: `Ensure digital skilling/upskilling of all workforces, promote decent, and productive digital workplace, at all levels, and foster science, technology, and innovation`,
    home__digital_skills_for_female_empowerment: `Digital Skills for <span>Female Empowerment</span>`,
    home__achieve_digital_gender_equity: `Achieve digital gender equity and empower all women and girls through enabling technology`,
    home__digital_skills_for_well_being: `Digital Skills for <span>Well-being</span>`,
    home__promote_wellbeing_in_digital_lives: `Promote wellbeing in digital lives, reduce inequality, and promote safe, trustful, and peaceful digitized societies for all, at all levels`,
    home__digital_skills_for_online_participation: `Digital Skills for <span>Online Participation</span>`,
    home__ensure_universal_access_by_all_people: `Ensure universal and inclusive access by all people, in particular the people in vulnerable situations including seniors and people with special needs, to the Internet and to participation in the digital economy`,
    home__take_action: `Take Action`,
    home__we_are_inviting_those_who_share_the_common_goal: `We are inviting those who share the common goal of closing the digital skills gap to join the DQ Index project. We monitor and track global progress in achieving the goal by measuring our collective impact through the DQ Index.`,
    home__safer_internet_day_february_8_2022: `Safer Internet Day<br> (February 8, 2022)`,
    home__digital_citizenship_test_dct: `Digital Citizenship Test (DCT)`,
    home__dct_was_made_available_to_the_public: `DCT was made available to the public so that all organizations and individuals can use it to understand how ready they are to use technology safely.`,
    home__download_the_test_kit: `Download the <br>Test Kit`,
    home__tbd: `TBD`,
    home__dq_index_launch: `DQ Index Launch <br>&amp; Co-Creation`,
    home__the_dq_index_platform_will_be_launched: `The DQ Index platform will be launched on a later date in 2022 while we invite partners to join the collaborative R&amp;D project.`,
    home__join_the_collaborative: `Join the <br>Collaborative R&amp;D`,
    home__dq_day_october_10_2022: `DQ Day <br> (October 10, 2022)`,
    home__2022_dq_conference: `2022 DQ Conference`,
    home__dq_conference_provides_learning_experiences: `DQ Conference provides learning experiences to the global community about digital skills. The conference will include discussions on the DQ Index, case studies and more.`,
    home__if_you_interested_in_becoming_a_sponsor: `If you’re interested in becoming a sponsor,  speaker, or participant, please register below.`,
    home__register_for_the_conference: `Register for <br>the Conference`,
    home__privacy_policy: `Privacy Policy`,
    home__dq_lab_pte_ltd_all_rights_reserved: `DQ LAB Pte Ltd. All Rights Reserved.`,
    home__language: `Language`,
    home__term_of_use: `Term of Use`,
    home__email: `Email`,
    home__general_enquiries: `General Enquiries`,
    home__social_media: `Social Media`,
    assessment__find_out_what_type_of_digital_citizen_you_are: `Find out <br>what type of digital citizen you are`,
    assessment__take_the_free_test: `Take The Free Test`,
    assessment__5_types_of_digital_citizen: `5 Types of Digital Citizen`,
    assessment__based_on_statement_misc_1: `Based on <a href="https://www.dqinstitute.org/" target="_blank">DQ Institute's</a> global databank, we have identified 5 different types of digital citizens showing different patterns of skills, technology usage, and cyber-risk exposure.`,
    assessment__carefree: `CAREFREE`,
    assessment__feels_more_comfortable_online_than_offline: `Feels more comfortable online than offline`,
    assessment__potentially_involved_with_various_cyber_risks: `Potentially involved with various cyber-risks`,
    assessment__ordinary: `ORDINARY`,
    assessment__average_technology_user_who_not_fully_aware: `Average technology user who may not be fully aware of the risks nor opportunities`,
    assessment__tend_to_passively_follow_the_algorithm: `Tend to passively follow the algorithm`,
    assessment__always_on: `ALWAYS ON`,
    assessment__likely_communicate_with_others_a_lot_online: `Likely communicate with others a lot online`,
    assessment__may_not_consider_much_about_risks: `May not consider much about potential cyber-risks`,
    assessment__cautious: `CAUTIOUS`,
    assessment__being_aware_of_potential_cyber_risks: `Being aware of potential cyber-risks and not fully exploring the digital world`,
    assessment__likely_having_low_risks_in_the_digi_world: `Likely having low risks in the digital world`,
    assessment__digilog: `DIGILOG`,
    assessment__well_rounded_digital_citizen: `Well-rounded digital citizen who balances online and offline lives`,
    assessment__uses_technology_mindfully: `Uses technology mindfully`,
    assessment__low: `Low`,
    assessment__medium: `Medium`,
    assessment__high: `High`,
    assessment__skills: `Skills`,
    assessment__usage: `Usage`,
    assessment__risks: `Risks`,
    assessment__be_yourself_and_answer_honestly: `Be yourself and answer honestly to find out your digital citizen type and level.`,
    assessment__take_the_test: `Take the Test`,
    assessment__digital_citizenship_test_and_dq_index: `Digital Citizenship Test and DQ Index`,
    assessment__the_level_of_digital_citizenship_is: `The level of <span class="isection-toolip">digital citizenship<div class="tooltip-content">Digital citizenship is a skill set required to use technology safely. As the first step of the IEEE digital intelligence (DQ) Global Standards (IEEE 3527.1™), digital citizenship is considered as a prerequisite for other digital skills, such as coding, cyber security, and other job-readiness technical skills.</div></span> in a country is a strong sign whether their DQ Index score is high or not.`,
    assessment__based_on_years_of_research_desc: `Based on years of research, the DQ Institute developed a set of questions that effectively evaluates the digital citizenship level of individuals, which is called the Digital Citizenship Test (DCT).`,
    assessment__connected_with_the_dq_index_desc: `Connected with the DQ Index, the results from the DCT are immediately updated to the index in order to showcase where each country stands. This allows the countries to effectively utilize resources and to inclusively improve their digital skill levels, creating a positive impact.`,
    assessment__digital_citizenship_is_a_skill_desc: `Digital citizenship is a skill set required to use technology safely. As the first step of the IEEE digital intelligence (DQ) Global Standards (IEEE 3527.1™), digital citizenship is considered as a prerequisite for other digital skills, such as coding, cyber security, and other job-readiness technical skills.`,
    assessment__digital_citizenship_scores: `Digital Citizenship Scores`,
    assessment__global: `Global`,
    assessment__australia: `Australia`,
    assessment__cambodia: `Cambodia`,
    assessment__china: `China`,
    assessment__colombia: `Colombia`,
    assessment__dominican_republic: `Dominican Republic`,
    assessment__ecuador: `Ecuador`,
    assessment__el_salvador: `El Salvador`,
    assessment__hong_kong: `Hong Kong`,
    assessment__india: `India`,
    assessment__indonesia: `Indonesia`,
    assessment__ireland: `Ireland`,
    assessment__japan: `Japan`,
    assessment__korea_republic_of: `Korea, Republic Of`,
    assessment__malaysia: `Malaysia`,
    assessment__mexico: `Mexico`,
    assessment__new_zealand: `New Zealand`,
    assessment__nigeria: `Nigeria`,
    assessment__peru: `Peru`,
    assessment__philippines: `Philippines`,
    assessment__qatar: `Qatar`,
    assessment__singapore: `Singapore`,
    assessment__south_africa: `South Africa`,
    assessment__spain: `Spain`,
    assessment__sweden: `Sweden`,
    assessment__thailand: `Thailand`,
    assessment__turkey: `Turkey`,
    assessment__united_kingdom: `United Kingdom`,
    assessment__united_states: `United States`,
    assessment__viet_nam: `Viet Nam`,
    assessment__n_a_text: `N/A`,
    assessment__national_dq: `National DQ`,
    assessment__global_dq: `Global DQ`,
    assessment__percent_of_participants: `% of Participants`,
    assessment__digital_citizenship_score: `Digital Citizenship Score`,
    assessment__distribution_of_dcs: `Distribution of DCS (Digital Citizenship Score)`,
    assessment__about_dq_assessments: `About DQ Assessments`,
    assessment__forall: `forALL`,
    assessment__available_for_all_age_groups_and_types: `Available for all age groups and types`,
    assessment__any_system: `Any System`,
    assessment__whether_you_looking_to_integrate: `Whether you’re looking to integrate into your existing system or want an out of the box solution, we can make it happen.`,
    assessment__dq_index: `DQ Index`,
    assessment__scores_are_immediately_updated_into: `Scores are immediately updated into the <a href="/">DQ Index</a> for national and global comparisons`,
    assessment__dq_assessment_was_developed_as: `DQ Assessment was developed as a web/mobile-based platform providing assessment &amp; analytics tools to support digital skills education/training programs to measure digital skills for individuals and organizations based on global benchmarks. It will enable:`,
    assessment__1_common_assessments_that_measure: `1) Common assessments that measure the levels of multiple competencies of digital intelligence of individuals and organizations against global standards with global benchmarks`,
    assessment__2_in_depth_analysis_to_the_end_users: `2) In-depth analysis to the end users (e.g., individuals and organizations) to identify their strengths and weaknesses through global benchmark`,
    assessment__3_customized_digital_skill_development: `3) Customized digital skill development for individuals and organizations to help design customized lifelong training`,
    assessment__dq_assessment_tools_are_developed_based_on: `DQ Assessment tools are developed based on collection of the various assessment instruments from various credible sources and existing research projects of content owner partners.`,
    assessment__toolkits: `Toolkits`,
    assessment__whether_it_be_your_school_company_or: `Whether it be your school, company or country, we have a variety of assessments that helps in measuring your digital citizenship skills based on global benchmark.`,
    assessment__if_you_looking_to_create_a_customized: `If you’re looking to create a customized assessment tool (white-labeled) or in-depth analytic services, or need more information, please <a href="mailto:partnership@dqinstitute.org">contact us</a>.`,
    assessment__primary_schools: `PRIMARY<br>SCHOOLS`,
    assessment__schools_can_get_their_students_ages_8_12: `Schools can get their students ages 8-12 to take a more comprehensive digital citizenship test to find out their digital citizenship levels.`,
    assessment__measures_dq_1_8: `Measures DQ 1-8`,
    assessment__analytics_dashboard_included_for_teachers: `Analytics Dashboard included for teachers`,
    assessment__digital_parenting: `Digital Parenting`,
    assessment__cyber_risk_assessment: `Cyber-Risk Assessment`,
    assessment__learn_more: `Learn More`,
    assessment__secondary_schools: `SECONDARY<br>SCHOOLS`,
    assessment__schools_can_get_their_teenage_students_ages_13_18: `Schools can get their teenage students ages 13-18 to take a more comprehensive digital citizenship test to find out their digital citizenship levels.`,
    assessment__companies_corporates_span: `COMPANIES`,
    assessment__companies_and_organizations_can_assess: `Companies and organizations can assess their employees’ digital citzenship skills and identify areas of needed growth.`,
    assessment__dashboard_included_for_hr: `Analytics Dashboard included for HR staff`,
    assessment__national_packages: `NATIONAL<br/>PACKAGES`,
    assessment__countries_and_govt_localize_assess: `Countries and governments can measure their country’s DQ with customized and localized assessments.`,
    assessment__customize_assessment: `Customized assessments`,
    assessment__localized_for_your_country: `Localized/translated for your country`,
    assessment__consult_strategies_plans: `Consult, strategize, and co-develop of roll-out plans`,
    assessment__balance_use_of_tech: `Balanced Use of Technology`,
    assessment__behavioural_risk_management: `Behavioural Cyber-Risk Management`,
    assessment__digital_empathy: `Digital Empathy`,
    assessment__dc_identity: `Digital Citizen Identity`,
    assessment__ps_cyber_sec_management: `Personal Cyber Security Management`,
    assessment__media_and_info_literacy: `Media and Information Literacy`,
    assessment__digi_footprint_management: `Digital Footprint Management`,
    assessment__privacy_management: `Privacy Management`,
    assessment__read_press_release: `Read the <br>Press Release`,
}


const lc_ko = {
    home__dq_index: `DQ 인덱스`,
    home__dq_assessment: `DQ 평가`,
    home__take_the_test: `무료 테스트하기`,
    home__global_project_to_measure: `디지털 역량 수준을 측정하는 글로벌 프로젝트`,
    home__realtime_tracker: `디지털 역량의 실시간 트래커`,
    home__disclaimer_1: `주의: 위는 단지 이해를 돕기 위한 것이며 국가의 디지털 역량 수준 혹은 그에 대한 관련 사항을 나타내지 않습니다.`,
    home__read_press_release: `보도자료 읽기`,
    home__measure_digi_skills: `국가의 디지털 역량 수준 측정하기`,
    home__dqindex_intro_title: `<a href="www.dqinstitute.org">DQ 연구소</a>는 전 세계 나라의 디지털 역량 수준을 글로벌 표준에 의거하여 객관적으로 추적하고, 평가하기 위해 새로운 글로벌 협업 프로젝트인 DQ 인덱스를 시작합니다.`,
    home__disc_about_cookies: `당사는 쿠키를 사용하여 온라인 서비스를 제공하고 온라인 서비스와의 상호작용을 쉽고 의미 있게 만듭니다. 당사 사이트를 계속 사용함으로써 귀하는 당사의 쿠키 정책에 동의합니다. 당사가 사용하는 쿠키의 세부 사항 및 쿠키를 사용하지 않도록 설정하는 방법에 대한 지침은 당사에 명시되어 있습니다.`,
    home__cookies_piolicy_link: `<a href="/cookies" target="_blank">쿠키 정책</a>`,
    home__project_measure_digi_skill_level: `디지털 역량 평가를 위한 글로벌 프로젝트`,
    home__realtime_tracker_for_digi_skills: `디지털 역량 측정을 위한 실시간 트래커`,
    home__read_press_release_br: `보도자료<br>읽기`,
    home__disclaimer_1_br: `주의: 위는 단지 이해를 돕기 위한 것이며 국가의 디지털 역량 수준 혹은 그에 대한 관련 사항을 나타내지 않습니다.`,
    home__measure_digi_skill_lvl_of_country: `국가의 디지털 역량 수준 측정하기`,
    home__dq_is_starting_new_collab_project: `<a href="https://www.dqinstitute.org/" target="_blank">DQ 연구소</a>는 전 세계 나라의 디지털 역량 수준을 글로벌 표준에 의거하여 객관적으로 추적하고, 평가하기 위해 새로운 글로벌 협업 프로젝트인 DQ 인덱스를 시작합니다.`,
    home__covid19_pushed_world_message: `COVID-19로 인해 빠르게 진행된 디지털 대전환으로 인해 이제 디지털 역량은 모든 개인에게 반드시 필요한 역량이 되었습니다.`,
    home__ieee_digiskill_beyond_tech_competency: `<strong>디지털 역량</strong>은 단순히 코딩이나 컴퓨터나 디지털 기기를 쓰는 기술적 역량이 아닙니다. <a href="https://standards.ieee.org/ieee/3527.1/7589/" target="_blank">IEEE 디지털 지능(DQ) 글로벌 표준(IEEE 3527.1™)에 따라</a>, "디지털 역량"은 디지털 시대에 성공하는 데 필요한 포괄적 역량으로 정의됩니다.`,
    home__based_on_un_sustainable_dev_goals: `DQ 인덱스는 전 세계의 다양한 프레임워크, 연구 및 프로그램을 집계하는 <a href="https://www.dqinstitute.org/collaborative-rd/" target="_blank">협업 플랫폼</a>을 기반으로 유엔의 '지속 가능한 개발 목표'에 따라 6가지 디지털 역량의 관점에서 국가가 어떻게 디지털 역량을 향상시키고 있는지를 추적하는 표준화된 지표입니다.`,
    home__global_tracker_for_digi_skills: `디지털 역량 측정을 위한 글로벌 트래커`,
    home__digi_skills_child_safety: `<span>아동 온라인 안전</span>을 위한 디지털 역량`,
    home__protect_all_children: `모든 어린이를 사이버 위험과 유해요소로부터 보호하고 모든 어린를 위한 디지털 시민의식 역량을 보장합니다.`,
    home__digi_skills_learning: `<span>학습</span>을 위한 디지털 역량`,
    home__ensure_quality_statement_1: `양질의 포괄적인 디지털 역량 교육을 보장하고 모든 사람들에게 평생 교육 기회를 촉진합니다.`,
    home__digiskills_innovation: `<span>일과 혁신</span>을 위한 디지털 역량`,
    home__ensure_digital_skilling: `모든 인력의 디지털 역량의 증진을 보장하고, 모든 수준에서 훌륭하고 생산적인 디지털 업무 공간을 홍보하며, 과학, 기술 및 혁신을 촉진합니다.`,
    home__digital_skills_for_female_empowerment: `<span>여성 역량 강화</span>를 위한 디지털 역량`,
    home__achieve_digital_gender_equity: `디지털 성 평등을 달성하고 모든 여성들에게 기술을 사용할 수 있도록 합니다.`,
    home__digital_skills_for_well_being: `<span>웰빙</span>을 위한 디지털 역량`,
    home__promote_wellbeing_in_digital_lives: `디지털 생활에서 웰빙을 촉진하고, 불평등을 줄이며, 안전하고 신뢰할 수 있으며 평화로운 디지털화된 사회를 모든 수준에서 촉진합니다.`,
    home__digital_skills_for_online_participation: `<span>온라인 참여</span>를 위한 디지털 역량`,
    home__ensure_universal_access_by_all_people: `모든 사람, 특히 특별한 도움이 필요한 노인과 사람들을 포함한 취약한 상황에 있는 사람들, 인터넷 및 디지털 경제에 참여할 수 있도록 보편적이고 포괄적인 접근을 보장합니다.`,
    home__take_action: `참여하기`,
    home__we_are_inviting_those_who_share_the_common_goal: `디지털 역량 격차를 해소하겠다는 공통의 목표를 공유하는 여러분을 DQ 인덱스 프로젝트에 초대합니다. DQ 인덱스를 통해 디지털 역량 강화를 위한 모든 노력들과 그 임팩트를 공동으로 측정하고, 목표 달성에 위한 글로벌 진행 상황을 모니터링하고 추적합니다.`,
    home__safer_internet_day_february_8_2022: `안전한 인터넷의 날<br>(2022년 2월 8일)`,
    home__digital_citizenship_test_dct: `디지털 시민의식 테스트(DCT)`,
    home__dct_was_made_available_to_the_public: `DCT는 개인이 기술을 안전하고 책임감있게 사용할 준비가 되었는지에 대한 역량을 측정합니다. 안전한 인터넷의 날을 기념하여 모두가 이용할 수 있도록 제공됩니다.`,
    home__download_the_test_kit: `테스트 키트 <br>다운로드`,
    home__tbd: `미정`,
    home__dq_index_launch: `DQ 인덱스 출시 <br>& 공동 개발`,
    home__the_dq_index_platform_will_be_launched: `DQ 인덱스 플랫폼은 2022년 후반에 출시될 예정입니다. 공동 R&D 프로젝트에 참여하실 파트너들을 초대합니다.`,
    home__join_the_collaborative: `공동 R&D 프로젝트에 <br> 참여하기`,
    home__dq_day_october_10_2022: `DQ의 날 <br> (2022년 10월 10일)`,
    home__2022_dq_conference: `2022년 DQ 컨퍼런스`,
    home__dq_conference_provides_learning_experiences: `DQ 컨퍼런스는 디지털 역량에 관련된 교육, 정책, 학술 연구, 그리고 다양한 경험사례를 나누는 장입니다.`,
    home__if_you_interested_in_becoming_a_sponsor: `스폰서, 연사 또는 참가자가 되는 데 관심이 있으시면 아래의 링크를 통해 등록하세요.`,
    home__register_for_the_conference: `컨퍼런스에<br>등록하기`,
    home__privacy_policy: `개인정보 정책`,
    home__dq_lab_pte_ltd_all_rights_reserved: `DQ LAB Pte Ltd. All Rights Reserved.`,
    home__language: `언어`,
    home__term_of_use: `이용약관`,
    home__email: `이메일`,
    home__general_enquiries: `일반 문의`,
    home__social_media: `소셜 미디어`,
    assessment__find_out_what_type_of_digital_citizen_you_are: `<br>디지털 시민의 유형을 알아보세요.`,
    assessment__take_the_free_test: `무료 테스트하기`,
    assessment__5_types_of_digital_citizen: `5가지 유형의 디지털 시민`,
    assessment__based_on_statement_misc_1: `<a href="https://www.dqinstitute.org/" target="_blank">DQ 연구소</a>의 글로벌 데이터 뱅크를 기반으로 디지털 역량 수준, 기술 사용 및 사이버 위험도의 다양한 패턴을 보여주는 디지털 시민의 5가지 유형을 확인했습니다.`,
    assessment__carefree: `골수 디지털 네이게이터`,
    assessment__feels_more_comfortable_online_than_offline: `당신은 오프라인보다 온라인에서 생활하는 것이 더 편함.`,
    assessment__potentially_involved_with_various_cyber_risks: `하지만 다양한 디지털 위험에 이미 노출되어 있을 수 있음.`,
    assessment__ordinary: `평범한 디지털 유저`,
    assessment__average_technology_user_who_not_fully_aware: `디지털 세상의 위험도, 그렇다고 기회도 완전히 잘 알지는 못함.`,
    assessment__tend_to_passively_follow_the_algorithm: `디지털에서 추천하는 알고리즘을 수동적으로 따르는 경향이 있음.`,
    assessment__always_on: `상시접속형 디지털 관종`,
    assessment__likely_communicate_with_others_a_lot_online: `온라인으로 사람들과 소통하는 것에 많은 시간을 투자함.`,
    assessment__may_not_consider_much_about_risks: `디지털 위험 따위는 크게 안중에 없음.`,
    assessment__cautious: `조심성 많은 디지털 경계형`,
    assessment__being_aware_of_potential_cyber_risks: `디지털 위험을 잘 인지하고 있고, 디지털 이용에 대한 경계심을 가지고 있어 쉽게 위험에 빠지지는 않음.`,
    assessment__likely_having_low_risks_in_the_digi_world: `그렇지만 디지털을 제대로 이용하지도 않음.`,
    assessment__digilog: `균형 잡힌 디지로그형`,
    assessment__well_rounded_digital_citizen: `온라인과 오프라인의 삶을 균형 잡을 줄 아는 모범적인 디지털 시민.`,
    assessment__uses_technology_mindfully: `기술을 제대로 이해하고, 신중하게 사용할 줄 알음.`,
    assessment__low: `낮음`,
    assessment__medium: `중간`,
    assessment__high: `높음`,
    assessment__skills: `역량`,
    assessment__usage: `사용량`,
    assessment__risks: `위험도`,
    assessment__be_yourself_and_answer_honestly: `솔직하게 답변하고 여러분의 디지털 시민 유형과 DQ 점수를 알아보세요.`,
    assessment__take_the_test: `무료 테스트하기`,
    assessment__digital_citizenship_test_and_dq_index: `디지털 시민의식 테스트 및 DQ 인덱스`,
    assessment__the_level_of_digital_citizenship_is: `한 국가의 <span class="isection-toolip">디지털 시민의식<div class="tooltip-content">디지털 시민의식은 기술을 안전하고 책임감 있게 사용하는 데 필요한 역량입니다. IEEE 디지털 지능(DQ) 글로벌 표준(IEEE 3527.1™)의 첫 번째 단계로 디지털 시민의식은 코딩, 사이버 보안 및 기타 직무 준비 기술 기술과 같은 다른 디지털 역량을 전제 조건으로 간주합니다.</div></span> 수준은 그 나라의 그 나라의 전체 디지털 역량 수준을 가늠할 수 있는 중요한 지표입니다.`,
    assessment__based_on_years_of_research_desc: `DQ 연구소는 다년간의 연구를 바탕으로 개인의 디지털 시민의식 수준을 평가하는 질문지 ‘디지털 시민의식 테스트(DCT)’를 개발했습니다.`,
    assessment__connected_with_the_dq_index_desc: `DCT의 결과는 DQ 인덱스와 실시간 연계되어 각 국가의 디지털 시민의식 수준을 보여주는 지표에 반영됩니다. DQ인덱스는 각국이 필요한 재원을 효과적으로 활용하여 전국민의 디지털 역량 향상을 이끌고, 디지털 대전환을 성공적으로 이룰 수 있도록 돕습니다.`,
    assessment__digital_citizenship_is_a_skill_desc: `IEEE 디지털 지능(DQ) 글로벌 표준(IEEE 3527.1™)의 첫 번째 단계로 디지털 시민의식은 코딩, AI 역량 및 기타 IT 직무 관련 역량과 같은 다른 디지털 역량을 전제 조건으로 간주합니다`,
    assessment__digital_citizenship_scores: `디지털 시민의식 점수`,
    assessment__global: `글로벌`,
    assessment__australia: `호주`,
    assessment__cambodia: `캄보디아`,
    assessment__china: `중국`,
    assessment__colombia: `콜롬비아`,
    assessment__dominican_republic: `도미니카 공화국`,
    assessment__ecuador: `에콰도르`,
    assessment__el_salvador: `엘살바도르`,
    assessment__hong_kong: `홍콩`,
    assessment__india: `인도`,
    assessment__indonesia: `인도네시아`,
    assessment__ireland: `아일랜드`,
    assessment__japan: `일본`,
    assessment__korea_republic_of: `대한민국`,
    assessment__malaysia: `말레이시아`,
    assessment__mexico: `멕시코`,
    assessment__new_zealand: `뉴질랜드`,
    assessment__nigeria: `나이지리아`,
    assessment__peru: `페루`,
    assessment__philippines: `필리핀`,
    assessment__qatar: `카타르`,
    assessment__singapore: `싱가포르`,
    assessment__south_africa: `남아프리카 공화국`,
    assessment__spain: `스페인`,
    assessment__sweden: `스웨덴`,
    assessment__thailand: `태국`,
    assessment__turkey: `터키`,
    assessment__united_kingdom: `영국`,
    assessment__united_states: `미국`,
    assessment__viet_nam: `베트남`,
    assessment__n_a_text: `N/A`,
    assessment__national_dq: `국가 DQ 인덱스`,
    assessment__global_dq: `글로벌 DQ 인덱스`,
    assessment__percent_of_participants: `참여자 비율`,
    assessment__digital_citizenship_score: `디지털 시민의식 점수`,
    assessment__distribution_of_dcs: `디지털 시민의식 점수(DCS) 분포`,
    assessment__about_dq_assessments: `DQ 평가란?`,
    assessment__forall: `누구든지`,
    assessment__available_for_all_age_groups_and_types: `모든 연령대 및 유형이 사용 가능하다.`,
    assessment__any_system: `어떤 조직에서든지`,
    assessment__whether_you_looking_to_integrate: `기존 시스템에 반영하는 경우든 새로운 시스템을 원하는 경우든 언제나 활용할 수 있다.`,
    assessment__dq_index: `DQ 인덱스`,
    assessment__scores_are_immediately_updated_into: `점수는 즉시 국내 및 글로벌 비교를 위해 <a href="/">DQ 인덱스</a>에 반영된다.`,
    assessment__dq_assessment_was_developed_as: `DQ 평가는 웹/모바일 기반의 플랫폼으로 개발되었으며, 디지털 역량 교육 프로그램이 글로벌 벤치마크를 기반으로 개인 및 조직의 디지털 역량을 측정하도록 돕는 평가/분석 도구를 제공합니다. DQ 평가는 다음을 가능케 합니다.`,
    assessment__1_common_assessments_that_measure: `1) 개인 및 조직의 디지털 역량 수준을 글로벌 표준과 비교하여 측정할 수 있습니다.`,
    assessment__2_in_depth_analysis_to_the_end_users: `2) 개인 및 조직의 강점과 약점을 파악하기 위하여 글로벌 벤치마크를 바탕으로 한 상세한 분석을 제공합니다.`,
    assessment__3_customized_digital_skill_development: `3) 생애주기별 교육을 디자인할 수 있도록 개인 및 조직을 위한 맞춤 디지털 역량 개발을 제공합니다.`,
    assessment__dq_assessment_tools_are_developed_based_on: `DQ 평가는 신뢰할 수 있는 다양한 연구결과와 파트너들의 연구 프로젝트를 바탕으로 개발되었습니다.`,
    assessment__toolkits: `다양한 툴킷`,
    assessment__whether_it_be_your_school_company_or: `글로벌 벤치마크에 근거해 디지털 시민의식을 평가하는 다양한 툴킷을 학교, 기업 및 국가에 제공합니다.`,
    assessment__if_you_looking_to_create_a_customized: `만약 맞춤형 평가 수단이나 상세 분석 서비스를 찾으신다면, 또는 기타 정보가 필요하시다면, 당사로 문의해주세요.`,
    assessment__primary_schools: `초등학교`,
    assessment__schools_can_get_their_students_ages_8_12: `만 8~12세 학생들에게 포괄적인 디지털 시민의식 테스트를 제공하여, 디지털 시민의식 수준을 확인할 수 있다.`,
    assessment__measures_dq_1_8: `DQ의 8가지 세부항목 측정`,
    assessment__analytics_dashboard_included_for_teachers: `교사용 분석 대시보드 포함`,
    assessment__digital_parenting: `디지털 양육법 교육`,
    assessment__cyber_risk_assessment: `디지털 위험도 평가`,
    assessment__learn_more: `더 알아보기`,
    assessment__secondary_schools: `중·고등학교`,
    assessment__schools_can_get_their_teenage_students_ages_13_18: `만 13~18세 학생들의 디지털 시민의식을 측정하여, 디지털 시민의식을 더 포괄적으로 이해하게 한다.`,
    assessment__companies_corporates_span: `기업`,
    assessment__companies_and_organizations_can_assess: `기업과 조직은 직원의 디지털 역량과 시민의식을 평가하고 성장이 필요한 부분을 확인할 수 있다.`,
    assessment__dashboard_included_for_hr: `인사 담당자를 위한 분석 대시보드 포함`,
    assessment__national_packages: `국가<br/>패키지`,
    assessment__countries_and_govt_localize_assess: `국가 및 정부는 현지화된 맞춤형 평가를 통해 자국의 DQ를 측정할 수 있다.`,
    assessment__customize_assessment: `맞춤형 평가`,
    assessment__localized_for_your_country: `각국에 맞게 현지화 및 번역 완료`,
    assessment__consult_strategies_plans: `DQ 활용 계획의 컨설팅, 전략화 및 공동 개발`,
    assessment__balance_use_of_tech: `균형 잡힌 기술 사용`,
    assessment__behavioural_risk_management: `행동 디지털 위험 관리`,
    assessment__digital_empathy: `디지털 공감`,
    assessment__dc_identity: `디지털 시민 정체성`,
    assessment__ps_cyber_sec_management: `개인 디지털 보안 관리`,
    assessment__media_and_info_literacy: `미디어 및 정보 리터러시`,
    assessment__digi_footprint_management: `디지털 발자국 관리`,
    assessment__privacy_management: `사생활 관리`,
    assessment__read_press_release: '더 알아보기',
}


export const LC_VALUES_WEBSITE = {
    'en': lc_en,
    'ko': lc_ko
}

const validate_keys_syncing = () => {
    const en_keys_count = Object.keys(lc_en).length;
    const ko_keys_count = Object.keys(lc_ko).length;
    if (en_keys_count === ko_keys_count) {
        console.log('lc_values::website:: keys are in sync');
    } else {
        console.log('lc_values::website:: FATAL ISSUE : keys not in sync');
    }
}

validate_keys_syncing();