import React from 'react';
import {useLocation} from "react-router-dom";
import {GET_APP_LOCALIZE_TEXT} from "../../utils/dq_lc_service/lc_service";

const Footer = (props) => {

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    return (
        <div className={'dq-app-footer'}>
            <a className={'dq-footer-link'} href={'/privacy_policy'} target={'_blank'}>{GET_APP_LOCALIZE_TEXT(pageLc, 'persist_guest__privacy_policy')}</a>
            <span>|</span>
            <a className={'dq-footer-link'} href={'/terms_of_use'} target={'_blank'}>{GET_APP_LOCALIZE_TEXT(pageLc, 'persist_guest__terms_of_use')}</a>
            <div className={'dq-copyright'}>&copy; 2022 DQ LAB. All Rights Reserved</div>
        </div>
    );
}

export default Footer;