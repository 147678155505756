import {invokeApi} from "../utils/invokeApi";
import {LOCAL_STORAGE_KEYS} from "../utils/constants";

export const getOrgByAppKey = (app_key) => {
    let requestObj = {
        path   : '/my_dq/app_start/get_org',
        method : 'POST'
    };
    const postData = {
        app_key
    };
    requestObj['postData'] = postData;

    return invokeApi(requestObj);
}

export const getGuestUser = (org_code, user_group) => {
    let requestObj = {
        path   : `/my_dq/app_start/guest_user`,
        method : 'POST',
        headers : {
            'xc-org-code': org_code
        }
    };
    if (user_group) {
        requestObj['postData'] = {
            user_group,
        };
    }

    return invokeApi(requestObj);
}

export const saveUserGroup = (user_group) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN));
    let requestObj = {
        path   : `/my_dq/app_start/save_user_group`,
        method : 'POST',
        headers : {
            Authorization : `Bearer ${token}`
        }
    };
    requestObj['postData'] = {
        user_group,
    };

    return invokeApi(requestObj);
}

export const saveDemographics = (
        gender,
        year_of_birth,
        country,
        postal_code
    ) => {
    const locale = localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVE_LOCALE);
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN));
    let requestObj = {
        path   : `/my_dq/app_start/update_demographic`,
        method : 'POST',
        headers : {
            Authorization : `Bearer ${token}`,
            'xc-dq-locale': locale
        }
    };
    requestObj['postData'] = {
        gender,
        year_of_birth,
        country,
        postal_code
    };

    return invokeApi(requestObj);
}