import React, {useEffect, useState} from 'react';
// import Button, {btnSize} from "../../components/UI/Button/Button.ui";
import BorderButton , {btnSize} from "../../components/UI/BorderButton/BorderButton.ui";

import { withRouter } from "react-router";
import {useLocation, useParams} from "react-router-dom";
import {finishAssessService} from "../../services/home.service";
import {useGlobalState} from "../../context/global_state";

import rubricImg from '../../assets/images/score-rubric.png';
import LoaderWithBackDrop from "../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import Footer from "../../components/Footer/plugable.footer";

import {LOCAL_STORAGE_KEYS, DIGITAL_CITIZEN_TYPES, LOCALES_ENABLED} from "../../utils/constants";
import {getActiveThemeColor, getMapFromObjectListByKey, getPercentileStr, getBadgeResultStr, getBadgeResultColorClass} from "../../utils/utils";
import {logoutUser} from "../../services/auth.service";
import {useAuth} from "../../context/auth";
import {GET_APP_LOCALIZE_TEXT} from "../../utils/dq_lc_service/lc_service";
import ReactHtmlParser from "react-html-parser";

const QuizResult = (props) => {
    
    const { identifier } = useParams();
    const { history } = props;

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const [loading, setLoading] = useState(false);

    const [score, setScore] = useState(-1);
    const [percentile, setPercentile] = useState('');
    const [resultText, setResultText] = useState('');
    const [resultColorClass, setResultColorClass] = useState('');

    const [subScreen, setSubScreen] = useState(1);

    const [badgeCode, setBadgeCode] = useState('');
    const [dcType, setDcType] = useState(false);

    const { setAuthToken, setAuthUser } = useAuth();
    const { setToastShow, setToastMessage, setProfile, profile } = useGlobalState();

    const btnAction = async () => {
        if (dcType && subScreen === 1) {
            setSubScreen(2);
        } else {

            let redirect_path = '/home';
            let host_redirect = false;
            let land_on_index = false;


            // if (profile.is_org_provide) {
            //     redirect_path = '/home';
            // }

            const user_age = localStorage.getItem(LOCAL_STORAGE_KEYS.CURRENT_USER_AGE);
            
            // Temp fix to redirect DQ users and users below age 16 to index page
            // TODO: This needs to be changed when more assessments are added for DQ
            //if (user_age && user_age < 16  && !profile.is_org_provide) {
            if (user_age && user_age < 16  || !profile.is_org_provide) {
                redirect_path = '/';
                land_on_index = true;
            }

            const collab_value_raw = localStorage.getItem(LOCAL_STORAGE_KEYS.COLLAB_STRING);
            if (collab_value_raw) {
                const collab_value = atob(collab_value_raw);
                const collab_obj = JSON.parse(collab_value);
                if (collab_obj.url && collab_obj.url !== '') {
                    redirect_path = collab_obj.url
                    host_redirect = true;
                }
            }

            if (host_redirect) {
                await logoutAction();
                window.location.replace(redirect_path);
            } else {
                if (land_on_index) {
                    await logoutAction();
                }
                history.push(redirect_path, true);
            }
        }

    }

    const logoutAction = async () => {
        try {
            await logoutUser();
            setAuthToken(null);
            setAuthUser(null);
            setProfile(null);
            localStorage.clear();

            if (LOCALES_ENABLED.includes(pageLc)) {
                localStorage.setItem(LOCAL_STORAGE_KEYS.ACTIVE_LOCALE, pageLc);
            }
        } catch (e) {
            setToastShow(true);
            setToastMessage(e.message);
        }
    }

    useEffect( () => {

        finishAssess().then(d=>console.log('fetched assess score'));

    }, []);

    useEffect( () => {
        if (badgeCode !== '') {
            // set active theme color as per current area of the Quiz
            const app_start_cached_data = localStorage.getItem(LOCAL_STORAGE_KEYS.HOME_APP_START_API_RESPONSE);
            if (app_start_cached_data) {
                const api_data = JSON.parse(app_start_cached_data);
                const badges_ui_map = getMapFromObjectListByKey(api_data.ui_config.badges_uic, 'badge_code');
                const areas_ui_conf = getMapFromObjectListByKey(api_data.ui_config.areas_uic, 'area_code');

                const current_badge = badges_ui_map[badgeCode];
                if (current_badge) {
                    const current_area = areas_ui_conf[current_badge.area_code];
                    if (current_area) {
                        setProfile({
                            ...profile,
                            active_theme_color: current_area.theme_color
                        })
                    }
                }
            }
        }
    }, [badgeCode] )

    const finishAssess = async () => {
        try {
            setLoading(true);

            const res = await finishAssessService(identifier);
            setProfile({
                ...profile,
                ...res.data.profile,
            });
            if (res.data.error_from_flask) {
                console.log('flask::-error-logs:::-- message-> ', res.data.flask_error_message)
                console.log('flask::-error-logs:::-- input-data-> ', res.data.flask_function_input)
            }

            setScore(res.data.score);
            setPercentile(getPercentileStr(res.data.score, res.data.percentile, pageLc));
            setResultText(getBadgeResultStr(res.data.score, false, pageLc));
            setResultColorClass(getBadgeResultColorClass(res.data.score));
            setBadgeCode(res.data.badge_code)
            setDcType(res.data.dc_type);
            setLoading(false);

        } catch (e) {
            setToastShow(true);
            setToastMessage(e.message);
            setLoading(false)
        }
    }

    return (
        <div className={'quiz-result-container'}>
            
            <LoaderWithBackDrop loading={loading} />

            {
                subScreen === 1 && <div className={'quiz-result-content'}>
                    {
                        score && percentile && resultText && resultColorClass ?
                            <>
                                <h1 className={resultColorClass}>{resultText}</h1>
                                <div className={'quiz-score-container'}>
                                    <div className={'quiz-score-content'}>
                                        <div className={'score-spinner'}>
                                            {
                                                score && <><span>{(parseFloat(score).toFixed(2)).split('.')[0]}
                                            </span><span className={'score-label-pt'}>.{(parseFloat(score).toFixed(2)).split('.')[1]}</span></>
                                            }
                                        </div>
                                        <div className={'score-desc'}>{GET_APP_LOCALIZE_TEXT(pageLc, 'result_quiz__your_score_span')}<span className={resultColorClass}>{percentile}</span>.</div>
                                    </div>
                                    <div className={'score-rubric'}>
                                        <img className={'rubric-desktop'} src={`/images/${pageLc}/mydq-rubric-desktop.png`} alt="" />
                                        <img className={'rubric-mobile'} src={`/images/${pageLc}/mydq-rubric-mobile.png`} alt="" />
                                        <div className='rubric-container'>
                                            <div className='rubric-scores'>
                                                <span>115</span>
                                                <span>100</span>
                                                <span>85</span>
                                            </div>
                                            <div className='rubric-content'>
                                                <div className='rubric-row rubric-excellent'>
                                                    <span>{ReactHtmlParser(GET_APP_LOCALIZE_TEXT(pageLc, 'result_quiz__excellent_br'))}</span>
                                                    <div>{GET_APP_LOCALIZE_TEXT(pageLc, 'result_quiz__you_are_ready_to_use_tech_rubirc')}</div>
                                                </div>
                                                <div className='rubric-row rubric-above-avg'>
                                                    <span>{ReactHtmlParser(GET_APP_LOCALIZE_TEXT(pageLc, 'result_quiz__above_avg_br'))}</span>
                                                    <div>{GET_APP_LOCALIZE_TEXT(pageLc, 'result_quiz__your_score_is_above_average_rubric')}</div>
                                                </div>
                                                <div className='rubric-row rubric-below-avg'>
                                                    <span>{ReactHtmlParser(GET_APP_LOCALIZE_TEXT(pageLc, 'result_quiz__bellow_avg_br'))}</span>
                                                    <div>{GET_APP_LOCALIZE_TEXT(pageLc, 'result_quiz__your_score_is_less_than_average_rubirc')}</div>
                                                </div>
                                                <div className='rubric-row rubric-req-att'>
                                                    <span>{ReactHtmlParser(GET_APP_LOCALIZE_TEXT(pageLc, 'result_quiz__require_attention_br'))}</span>
                                                    <div>{GET_APP_LOCALIZE_TEXT(pageLc, 'result_quiz__you_may_potentially_be_exposed_rubirc')}</div>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            badgeCode === "FreeDCT" ?
                                                <div className={'dq-disclaimer dq-disc-desktop'}>
                                                    {ReactHtmlParser(GET_APP_LOCALIZE_TEXT(pageLc, 'result_quiz__dct_discalimer_html_text'))}
                                                </div>
                                            : null

                                        }
                                    </div>
                                </div>
                                <div className={'dq-result-button-container'}>
                                    {/*<div className={'dq-more-info-link'}>{GET_APP_LOCALIZE_TEXT(pageLc, 'result_quiz__click_message_dct_type')}</div>*/}
                                    <BorderButton
                                        size={btnSize.sm}
                                        title={GET_APP_LOCALIZE_TEXT(pageLc, 'result_quiz__next')}
                                        action={btnAction}
                                        themeColor={getActiveThemeColor(profile)}
                                    />
                                </div>
                                {
                                    badgeCode === "FreeDCT" ?
                                        <div className={'dq-disclaimer dq-disc-mobile'}>
                                            {ReactHtmlParser(GET_APP_LOCALIZE_TEXT(pageLc, 'result_quiz__dct_discalimer_html_text'))}
                                        </div>
                                        : null
                                }
                            </>
                            :
                            null
                    }
                </div>
            }

            {
                subScreen === 2 && <div
                    style={{
                        marginTop: '10%'
                    }}
                    className={'qt-guide-container'}>
                    <div
                        style={{color: getActiveThemeColor(profile)}}
                        className={'qt-guide-heading'} >
                        <span>{DIGITAL_CITIZEN_TYPES(pageLc)[dcType].title}</span>
                    </div>
                    <div className={'qt-guide-img-container dc-type-container'}>
                        <div className={`dq-dc-type ${dcType}`} />
                    </div>
                    <div className={'qt-guide-body dc-type-desc'}>
                        <ul>
                            { DIGITAL_CITIZEN_TYPES(pageLc)[dcType].desc_list.map( (desc, index) => <li>{desc}</li>) }
                        </ul>
                    </div>

                    <div className={'dq-result-button-container'}>
                        <div className={'dq-more-info-link'}>
                            {ReactHtmlParser(GET_APP_LOCALIZE_TEXT(pageLc, 'result_quiz__learn_more_html_text'))}
                        </div>
                        <BorderButton
                            size={btnSize.sm}
                            title={GET_APP_LOCALIZE_TEXT(pageLc, 'result_quiz__done')}
                            action={btnAction}
                            themeColor={getActiveThemeColor(profile)}
                        />
                    </div>
                    <div className={'dq-disclaimer dq-disc-mobile'}>
                        {ReactHtmlParser(GET_APP_LOCALIZE_TEXT(pageLc, 'result_quiz__dct_discalimer_html_text'))}
                    </div>
                </div>
            }
            <Footer />
        </div>
    )
};

export default withRouter(QuizResult);
