import React from 'react';
import DQLogoImg from '../../../assets/icons/dq-icons/dq-logo-orange.png';
import {LOCAL_STORAGE_KEYS, LOCALES_ENABLED} from "../../../utils/constants";
import {useAuth} from "../../../context/auth";
import {logoutUser} from "../../../services/auth.service";
import {useGlobalState} from "../../../context/global_state";

import { withRouter } from "react-router";
import {useLocation} from "react-router-dom";
import {GET_APP_LOCALIZE_TEXT} from "../../../utils/dq_lc_service/lc_service";

const SideBar = (props) => {

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const {show, closeBar, history} = props;
    const { setAuthToken, setAuthUser, authUser } = useAuth();
    const { setToastShow, setToastMessage, setProfile } = useGlobalState();

    let drawerClasses = `${'sidebar'} ${'sidebar_close'}`
    if (show) {
        drawerClasses = `${'sidebar'} ${'sidebar_open'}`
    }

    const clickAction = (path) => {
        history.push(path);
        closeBar();
    }

    const LogoutAction = async () => {

        let redirect_path = '/login';
        const app_key = localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVE_APP_KEY);
        if (app_key) {
            redirect_path = redirect_path + '?app_key=' + app_key
        }

        try {
            await logoutUser();
            setAuthToken(null);
            setAuthUser(null);
            setProfile(null);
            localStorage.clear();

            if (LOCALES_ENABLED.includes(pageLc)) {
                localStorage.setItem(LOCAL_STORAGE_KEYS.ACTIVE_LOCALE, pageLc);
            }
        } catch (e) {
            setToastShow(true);
            setToastMessage(e.message);
        }

        // redirect to landing page.
        history.push(redirect_path, true);
        closeBar();
    }

    const getHelloString = () => {
        if (authUser.is_guest_user) {
            return GET_APP_LOCALIZE_TEXT(pageLc, 'menu__guest_text')
        }
        return authUser.username
    }

    return (
        <div className={drawerClasses}>
            <p className={'usr'}>{GET_APP_LOCALIZE_TEXT(pageLc, 'menu__hello_text')}, {getHelloString()}!</p>
            <hr />
            <ul>
                <li onClick={() => {
                    clickAction('/home')
                }}>{GET_APP_LOCALIZE_TEXT(pageLc, 'menu__home')}
                </li>
                {/*{*/}
                {/*    process.env.REACT_APP_DEVSID_BUILD !== '1' && <li onClick={() => {*/}
                {/*        clickAction('/stats')*/}
                {/*    }}>View Stats*/}
                {/*    </li>*/}
                {/*}*/}

                {
                    authUser.is_guest_user && <li onClick={() => {
                        clickAction('/persist_guest')
                    }}>{GET_APP_LOCALIZE_TEXT(pageLc, 'menu__register')}
                    </li>
                }
                {/* <li onClick={() => {
                    clickAction('/settings')
                }}>{GET_APP_LOCALIZE_TEXT(pageLc, 'menu__settings')}
                </li> */}
                <li onClick={LogoutAction}>{GET_APP_LOCALIZE_TEXT(pageLc, 'menu__logout')}
                </li>
            </ul>
        </div>
    );
};

export default withRouter(SideBar);