import React, {useState, useEffect} from 'react';
import {GET_APP_LOCALIZE_TEXT, GET_WEBSITE_LOCALIZE_TEXT} from "../../../utils/dq_lc_service/lc_service";
import {LOCAL_STORAGE_KEYS, LOCALES_ENABLED} from "../../../utils/constants";
import {useHistory, useLocation} from "react-router-dom";

const lang_dict = {
    en: 'English',
    ko: '한국어'
}


const LangDropdown = (props) => {

    const history = useHistory();
    const location = useLocation();

    const {className} = props;

    const pageLc = location.pathname.split('/')[1];

    const getLanguageFromLc = (lc_code) => {
        if (lang_dict[lc_code]) {
            return lang_dict[lc_code]
        }
        return lc_code
    }

    const changeLocale = (e) => {
        const path = location.pathname.split('/')[2];
        const loc_code = e.target.value;

        if (LOCALES_ENABLED.includes(loc_code)) {
            localStorage.setItem(LOCAL_STORAGE_KEYS.ACTIVE_LOCALE, loc_code);
        }

        history.push(`/${loc_code}/${path}`);
        window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
    }

    return (
        <div className={'dq-lang-select'}>
            <select className={className} name={'language'} id={'language'} value={pageLc} onChange={changeLocale}>
                <option value="en">English</option>
                <option value="ko">Korean</option>
            </select>
        </div>
    )
}

export default LangDropdown;