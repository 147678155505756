import React from 'react';
import Button, {btnSize} from "../../components/UI/Button/Button.ui";

import { withRouter } from "react-router";

const NotFound = (props) => {

    const { history } = props;

    return (
        <div className={'not-found-main-container'}>
            <div className={'not-found-heading'}>404</div>
            <div className={'not-found-heading'}>Not Found</div>
            <div className={'not-found-btn-container'}>
                <Button
                    action={ () => {
                        history.push('/')
                    } }
                    title={'goto Home Page'}
                    size={btnSize.lg}
                />
            </div>

        </div>
    );
}

export default withRouter(NotFound);
