import React, {useEffect, useState} from 'react';
import ReactHtmlParser from 'react-html-parser';
import { withRouter } from "react-router";

import Footer from "../../../components/Footer/plugable.footer";
import dqIcon from '../../../assets/icons/dq-icons/dq-logo.png';
import {LOCAL_STORAGE_KEYS, LOCALES_ENABLED} from "../../../utils/constants";
import {logoutUser} from "../../../services/auth.service";
import {useAuth} from "../../../context/auth";
import {useGlobalState} from "../../../context/global_state";
import {useLocation} from "react-router-dom";
import {GET_APP_LOCALIZE_TEXT} from "../../../utils/dq_lc_service/lc_service";


const PostQuizGuestLanding = (props) => {

    const { history } = props;

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const { setAuthToken, setAuthUser } = useAuth();
    const { setToastShow, setToastMessage, setProfile } = useGlobalState();

    const [registerRequire, setRegisterRequire] = useState(true);

    useEffect( () => {
        const user_age = localStorage.getItem(LOCAL_STORAGE_KEYS.CURRENT_USER_AGE);
        console.log('---- user_age ----- ==> ', user_age);

        if (user_age && user_age < 16) {
            setRegisterRequire(false);
        }
    }, [] )

    const signup_link = () => {
        history.push('/persist_guest', true);
    }

    const see_result_action = () => {
        const quiz_id = localStorage.getItem(LOCAL_STORAGE_KEYS.LAST_ACTIVE_QUIZ_ID)
        history.push(`/result/quiz/${quiz_id}`);
    }

    const no_thanks_link = async () => {
        // *** following commented code block is deprecated.
        if (process.env.REACT_APP_DEVSID_BUILD === '1') {
            // logout the user
            let redirect_path = '/assessment/DCT';
            let host_redirect = false;

            const collab_value_raw = localStorage.getItem(LOCAL_STORAGE_KEYS.COLLAB_STRING);
            if (collab_value_raw) {
                const collab_value = atob(collab_value_raw);
                const collab_obj = JSON.parse(collab_value);
                if (collab_obj.url && collab_obj.url !== '') {
                    redirect_path = collab_obj.url
                    host_redirect = true;
                }
            }

            try {
                await logoutUser();
                setAuthToken(null);
                setAuthUser(null);
                setProfile(null);
                localStorage.clear();

                if (LOCALES_ENABLED.includes(pageLc)) {
                    localStorage.setItem(LOCAL_STORAGE_KEYS.ACTIVE_LOCALE, pageLc);
                }
            } catch (e) {
                setToastShow(true);
                setToastMessage(e.message);
            }

            // redirect to landing page.
            if (host_redirect) {
                window.location.replace(redirect_path);
            } else {
                history.push(redirect_path, true);
            }
        } else {
            history.push('/home', true);
        }
        // history.push('/home', true);
    }

    return (
        <div className={'dq-audience-container'}>

            <div className="post-quiz-section-dq-image"><img src={dqIcon} alt='DQ'/></div>

            <h1
                style={{
                    marginTop: '40px',
                    textAlign: 'center'
                }}
            >{GET_APP_LOCALIZE_TEXT(pageLc, 'post_q_landing__congrats')}</h1>

            {
                registerRequire ? <>
                    <p className={'dq-audience-title post-quiz-sub-title'}>
                        {ReactHtmlParser(GET_APP_LOCALIZE_TEXT(pageLc, 'post_q_landing__finished_test_message_text'))}
                    </p>

                    <div className={'dq-options-submit dq-btn-2'}>
                        <button onClick={signup_link}>{GET_APP_LOCALIZE_TEXT(pageLc, 'post_q_landing__signup')}</button>
                        <button
                            style={{
                                marginTop: '20px'
                            }}
                            onClick={no_thanks_link}>{GET_APP_LOCALIZE_TEXT(pageLc, 'post_q_landing__no_thanks')}</button>
                    </div>
                </> : <>
                    <p className={'dq-audience-title post-quiz-sub-title'}>
                        {GET_APP_LOCALIZE_TEXT(pageLc, 'post_q_landing__finished_test_short_message')}
                    </p>

                    <div className={'dq-options-submit dq-btn-2'}>
                        <button onClick={see_result_action}>{GET_APP_LOCALIZE_TEXT(pageLc, 'quiz_result_see_your_score')}</button>
                    </div>
                </>
            }

            <Footer />

        </div>
    );
};


export default withRouter(PostQuizGuestLanding);
