import React, {useEffect, useState} from 'react';
import Helmet from "react-helmet";
import ReactHtmlParser from 'react-html-parser';
import { withRouter } from "react-router";
import DQIndexFooter from './components/Footer.comp';

import dqIndexLogo from "../../assets/images/dqindex/dqindex-logo.png";
import dqIndexAssessBanner from "../../assets/images/dqindex/dqassessment-banner.png";

import dcType1 from "../../assets/images/dc-types/dc-type-c1.png";
import dcType2 from "../../assets/images/dc-types/dc-type-c2.png";
import dcType3 from "../../assets/images/dc-types/dc-type-c3.png";
import dcType4 from "../../assets/images/dc-types/dc-type-c4.png";
import dcType5 from "../../assets/images/dc-types/dc-type-c5.png";
import dcTypeB1 from "../../assets/images/dqindex/barchart/dc-type-bar1.jpg";
import dcTypeB2 from "../../assets/images/dqindex/barchart/dc-type-bar2.jpg";
import dcTypeB3 from "../../assets/images/dqindex/barchart/dc-type-bar3.jpg";
import dcTypeB4 from "../../assets/images/dqindex/barchart/dc-type-bar4.jpg";
import dcTypeB5 from "../../assets/images/dqindex/barchart/dc-type-bar5.jpg";
import dqforAl from "../../assets/images/dqindex/icons/forall-icon.png";
import anySys from "../../assets/images/dqindex/icons/system-icon.png";
import dqIndexAssess from "../../assets/images/dqindex/icons/index-globe-icon.png";
import dqIndexAppImg from "../../assets/images/dqindex/dqassessment-img.png";

import primarySchools from "../../assets/images/dqindex/icons/primary-school-icon.png";
import secondarySchools from "../../assets/images/dqindex/icons/secondary-school-icon.png";
import companies from "../../assets/images/dqindex/icons/company-icon.png";
import globePack from "../../assets/images/dqindex/icons/globe-icon.png";
import DC_ScoreChart from "../../components/UI/Charts/DC_ScoreChart.ui";

import {DQW_DCT_SCORES_DIST_FUNC} from "../../utils/constants";
import {useLocation} from "react-router-dom";
import {GET_WEBSITE_LOCALIZE_TEXT} from "../../utils/dq_lc_service/lc_service";

const DQIndexAssessment = (props) => {

    const {history} = props;
    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const [countryData, setCountryData] = useState(DQW_DCT_SCORES_DIST_FUNC(pageLc)[0]);

    const dropdownAction = (e) => {
        setCountryData(DQW_DCT_SCORES_DIST_FUNC(pageLc)[parseInt(e.target.value)]);
    }

    return (
        <div className="index-page-container">
            <Helmet htmlAttributes={{lang: pageLc}} />
            <header className='index-header'>
                <div className='iheader-contnet'>
                    <div className='index-logo'><img src={dqIndexLogo} alt='DQIndex'></img></div>
                    <div className='index-menu'>
                        <div className='imenu-item'><a href='/'>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__dq_index')}</a></div>
                        <div className='imenu-item'><a className='imenu-item-active' href='/assessment'>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'home__dq_assessment')}</a></div>
                        {/*<div className='imenu-item'><a className='imenu-link' href='/assessment/DCT' target='_blank'>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'home__take_the_test')}</a></div>*/}
                    </div>
                </div>
            </header>
            
            <section className='index-banner index-banner2'>
                <img className='ibanner-img' src={dqIndexAssessBanner} alt='DQ' />
                <div className='ibanner-content'>
                    <div className='ibanner-heading-assess'>
                        <h1 className='ihead-title'>{ReactHtmlParser(GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__find_out_what_type_of_digital_citizen_you_are'))}</h1>
                        <a href='/assessment/DCT' target='_blank' className='ihead-button'>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__take_the_free_test')}</a>
                    </div>
                </div>
            </section>

            <section className='index-section-container'>
                <div className='index-section-content isection-assess-sp'>
                    <h1 className='isection-title'>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__5_types_of_digital_citizen')}</h1>
                    <div className='isection-content'>
                        <p>
                            {ReactHtmlParser(GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__based_on_statement_misc_1'))}
                        </p>
                        <div className='dc-types-container'>
                            <div className='dc-types-bars-title'>
                                <span>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__skills')}</span>
                                <span>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__usage')}</span>
                                <span>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__risks')}</span>
                            </div>
                            <div className='dc-types'>
                                <div className='dc-type dt-carefree'>
                                    <h3>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__carefree')}</h3>
                                    <img className='dc-type-icon' src={dcType1} alt={GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__carefree')}></img>
                                    <div className='dc-type-skills'>
                                        <ul>
                                            <li>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__feels_more_comfortable_online_than_offline')}</li>
                                            <li>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__potentially_involved_with_various_cyber_risks')}</li>
                                        </ul>
                                    </div>
                                    <div className='dc-type-bars'>
                                        <div className='dc-type-bars-range'>
                                            <span>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__low')}</span>
                                            <span>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__medium')}</span>
                                            <span>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__high')}</span>
                                        </div>
                                        <img className='dc-type-bar' src={dcTypeB1} alt={GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__carefree')}></img>
                                    </div>
                                </div>
                                <div className='dc-type dt-ordinary'>
                                    <h3>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__ordinary')}</h3>
                                    <img className='dc-type-icon' src={dcType2} alt={GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__ordinary')}></img>
                                    <div className='dc-type-skills'>
                                        <ul>
                                            <li>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__average_technology_user_who_not_fully_aware')}</li>
                                            <li>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__tend_to_passively_follow_the_algorithm')}</li>
                                        </ul>
                                    </div>
                                    <div className='dc-type-bars'>
                                        <div className='dc-type-bars-range'>
                                            <span>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__low')}</span>
                                            <span>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__medium')}</span>
                                            <span>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__high')}</span>
                                        </div>
                                        <img className='dc-type-bar' src={dcTypeB2} alt={GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__ordinary')}></img>
                                    </div>
                                </div>
                                <div className='dc-type dt-always-on'>
                                    <h3>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__always_on')}</h3>
                                    <img className='dc-type-icon' src={dcType3} alt={GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__always_on')}></img>
                                    <div className='dc-type-skills'>
                                        <ul>
                                            <li>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__likely_communicate_with_others_a_lot_online')}</li>
                                            <li>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__may_not_consider_much_about_risks')}</li>
                                        </ul>
                                    </div>
                                    <div className='dc-type-bars'>
                                        <div className='dc-type-bars-range'>
                                            <span>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__low')}</span>
                                            <span>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__medium')}</span>
                                            <span>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__high')}</span>
                                        </div>
                                        <img className='dc-type-bar' src={dcTypeB3} alt={GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__always_on')}></img>
                                    </div>
                                </div>
                                <div className='dc-type dt-cautious'>
                                    <h3>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__cautious')}</h3>
                                    <img className='dc-type-icon' src={dcType4} alt={GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__cautious')}></img>
                                    <div className='dc-type-skills'>
                                        <ul>
                                            <li>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__being_aware_of_potential_cyber_risks')}</li>
                                            <li>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__likely_having_low_risks_in_the_digi_world')}</li>
                                        </ul>
                                    </div>
                                    <div className='dc-type-bars'>
                                        <div className='dc-type-bars-range'>
                                            <span>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__low')}</span>
                                            <span>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__medium')}</span>
                                            <span>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__high')}</span>
                                        </div>
                                        <img className='dc-type-bar' src={dcTypeB4} alt={GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__cautious')}></img>
                                    </div>
                                </div>
                                <div className='dc-type dt-digilog'>
                                    <h3>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__digilog')}</h3>
                                    <img className='dc-type-icon' src={dcType5} alt={GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__digilog')}></img>
                                    <div className='dc-type-skills'>
                                        <ul>
                                            <li>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__well_rounded_digital_citizen')}</li>
                                            <li>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__uses_technology_mindfully')}</li>
                                        </ul>
                                    </div>
                                    <div className='dc-type-bars'>
                                        <div className='dc-type-bars-range'>
                                            <span>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__low')}</span>
                                            <span>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__medium')}</span>
                                            <span>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__high')}</span>
                                        </div>
                                        <img className='dc-type-bar' src={dcTypeB5} alt={GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__digilog')}></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p className='ilabel-blue'>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__be_yourself_and_answer_honestly')}</p>
                        <div className='iaction-btn ibtn-assess'><a href="/assessment/DCT" target='_blank'>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__take_the_test')}</a></div>
                    </div>
                </div>
            </section>

            <section className='index-section-container full-length-container container-gr-shade'>
                <div className='index-section-content'>
                    <h1 className='isection-title'>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__digital_citizenship_test_and_dq_index')}</h1>
                    <div className='isection-content' style={{paddingBottom: '10px'}}>
                        <p>{ReactHtmlParser(GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__the_level_of_digital_citizenship_is'))}</p>
                        <p>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__based_on_years_of_research_desc')}</p>
                        <p>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__connected_with_the_dq_index_desc')}</p>
                        <div className='dc-tooltip' style={{display: 'none'}}>{ReactHtmlParser(GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__digital_citizenship_is_a_skill_desc'))}</div>
                    </div>
                    <h1 className='isection-title'>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__digital_citizenship_scores')}</h1>
                    <div className='dc-scores-container'>
                        <div className='dc-country-ddl-container'>
                            <select className='dc-country-ddl' onChange={dropdownAction}>
                                { DQW_DCT_SCORES_DIST_FUNC(pageLc).map( (dcScore, index) => <option value={index}>{dcScore.demo_country}</option>) }
                            </select>
                        </div>
                        <div className='dc-score-content dc-national-score'>
                            <div>
                                { countryData ? countryData.AVGScore.toString().split('.')[0] : GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__n_a_text')}
                            </div>
                            <span>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__national_dq')}</span>
                        </div>
                        <div className='dc-score-content dc-global-score'>
                            <div>100</div>
                            <span>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__global_dq')}</span>
                        </div>
                    </div>
                    <div className='dq-dc-chart'>
                        {/* BAR CHART HERE */}
                        <DC_ScoreChart
                            all_scores={countryData}
                            locale={pageLc}
                        ></DC_ScoreChart>
                    </div>

                </div>
            </section>
            <section className='index-section-container'>
                <div className='index-section-content'>
                    <h1 className='isection-title'>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__about_dq_assessments')}</h1>
                    <div className='isection-content'>
                        <div className='isection-assessments'>
                            <div>
                                <img src={dqforAl} alt='forAll'></img>
                                <span>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__forall')}</span>
                                {GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__available_for_all_age_groups_and_types')}
                            </div>
                            <div>
                                <img src={anySys} alt='Any System'></img>
                                <span>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__any_system')}</span>
                                {GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__whether_you_looking_to_integrate')}
                            </div>
                            <div>
                                <img src={dqIndexAssess} alt='DQ Index'></img>
                                <span>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__dq_index')}</span>
                                {ReactHtmlParser(GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__scores_are_immediately_updated_into'))}
                            </div>
                        </div>
                        <div className='iassess-details'>
                            <div className='iassess-details-ico'><img src={dqIndexAppImg} alt='DQ Assessments'></img></div>
                            <div className='iassess-details-content'>
                                <p>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__dq_assessment_was_developed_as')}</p>
                                <p>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__1_common_assessments_that_measure')}</p>
                                <p>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__2_in_depth_analysis_to_the_end_users')}</p>
                                <p>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__3_customized_digital_skill_development')}</p>
                                <p>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__dq_assessment_tools_are_developed_based_on')}</p>
                                <div className='iaction-btn ibtn-assess'><a href="/assessment/DCT" target='_blank'>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'home__take_the_test')}</a></div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </section>
            <section className='index-section-container'>
                <div className='index-section-content'>
                    <h1 className='isection-title'>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__toolkits')}</h1>
                    <div className='isection-content'>
                        <p>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__whether_it_be_your_school_company_or')}</p>
                        <p>{ReactHtmlParser(GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__if_you_looking_to_create_a_customized'))}</p>
                    </div>
                    <div className='index-actions'>
                        <div className='iactions-content iaction-kits-content'>
                            <div className='iaction-col iaction-kits-col'>
                                <img src={primarySchools} alt='Primary Schools'></img>
                                <h4>{ReactHtmlParser(GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__primary_schools'))}</h4>
                                <p>
                                    {GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__schools_can_get_their_students_ages_8_12')}
                                    <ul>
                                        <li>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__measures_dq_1_8')}</li>
                                        <li>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__analytics_dashboard_included_for_teachers')}</li>
                                        <li>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__digital_parenting')}</li>
                                        <li>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__cyber_risk_assessment')}</li>
                                    </ul>
                                </p>
                                <div className='iaction-btn'><a href="mailto:partnership@dqinstitute.org" target='_blank'>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__learn_more')}</a></div>
                            </div>
                            <div className='iaction-col iaction-kits-col'>
                                <img src={secondarySchools} alt='Secondary Schools'></img>
                                <h4>{ReactHtmlParser(GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__secondary_schools'))}</h4>
                                <p>
                                    {GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__schools_can_get_their_teenage_students_ages_13_18')}
                                    <ul>
                                        <li>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__measures_dq_1_8')}</li>
                                        <li>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__analytics_dashboard_included_for_teachers')}</li>
                                        <li>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__digital_parenting')}</li>
                                        <li>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__cyber_risk_assessment')}</li>
                                    </ul>
                                </p>
                                <div className='iaction-btn'><a href="mailto:partnership@dqinstitute.org" target='_blank'>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__learn_more')}</a></div>
                            </div>
                            <div className='iaction-col iaction-kits-col'>
                                <img src={companies} alt='Companies'></img>
                                <h4>{ReactHtmlParser(GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__companies_corporates_span'))}</h4>
                                <p>
                                    {GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__companies_and_organizations_can_assess')}
                                    <ul>
                                        <li>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__measures_dq_1_8')}</li>
                                        <li>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__dashboard_included_for_hr')}</li>
                                        <li>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__cyber_risk_assessment')}</li>
                                    </ul>
                                </p>
                                <div className='iaction-btn'><a href="mailto:partnership@dqinstitute.org" target='_blank'>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__learn_more')}</a></div>
                            </div>
                            <div className='iaction-col iaction-kits-col'>
                                <img src={globePack} alt='Global'></img>
                                <h4>{ReactHtmlParser(GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__national_packages'))}</h4>
                                <p>
                                    {GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__countries_and_govt_localize_assess')}
                                    <ul>
                                        <li>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__customize_assessment')}</li>
                                        <li>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__localized_for_your_country')}</li>
                                        <li>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__consult_strategies_plans')}</li>
                                    </ul>
                                </p>
                                <div className='iaction-btn'><a href="mailto:partnership@dqinstitute.org" target='_blank'>{GET_WEBSITE_LOCALIZE_TEXT(pageLc, 'assessment__learn_more')}</a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <DQIndexFooter />
        </div>
    );
}

export default withRouter(DQIndexAssessment);
